import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateBrandAsyncThunk } from "../../redux/pagesSlices/brandSlice";
import { getId, returnArray } from "../../utils/utils";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import isEqual from "lodash/isEqual";

const categoriesList = [
  "Electronics",
  "Beauty & Health",
  "Home & Living",
  "Toys & Games",
  "Automotive",
  "Books & Media",
  "Groceries & Food",
  "Pet Supplies",
  "Office Supplies",
  "Tools & Hardware",
  "Jewellery & Watches",
  "Sports & Outdoors",
  "Baby & Kids",
  "Arts & Collectibles",
];

export default function EditProfileInfoModal({
  editProfileModal,
  editProfileModalCloseHandler,
  brand,
  id,
  brandFromId,
}) {
  const companyName = id ? brandFromId?.name : brand?.name;

  const about = id ? brandFromId?.description : brand?.description;
  const website = id ? brandFromId?.website : brand?.website;
  const categories = id ? brandFromId?.categories : brand?.categories;
  const socials = returnArray(id ? brandFromId?.socials : brand?.socials);
  const address = id ? brandFromId?.address : brand?.address;
  const phone = id ? brandFromId?.phone : brand?.phone;
  const dispatch = useDispatch();

  const initialValues = {
    name: companyName || "",
    description: about || "",
    website: website || "",
    address: address || "",
    phone: phone || "",
    categories: Array.isArray(categories) ? categories : [],
    socials: [
      {
        type: "Facebook",
        url: socials?.find((s) => s.type === "Facebook")?.url || "",
      },
      {
        type: "YouTube",
        url: socials?.find((s) => s.type === "YouTube")?.url || "",
      },
      {
        type: "Instagram",
        url: socials?.find((s) => s.type === "Instagram")?.url || "",
      },
      {
        type: "TikTok",
        url: socials?.find((s) => s.type === "TikTok")?.url || "",
      },
    ],
  };
  const handleBrandUpdate = async (values) => {
    
    const allowedTypes = [
      "Facebook",
      "Instagram",
      "Twitter",
      "LinkedIn",
      "TikTok",
      "TikTokShop",
      "YouTube",
      "Telegram",
      "Portfolio",
    ];
    const processedSocials = values.socials.filter(
      (social) => social.url.trim() !== ""
    );

    
      dispatch(
        updateBrandAsyncThunk({
          id: id ? getId(brandFromId) : getId(brand),
          data: {
            name: values.name,
            description: values.description,
            address : values?.address,
            phone : values?.phone,
            website: values.website,
            categories: values.categories,
            socials: processedSocials,
          },
        })
      );
  };

  return (
    <div className="edit-profile-info-modal-main-wrapper">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleBrandUpdate(values, initialValues)}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => {
          const isButtonDisabled =
          JSON.stringify(values) === JSON.stringify(initialValues);
          return (
            <Modal
              show={editProfileModal}
              onHide={editProfileModalCloseHandler}
              className="custom-modal edit-profile-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit company Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="body-first mb-5">
                  <form action="#" className="form">
                    <div className="form-group">
                      <label htmlFor="name">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={values.name}
                        name="name"
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                        placeholder="Burger King"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">About</label>
                      <textarea
                        type="text"
                        value={values.description}
                        name="description"
                        onChange={handleChange("description")}
                        onBlur={handleBlur("description")}
                        className="form-control"
                        placeholder="Burger King"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Location</label>
                      <input
                        type="text"
                        className="form-control"
                        value={values.address}
                        name="address"
                        onChange={handleChange("address")}
                        onBlur={handleBlur("address")}
                        placeholder="Texas, United States"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Phone number</label>
                      <input
                        type="tel"
                        className="form-control"
                        value={values.phone}
                        name="phone"
                        onChange={handleChange("phone")}
                        onBlur={handleBlur("phone")}
                        placeholder="+1 00 000 0000"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Website address</label>
                      <input
                        type="text"
                        name="website"
                        value={values.website}
                        onChange={handleChange("website")}
                        onBlur={handleBlur("website")}
                        className="form-control"
                        placeholder="burgetking.com"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Categories</label>
                      <p className="medium-bold mb-2">Delete or type to add more categories.</p>
                      {/* <textarea
                        type="text"
                        value={values.categories}
                        name="categories"
                        onChange={handleChange("categories")}
                        onBlur={handleBlur("categories")}
                        className="form-control"
                        placeholder="Burger King"
                      /> */}

                      <CreatableSelect
                        id="categories"
                        name="categories"
                        isMulti
                        className="form-select with-out-arrow shadow-none select-package"
                        classNamePrefix="react-select"
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: "160px", // Adjust height to simulate textarea
                            overflowY: "auto", // Enable scrolling for multiple rows
                          }),
                          valueContainer: (base) => ({
                            ...base,
                            whiteSpace: "pre-wrap", // Allow text to wrap like in a textarea
                          }),
                        }}
                        placeholder="Type or select categories"
                        options={categoriesList.map((category) => ({
                          label: category,
                          value: category,
                        }))}
                        value={returnArray(values.categories).map(
                          (category) => ({
                            label: category,
                            value: category,
                          })
                        )}
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : [];
                          setFieldValue("categories", selectedValues);
                        }}
                        onCreateOption={(inputValue) => {
                          const newOption = {
                            label: inputValue,
                            value: inputValue,
                          };
                          const currentValues = returnArray(
                            values.categories
                          ).map((category) => ({
                            label: category,
                            value: category,
                          }));
                          const updatedValues = [...currentValues, newOption];
                          setFieldValue(
                            "categories",
                            updatedValues.map((opt) => opt.value)
                          );
                        }}
                        onBlur={() => setFieldTouched("categories", true)}
                      />
                    </div>
                    {values.socials.map((social, index) => (
                      <div key={index} className="form-group">
                        <label htmlFor={`social-${index}`}>{social.type}</label>
                        <input
                          type="text"
                          name={`socials[${index}].url`}
                          value={values.socials[index].url}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          placeholder={`Enter ${social.type} URL`}
                        />
                      </div>
                    ))}
                    {/* <div className="form-group">
                      <label htmlFor="name">TikTok</label>
                      <input
                        name="socials.tiktok"
                        value={values.socials.tiktok}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        className="form-control"
                        placeholder="https://www.tiktok.com/7286376671597300998"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Instagram</label>
                      <input
                        name="socials.instagram"
                        value={values.socials.instagram}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        className="form-control"
                        placeholder="https://www.tiktok.com/7286376671597300998"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Facebook</label>
                      <input
                        name="socials.facebook"
                        value={values.socials.facebook}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        className="form-control"
                        placeholder="https://www.tiktok.com/7286376671597300998"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">You tube</label>
                      <input
                        name="socials.youtube"
                        value={values.socials.youtube}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        className="form-control"
                        placeholder="https://www.tiktok.com/7286376671597300998"
                      />
                    </div> */}
                  </form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="cancel"
                  onClick={editProfileModalCloseHandler}
                >
                  Cancel
                </Button>
                <Button disabled={isButtonDisabled} className="save" onClick={handleSubmit}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          );
        }}
      </Formik>
    </div>
  );
}
