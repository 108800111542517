import React, { useEffect, useState } from "react";
import DashboardHeader from "../components/DashboardeHeader";
import MyJobsPageHeader from "../components/MyJobsPageHeader";
import SideBarComponent from "../components/SideBarComponent";
import TabsAndFilterBar from "../components/TabsAndFilterBar";
import FindCreatorCardComponent from "../components/Job/FindCreatorCardComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandApplicationsAsyncThunk,
  getBrandApplicationsCountAsyncThunk,
} from "../redux/pagesSlices/applicationSlice";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import NoJobNewComponent from "../components/NoJobNewComponent";
import CreatorTabsAndFilterBar from "../components/CreatorsTabsAndFilterBar";
import {
  findCreatorsAsyncThunk,
  findCreatorsCountAsyncThunk,
} from "../redux/pagesSlices/creatorSlice";
import NoDataComponent from "../components/NoDataComponent";

export default function FindCreators({}) {
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [statusTabs, setStatusTabs] = useState("all");
  const { findCreators, findCreatorsCount } = useSelector((s) => s.creator);
  const handleSearchChange = (e) => setSearch(e.target.value);
  const [selectedFilters, setSelectedFilters] = useState({
    promotionType: [],
    preferredGender: "All",
    AgeRange: "All",
    categories: "All",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    console.log("selectedFilters", selectedFilters);
    const params = {
      populate: "brandId",
      sortBy,
      activeTab: statusTabs,
      ...(selectedFilters.promotionType?.length > 0 && {
        types: selectedFilters.promotionType,
      }),
      ...(selectedFilters.preferredGender !== "All" && {
        gender: selectedFilters.preferredGender.toLowerCase() || "All",
      }),
      ...(selectedFilters.AgeRange !== "All" && {
        age: selectedFilters.AgeRange || "All",
      }),
      ...(selectedFilters.categories !== "All" && {
        categories: selectedFilters.categories || "All",
      }),
      ...(search && { name: search }),
      // ...(statusTabs && statusTabs !== "all" ? { status: statusTabs } : {}),
    };
    dispatch(findCreatorsAsyncThunk(params));
  }, [statusTabs, selectedFilters, search, sortBy]);

  useEffect(() => {
    const params = {
      // ...(search && { name: search }),
    };
    dispatch(
      findCreatorsCountAsyncThunk({
        params,
      })
    );
  }, [dispatch, statusTabs]);

  const sideBarOpenHandler = () => {
    setSideBarOpen(!sideBarOpen);
  };

  return (
    <div className="main-inner-page-wrapper">
      <SideBarComponent
        sideBarOpen={sideBarOpen}
        sideBarOpenHandler={sideBarOpenHandler}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        clearAllFilters={() =>
          setSelectedFilters({
            categories: "All",
            promotionType: [],
            status: "All",
            preferredGender: "All",
            AgeRange: "All",
          })
        }
        arrangementArray={[
          { name: "GMVRange", type: "multiple" },
          { name: "ROIRange", type: "multiple" },
          { name: "PreferredGender", type: "single" },
          { name: "AgeRange", type: "single" },
          { name: "Categories", type: "multiple" },
        ]}
      />
      <div className={`inner-page-wrapper ${sideBarOpen ? "active" : ""}`}>
        <div className="find-creators-page">
          <MyJobsPageHeader
            // exLargeContainer={true}
            largeContainer={true}
            title={"All Creators"}
            showBtn={false}
            sortBy={sortBy}
            setSortBy={setSortBy}
            search={search}
            handleSearchChange={handleSearchChange}
          />
          <CreatorTabsAndFilterBar
            // exLargeContainer={true}
            sortBy={sortBy}
            setSortBy={setSortBy}
            statusTabs={statusTabs}
            setStatusTabs={setStatusTabs}
            search={search}
            handleSearchChange={handleSearchChange}
            largeContainer={true}
            creatorsCount={findCreatorsCount}
          />
          <div className="my-creators-section">
            <div className="container large">
              <div className="row">
                <ProviderErrorLoadingPagination
                  reducer="creator"
                  action="findCreatorsAsyncThunk"
                  asyncThunk={findCreatorsAsyncThunk}
                  dataKey="findCreators"
                  Component={FindCreatorCardComponent}
                  noDataComponent={<NoDataComponent title={"No Creators Found!"} description={"It looks like there are no creators with your search. Please try something different."}/>}
                  emptyMessage={
                    <>
                      <NoJobNewComponent description="You don't have any creator!" showBtn={false}/>
                    </>
                  }
                  componentProps={{
                    params: {
                      populate: "brandId",
                      sortBy,
                      activeTab: statusTabs,
                      ...(selectedFilters.promotionType?.length > 0 && {
                        types: selectedFilters.promotionType,
                      }),
                      ...(selectedFilters.preferredGender !== "All" && {
                        gender:
                          selectedFilters.preferredGender.toLowerCase() ||
                          "All",
                      }),
                      ...(selectedFilters.AgeRange !== "All" && {
                        age: selectedFilters.AgeRange || "All",
                      }),
                      ...(search && { name: search }),
                    },
                  }}
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
