import React, { useEffect, useState } from "react";

import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";

import jobFileIcon from "../assets/images/icons/order-file-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import mapIcon from "../assets/images/icons/map-icon.svg";
import JobDetailFullSection from "../components/JobDetailFullSection";
import ContractSettingModal from "../components/modals/ContractSettingModal";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationAsyncThunk } from "../redux/pagesSlices/applicationSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import JobDetailSectionForCreator from "../components/JobDetailSectionForCreator";
import { getCreatorAsyncThunk } from "../redux/pagesSlices/creatorSlice";
import { FaEnvelope } from "react-icons/fa";
import {
  confirmPaymentAsyncThunk,
  disputePaymentAsyncThunk,
} from "../redux/pagesSlices/paymentSlice";
import { Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import { getId } from "../utils/utils";
import ViewProfileDetailModal from "../components/ViewProfileDetailModal";
import ViewJobDetailModal from "../components/modals/ViewJobDetailModal";
import { FaPlay } from "react-icons/fa";

export default function CreatorProfilePage() {
  const { loadings } = useSelector((state) => state.payment);
  const [contractSettingModal, setContractSettingModal] = useState(false);
  const brand = useSelector((state) => state.auth?.brand);
  const contractSettingModalOpenHandler = () => {
    setContractSettingModal(true);
  };
  const contractSettingModalCloseHandler = () => {
    setContractSettingModal(false);
  };
  const [searchParams] = useSearchParams();
  const applicationId = searchParams.get("applicationId");

  const dispatch = useDispatch();

  useEffect(() => {
    if (applicationId || !contractSettingModal) {
      dispatch(getApplicationAsyncThunk(applicationId));
    }
  }, [applicationId, contractSettingModal]);

  const applicationDetails = useSelector(
    (state) => state.application?.application
  );
  console.log("applicationDetails", applicationDetails);
  const creatorId = applicationDetails?.creatorId;

  const hiredCreator = useSelector((state) => state.creator?.creator);
  const brandId = getId(brand);

  const brandName = hiredCreator?.name;

  const brandAvatar = hiredCreator?.avatar;

  const creatorUsername = hiredCreator?.userName;

  const jobId = applicationDetails?.jobId;

  const loading = useSelector((s) => s.loadings?.getApplicationAsyncThunk);

  const [activeJobsTab, setJobDetailTabs] = useState("about job");

  const activeJobTabsHandler = (tab) => {
    setJobDetailTabs(tab);
  };


  return (
    <div className="creator-profile-page">
      <div className="container">
        <div className="detail-page-main-title-wrapper">
          <div className="inner-wrapper">
            <div className="upper-section-wrapper">
              <div className="content-wrapper">
                <div className="back-btn-wrapper">
                  <Link to="/dashboard/creators">
                    <MdOutlineKeyboardArrowLeft />
                    <strong className="medium-bold">Back to my jobs</strong>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-7 align-self-center">
                  <div className="content-wrapper">
                    <div className="main-creator-profile-info-wrapper">
                      <div className="main-wrapper">
                        <div className="img-wrapper">
                          {hiredCreator?.avatar ? (
                            <img
                              src={parseImagePathToURL(brandAvatar)}
                              alt="brandAvatar"
                            />
                          ) : (
                            <img src={avatarIcon} alt="avatarIcon" />
                          )}
                        </div>
                        <div className="content-wrapper">
                          <div className="title">
                            <strong className="name dark medium">
                              {brandName}
                            </strong>
                            <div className="location">
                              <img src={mapIcon} alt="mapIcon" />
                              <strong className="dark medium medium-bold">
                                {hiredCreator?.location}
                              </strong>
                            </div>
                          </div>
                          <spsan className="username">@{creatorUsername}</spsan>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 align-self-center">
                  <div className="btn-wrapper">
                    {applicationDetails?.isHired ? (
                      <>
                        <Link
                          to={`/dashboard/messages?id=${applicationDetails?.creatorId ||
                            applicationDetails?.creatorId
                            }&jobId=${applicationDetails?.jobId?._id ||
                            applicationDetails?.jobId?.id
                            }`}
                          //   onClick={contractSettingModalOpenHandler}
                          className="btn-style blue-border"
                        >
                          <FaEnvelope size={18} />
                          Message Creator
                        </Link>
                        {/* {applicationDetails?.paymentInfo?.isPaid &&
                          !applicationDetails?.paymentInfo?.hasSent &&
                          applicationDetails?.paymentInfo?.status ===
                            "paid" && (
                            <>
                              <DropdownButton
                                id={`dropdown-button-drop-down`}
                                drop={"down"}
                                variant="secondary"
                                className="custom-dropdown"
                                title={"Payment"}
                              >
                                <Dropdown.Item
                                  eventKey="1"
                                  disabled={loadings.confirmPaymentAsyncThunk}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleConfirmPayment();
                                  }}
                                >
                                  {loadings.confirmPaymentAsyncThunk ? (
                                    <Spinner size="sm" />
                                  ) : (
                                    "Confirm Payment"
                                  )}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey="2"
                                  disabled={loadings.disputePaymentAsyncThunk}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDisputePayment();
                                  }}
                                >
                                  {loadings.disputePaymentAsyncThunk ? (
                                    <Spinner size="sm" />
                                  ) : (
                                    "Dispute Payment"
                                  )}
                                </Dropdown.Item>
                              </DropdownButton>
                            </>
                          )} */}
                      </>
                    ) : (
                      <a
                        href="#"
                        onClick={contractSettingModalOpenHandler}
                        className="btn-style"
                      >
                        Hire This Creator
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="nav-tabs-main-wrapper simple">
                  <ul className="tabs-wrapper">
                    {jobs?.job?.status !== "pending" && (
                      <li
                        className={`${
                          jobDetailTabs === "applicants" ? "active" : ""
                        }`}
                      >
                        <a
                          href="#"
                          onClick={() => jobDetailPageTabsHandler("applicants")}
                        >
                         Hired Creators
                        </a>
                      </li>
                    )}
                    {jobs?.job?.status === "approved" ||
                      (jobs?.job?.status === "completed" && (
                        <li
                          className={`${
                            jobDetailTabs === "hired_creators" ? "active" : ""
                          }`}
                        >
                          <a
                            href="#"
                            onClick={() =>
                              jobDetailPageTabsHandler("hired_creators")
                            }
                          >
                            Hired Creators
                          </a>
                        </li>
                      ))}

                    <li
                      className={`${
                        jobDetailTabs === "job_details" ? "active" : ""
                      }`}
                    >
                      <a
                        href="#"
                        onClick={() => jobDetailPageTabsHandler("job_details")}
                      >
                        Job details
                      </a>
                    </li>
                  </ul>
                </div> */}
            {/* <div className="nav-tabs-main-wrapper simple">
              <ul className="tabs-wrapper">
                <li
                  onClick={() => activeJobTabsHandler("content created")}
                  className={`${activeJobsTab === "content created" ? "active" : ""
                    }`}
                >
                  <a
                    href="#"
                  // onClick={() => jobDetailPageTabsHandler("hired_creators")}
                  >
                    Content Created
                  </a>
                </li>
                <li
                  className={`${activeJobsTab === "about job" ? "active" : ""}`}
                  onClick={() => activeJobTabsHandler("about job")}
                >
                  <a href="#">About Job</a>
                </li>

              </ul>
            </div> */}
          </div>
        </div>

        <div className="creator-job-detail-main-section">
          {activeJobsTab === 'content created' &&
            <div className="project-detail-section">
              <div className="project-detail-section">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="about-project-detal-wrapper about-video-project">
                        <div className="media-and-resources-wrapper">
                      <h3>Burger King Brand Ambassador
                      </h3>
                        <div className="media-wrapper " style={{marginTop:'56px'}}>
                          <strong className="bold dark">Videos Created</strong>
                          <ul className="videos-media-wrapper">

                            <li style={{ position: "relative" }}>
                              <video
                                controls
                                width="600"

                              >
                                <source type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>

                              <div
                                className="icon-wrapper"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  zIndex: 10,
                                  cursor: "pointer",
                                }}

                              >
                                <FaPlay />
                              </div>

                            </li>

                          </ul>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">

                    </div>
                  </div>
                </div>

              </div>

            </div>
          }
          {activeJobsTab === 'about job' &&
            <JobDetailSectionForCreator
              creatorId={applicationDetails?.creatorId}
              jobId={jobId}
              activeJobsTab={activeJobsTab}
              livePrice={applicationDetails?.livePrice}
              videoPrice={applicationDetails?.videoPrice}
              creatorProgress={applicationDetails?.creatorProgress}
              loading={loading}
            />
          }
        </div>

      </div>
      {contractSettingModal && (
        <ContractSettingModal
          creator={creatorId}
          contractSettingModal={contractSettingModal}
          applicationDetails={applicationDetails}
          contractSettingModalCloseHandler={contractSettingModalCloseHandler}
        />
      )}
    </div>
  );
}
