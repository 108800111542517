import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { MdOutlineAttachment } from "react-icons/md";
import LinkTrackingUrlModal from "./LinkTrackingUrlModal";
import { FaEnvelope } from "react-icons/fa6";
import confirmPayment from "../assets/images/icons/subtract-icon.svg";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import videofile from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import { TbRefresh } from "react-icons/tb";
import { HiMiniInformationCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import proposalImg from "../assets/images/proposal-img.svg";
import dollarSign from "../assets/images/icons/dollar-sign.svg";
import regroupIcon from "../assets/images/icons/regroup-icon.svg";
import { getStripe } from "../utils/stripe/client";
import { useDispatch, useSelector } from "react-redux";
import { createPaymentAsyncThunk } from "../redux/pagesSlices/paymentSlice";
import { getId } from "../utils/utils";
import {
  getJobTaskWithApplicationIdAsyncThunk,
  updateJobTaskWithIdAsyncThunk,
} from "../redux/pagesSlices/jobTaskSlice";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateApplicationAsyncThunk } from "../redux/pagesSlices/applicationSlice";

export default function TrackJobsStepsContent({ proposalDetails }) {
  const [editModalState, setEditModalState] = useState(false);
  const dispatch = useDispatch();
  const { loadings } = useSelector((s) => s.payment);

  const editModalOpenHandler = () => {
    setEditModalState(true);
  };
  const editModalCloseHandler = () => {
    setEditModalState(false);
  };
  console.log(
    "proposalDetails?.paymentInfo?.isPaid",
    proposalDetails?.paymentInfo
  );

  const isContentCreated =
    (proposalDetails?.videoPrice?.count &&
      proposalDetails?.livePrice?.count &&
      proposalDetails?.jobProgressInfo?.video_count >=
        proposalDetails?.videoPrice?.count &&
      proposalDetails?.jobProgressInfo?.live_count >=
        proposalDetails?.livePrice.count) ||
    (proposalDetails?.videoPrice?.count &&
      !proposalDetails?.livePrice?.count &&
      proposalDetails?.jobProgressInfo?.video_count >=
        proposalDetails?.videoPrice?.count) ||
    (proposalDetails?.livePrice?.count &&
      !proposalDetails?.videoPrice?.count &&
      proposalDetails?.jobProgressInfo?.live_count >=
        proposalDetails?.livePrice?.count);

  const handlePayCreator = async () => {
    const livePrice = proposalDetails?.livePrice?.price ?? 0;
    const videoPrice = proposalDetails?.videoPrice?.price ?? 0;
    const amount = Number(livePrice + videoPrice).toFixed(1);

    if (amount > 1) {
      await dispatch(
        createPaymentAsyncThunk({
          data: {
            amount,
            currency: "usd",
            paymentMethod: "card",
            creatorId: getId(proposalDetails?.creatorId),
            jobTaskId: getId(proposalDetails),
          },
          callback: () => {
            dispatch(
              getJobTaskWithApplicationIdAsyncThunk(
                getId(proposalDetails?.applicationId)
              )
            );
          },
        })
      );
    }
  };

  const handleUpdateJobTask = (data) => {
    dispatch(
      updateJobTaskWithIdAsyncThunk({
        id: getId(proposalDetails),
        data,
        callback: () => {
          toast.success("Job confirmed successfully!");
          dispatch(
            getJobTaskWithApplicationIdAsyncThunk(
              getId(proposalDetails?.applicationId)
            )
          );
        },
      })
    );
  };

  const handleUpdateApplication = (data) => {
    dispatch(
      updateApplicationAsyncThunk({
        id: getId(proposalDetails?.applicationId),
        data,
        callBack: () => {
          toast.success("Application updated Successfully!");
          dispatch(
            getJobTaskWithApplicationIdAsyncThunk(
              getId(proposalDetails?.applicationId)
            )
          );
        },
      })
    );
  };

  const accordionData = [
    {
      eventKey: "0",
      title: "Payment Status",
      description: "Confirm payment transfer to the creator.",
      action: (
        <>
          {proposalDetails?.paymentInfo?.isPaid ? (
            <button className="custon-btn light">Payment Sent</button>
          ) : (
            <button
              className="custon-btn light"
              disabled={loadings?.createPaymentAsyncThunk}
              onClick={handlePayCreator}
            >
              {loadings?.createPaymentAsyncThunk ? (
                <Spinner size="sm" />
              ) : (
                <>
                  <MdOutlineAttachment /> Make Payment
                </>
              )}
            </button>
          )}
        </>
      ),
    },
    {
      eventKey: "1",
      title: "Content Creation in Progress",
      description: "Follow the creator’s progress and receive regular updates.",
      action: (
        <>
          <div className="content-creation-steps-main">
            <div className="row">
              <div className="col-md-4 mb-5">
                <div className="image-side-text-parent">
                  <div className="img-wrapper">
                    <img src={proposalImg} alt="png img" />
                  </div>
                  <div className="text-icon-parent">
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={regroupIcon} alt="Regrouplight icon" />
                        <span className="roi-text">ROI</span>
                      </div>
                      <span className="text-3x">3x</span>
                    </div>
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={dollarSign} alt="dollarSign" />
                        <span className="roi-text">GMV</span>
                      </div>
                      <span className="text-3x">$89</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="image-side-text-parent">
                  <div className="img-wrapper">
                    <img src={proposalImg} alt="png img" />
                  </div>
                  <div className="text-icon-parent">
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={regroupIcon} alt="Regrouplight icon" />
                        <span className="roi-text">ROI</span>
                      </div>
                      <span className="text-3x">3x</span>
                    </div>
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={dollarSign} alt="dollarSign" />
                        <span className="roi-text">GMV</span>
                      </div>
                      <span className="text-3x">$89</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="image-side-text-parent">
                  <div className="img-wrapper">
                    <img src={proposalImg} alt="png img" />
                  </div>
                  <div className="text-icon-parent">
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={regroupIcon} alt="Regrouplight icon" />
                        <span className="roi-text">ROI</span>
                      </div>
                      <span className="text-3x">3x</span>
                    </div>
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={dollarSign} alt="dollarSign" />
                        <span className="roi-text">GMV</span>
                      </div>
                      <span className="text-3x">$89</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="image-side-text-parent">
                  <div className="img-wrapper">
                    <img src={proposalImg} alt="png img" />
                  </div>
                  <div className="text-icon-parent">
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={regroupIcon} alt="Regrouplight icon" />
                        <span className="roi-text">ROI</span>
                      </div>
                      <span className="text-3x">3x</span>
                    </div>
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={dollarSign} alt="dollarSign" />
                        <span className="roi-text">GMV</span>
                      </div>
                      <span className="text-3x">$89</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="image-side-text-parent">
                  <div className="img-wrapper">
                    <img src={proposalImg} alt="png img" />
                  </div>
                  <div className="text-icon-parent">
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={regroupIcon} alt="Regrouplight icon" />
                        <span className="roi-text">ROI</span>
                      </div>
                      <span className="text-3x">3x</span>
                    </div>
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={dollarSign} alt="dollarSign" />
                        <span className="roi-text">GMV</span>
                      </div>
                      <span className="text-3x">$89</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      eventKey: "2",
      title: "Finalise or Renew Contract",
      description:
        "Complete the project or initiate a renewal based on the campaign’s performance metrics (like GMV targets).",
      action: (
        <>
          <div className="progress-wrapper-main mt-5">
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar
                  className="video-created-progress-bar blue"
                  value={
                    proposalDetails?.jobProgressInfo?.video_count
                      ? Math.floor(
                          (proposalDetails?.jobProgressInfo?.video_count /
                            proposalDetails?.videoPrice?.count) *
                            100
                        )
                      : 0
                  }
                  strokeWidth={13}
                />
                <strong className="total medium">
                  {proposalDetails?.jobProgressInfo?.video_count || 0}/
                  {proposalDetails?.videoPrice?.count}
                </strong>
              </div>
              <div className="text-wrapper">
                <h3>Videos Created</h3>
                <ul className="list-wrapper">
                  <li>
                    <span className="label gray"></span> Expected Videos
                  </li>
                  <li>
                    <span className="label blue"></span> Videos Created
                  </li>
                </ul>
              </div>
            </div>
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar
                  className="video-created-progress-bar pink"
                  value={
                    proposalDetails?.jobProgressInfo?.live_count
                      ? Math.floor(
                          (proposalDetails?.jobProgressInfo?.live_count /
                            proposalDetails?.livePrice?.count) *
                            100
                        )
                      : 0
                  }
                  strokeWidth={13}
                />
                <strong className="total medium">
                  {proposalDetails?.jobProgressInfo?.live_count || 0}/
                  {proposalDetails?.livePrice?.count}
                </strong>
              </div>
              <div className="text-wrapper">
                <h3>Live Streams</h3>
                <ul className="list-wrapper">
                  <li>
                    <span className="label gray"></span> Expected Lives
                  </li>
                  <li>
                    <span className="label pink"></span> Live Streamed
                  </li>
                </ul>
              </div>
            </div>
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar
                  className="video-created-progress-bar gradient"
                  value={
                    proposalDetails?.generated_gmv?.amount
                      ? Math.floor(
                          (Number(proposalDetails?.generated_gmv?.amount) /
                            proposalDetails?.applicationId?.expected_gmv) *
                            100
                        )
                      : 0
                  }
                  strokeWidth={13}
                />
                <strong className="total medium">
                  {proposalDetails?.generated_gmv?.amount_formatted}
                </strong>
              </div>
              <div className="text-wrapper">
                <h3>GMV Generated</h3>
                <ul className="list-wrapper">
                  <li>
                    <span className="label gray"></span> Expected GMV
                  </li>
                  <li>
                    <span className="label gradient"></span> Generated GMV
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="notification-wrapper">
            {!isContentCreated &&
              proposalDetails?.applicationId?.shouldAutoRenew && (
                <>
                  <HiMiniInformationCircle />
                  <strong>
                    You set this job to auto-renew if{" "}
                    {proposalDetails?.creatorId?.firstName ||
                      proposalDetails?.creatorId?.tikTokUserName}{" "}
                    meets expected GMV
                  </strong>
                </>
              )}
          </div>
          <div className="attatch-link-wrapper">
            {!isContentCreated &&
              proposalDetails?.applicationId?.shouldAutoRenew && (
                <button
                  className="btn-style error-btn"
                  onClick={() =>
                    handleUpdateApplication({ shouldAutoRenew: false })
                  }
                >
                  <RxCross2 /> Cancel auto-renewal
                </button>
              )}
            {isContentCreated && !proposalDetails?.brandApproval && (
              <button
                className="custon-btn light"
                onClick={() => handleUpdateJobTask({ brandApproval: true })}
              >
                <RxCross2 /> Confirm Completion
              </button>
            )}
          </div>
        </>
      ),
    },
  ];

  console.log("Proposal Details", proposalDetails);

  console.log("Status", proposalDetails?.status);

  const getClassName = (accordion) => {
    const status = proposalDetails?.status;

    const stepStatusMap = {
      0: proposalDetails?.paymentInfo?.isPaid
        ? "accordion-item complete"
        : "accordion-item active",
      1: isContentCreated ? "accordion-item complete" : "accordion-item active",
      2:
        isContentCreated && proposalDetails?.brandApproval
          ? "accordion-item complete"
          : "accordion-item active",
    };
    return stepStatusMap[accordion.eventKey] || "accordion-item";
  };

  return (
    <div className="track-job-steps-content-wrapper">
      <Accordion
        defaultActiveKey={
          accordionData.find((step) => getClassName(step).includes("active"))
            ?.eventKey
        }
      >
        {accordionData.map((item, index) => (
          <Accordion.Item
            key={item.eventKey}
            eventKey={item.eventKey}
            className={getClassName(item)}
          >
            <Accordion.Header>
              <div className="step-wrapper">
                <div className="count-wrapper">
                  {getClassName(item).includes("complete") ? (
                    <FaCheck />
                  ) : (
                    <span>{+item.eventKey + 1}</span>
                  )}
                </div>
                <div className="contetn-wrapper">
                  <strong className="medium">{item.title}</strong>
                  <p>{item.description}</p>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="attatch-link-wrapper">{item.action}</div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      <LinkTrackingUrlModal
        editModalState={editModalState}
        editModalCloseHandler={editModalCloseHandler}
        proposalDetails={proposalDetails}
      />
    </div>
  );
}
