import React, {useState, useEffect, useRef} from "react";
import { NavLink, useLocation } from "react-router-dom";
import {Link} from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import envelopIcon from "../assets/images/icons/envelop-icon.svg";
import notificationIcon from "../assets/images/icons/notification-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import Logo from "../assets/images/icons/logo.svg";
import userLightIcon from "../assets/images/icons/user-light-icon.svg";
import settingsLightIcon from "../assets/images/icons/settings-light-icon.svg";
import helpLightIcon from "../assets/images/icons/help-light-icon.svg";
import logoutIcon from "../assets/images/icons/logout-light-icon.svg";
import shopIcon from "../assets/images/icons/shop-icon.svg";
import settingIcon from "../assets/images/icons/settings-light-icon.svg";
import xIcon from "../assets/images/icons/x-icon.svg";
import {parseImagePathToURL} from "../utils/globalFunctions";
import {useDispatch, useSelector} from "react-redux";
import {TbLogout} from "react-icons/tb";
import menuIcon from "../assets/images/icons/menu-icon.png";
import {
    setBrand,
    setBrandProfile,
    storeProfile,
    userLogoutAsyncThunk,
} from "../redux/pagesSlices/authSlice";
import {Button} from "react-bootstrap";
import NotificationComponent from "./NotificationsComponent";
import {fetchRoomsAsyncThunk, setMessages, setRooms} from "../redux/pagesSlices/chatSlice";
import {getId, returnArray} from "../utils/utils";
import {IoAddCircleSharp} from "react-icons/io5";
import {FaCheckCircle} from "react-icons/fa";
import {getMyBrandsAsyncThunk} from "../redux/pagesSlices/brandSlice";
import {IoClose} from "react-icons/io5";
import TopShortBanner from "./TopShortBanner";
import { useChannel, usePresence } from "ably/react";
import { toast } from "react-toastify";

export default function DashboardHeader({brands}) {
    const {profile, user, brand = {}} = useSelector((s) => s.auth);
    console.log("🚀 ~ DashboardHeader ~ brand:", brand);
    const [showHeaderMenuState, setShowHeaderMenuState] = useState(false);
    const rooms = useSelector((s) => s.chat.rooms);
    const messages = useSelector((s) => s.chat.messages?.results);
    const notifications = useSelector((s) => s.chat?.notifications);

    console.log("notifications", notifications);
    

    const location = useLocation();
    const pageUrlActive = ["/dashboard/my-jobs", "/dashboard/my-profile", "/dashboard/settings"].includes(location.pathname);


    const d = useDispatch();
    const handleLogout = () => {
        d(userLogoutAsyncThunk());
    };
    // const [showNewBrandModel, setShowNewBrandModel] = useState(false);
      const [hasNotification, setHasNotification] = useState(true);
    const [showNotification, setShowNotifications] = useState(false);
    const notificationRef = useRef(null);

    const showNotificationsHandler = () => {
        setShowNotifications(true);
    };
    const hideNotificationsHandler = () => {
        setShowNotifications(false);
    };

    const { updateStatus } = usePresence(
        { channelName: "notifications" },
        { isOnline: true }
      );

    const handleClickOutside = (event) => {
        if (
            notificationRef.current &&
            !notificationRef.current.contains(event.target)
        ) {
            setShowNotifications(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        d(fetchRoomsAsyncThunk());
        // dispatch(getSystemRoomAsyncThunk())
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [toastId, setToastId] = useState(null);
    const { channel: notification } = useChannel("notifications", (message) => {
        console.log("🚀 ~ useChannel ~ message:", message);
        if (message.name === "notification") {
          const roomId = message?.data?.room_id;
          const content = message?.data?.content;
          const created_at = message?.data?.createdAt;
          if (message?.data?.sender_id === (brand?.id || brand._id)) return;
          if (message?.data?.recipient_id !== (brand?.id || brand._id)) return;
          if (messages[messages.length - 1]?.id === message?.data?.id) return;
          if (toastId) {
            toast.dismiss(toastId);
          }
          setToastId(
            toast.info("You have new unread messages!", {
              position: "top-right",
              autoClose: 10000,
              closeOnClick: true,
              closeButton: true,
            })
          );
          //   playNotificationSound();
          d(
            setRooms(
              rooms
                .map((room) => {
                  if ((room.id || room._id) === roomId) {
                    return {
                      ...room,
                      unreadMessagesCount: room.unreadMessagesCount + 1,
                      lastMessage: content,
                      lastMessageTimestamp: created_at,
                    };
                  }
                  return room;
                })
                .sort((a, b) => {
                  return (
                    new Date(b.lastMessageTimestamp).getTime() -
                    new Date(a.lastMessageTimestamp).getTime()
                  );
                })
            )
          );
        }
        if (message.name === "delete_room") {
          const roomId = message?.data?.room_id;
          d(setRooms(rooms.filter((room) => room.id !== roomId)));
        }
        if (message.name === "new room") {
          d(fetchRoomsAsyncThunk());
        }
        if (message.name === "delete_message") {
          d(
            setMessages(
              messages.filter(
                (msg) => (msg.id || msg._id) !== message?.data?.messageId
              )
            )
          );
          //   if(message?.data?.isLastMessage){
          //     dispatch(setRooms(rooms.map((room) => {
          //       if (room.name === channelName) {
          //         return {
          //           ...room,
          //           lastMessage: message?.data?.secondLastMessage?.content,
          //           lastMessageTimestamp: message?.data?.secondLastMessage?.createdAt,
          //         };
          //       }
          //       return room;
          //     })))
          //   }
        }
      });
    const handleBrandSwitch = (selectedBrand) => {
        if (selectedBrand?.steps < 5) {
            const previousBrands = brand;
            localStorage.setItem("previous_space", JSON.stringify(previousBrands));
        }
        d(setBrand(selectedBrand));
        console.log("selectedBrand at switch function", selectedBrand);
    };

    useEffect(() => {
        d(getMyBrandsAsyncThunk({pagination: false})); // Fetch brands
    }, [d]);

    const handleAddNewBrand = () => {
        const previousBrands = brand;
        d(storeProfile(brand));
        d(setBrandProfile(null));

        localStorage.setItem("previous_space", JSON.stringify(previousBrands));
    };

    const showHeaderMenu = () => {
        setShowHeaderMenuState(!showHeaderMenuState);
    };
    const closeHeaderMenu = () => {
        setShowHeaderMenuState(false);
    };

    return (
        <>

            <header className="dashboard-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-4 col-4 align-self-center">
                            <div className="logo-wrapper">
                                <a href="#">
                                    <img src={Logo} alt="Logo" className="logo"/>
                                </a>
                            </div>
                            <div className={`header-menu ${showHeaderMenuState ? "active" : ""}`}>
                                <ul className="menu">
                                    <li>
                                        <NavLink
                                            end
                                            to="/dashboard"
                                            activeClassName="active"
                                            onClick={() => setShowHeaderMenuState(false)}
                                        >
                                            Dashboard
                                        </NavLink>
                                    </li>
                                    <li>
                                       {/* <NavLink
                                            end
                                            to="/dashboard/my-jobs"
                                            activeClassName="active"
                                            onClick={() => setShowHeaderMenuState(false)}
                                        >
                                            My Jobs
                                        </NavLink> */}

                                            <DropdownButton
                                                id={`dropdown-button-drop-down`}
                                                drop={"down"}
                                                variant="secondary"
                                                className={`custom-dropdown ${pageUrlActive ? 'active' : ''}`}
                                                onClick={() => setShowHeaderMenuState(false)}
                                                // as={Link}
                                                // to="/dashboard/my-jobs"
                                                title={
                                                    <>
                                                        My Jobs
                                                    </>
                                                }
                                            >
                                                <Dropdown.Item
                                                    eventKey="2"
                                                    as={Link}
                                                    to="/dashboard/my-jobs?status=approved"
                                                >
                                                    Active Jobs
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    eventKey="3"
                                                    as={Link}
                                                    to="/dashboard/my-jobs?status=closed"
                                                >
                                                    Inactive Jobs
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="4"
                                                as={Link}
                                                to="/dashboard/my-jobs?status=draft"
                                                >
                                                    Draft
                                                </Dropdown.Item>
                                                <Dropdown.Item eventKey="4"
                                                as={Link}
                                                to="/dashboard/my-jobs?status=completed"
                                                >
                                                    Completed Jobs
                                                </Dropdown.Item>
                                            </DropdownButton>

                                    </li>
                                    <li>
                                        <NavLink
                                            end
                                            to="/dashboard/creators"
                                            activeClassName="active"
                                            onClick={() => setShowHeaderMenuState(false)}
                                        >
                                            My Creators
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            end
                                            to="/dashboard/find-creators"
                                            activeClassName="active"
                                        >
                                            Find Creators
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            end
                                            to="/job-post"
                                            activeClassName="active"
                                            onClick={() => setShowHeaderMenuState(false)}
                                        >
                                            Post a Job
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-8 col-8 align-self-center">
                            <div className="right-menu">
                                <ul className="menu">
                                    <li className={`hamburger-icon-li`}>
                                        <a href="#" onClick={showHeaderMenu}>
                                            {showHeaderMenuState ? (
                                                <IoClose/>
                                            ) : (
                                                <img src={menuIcon} alt="menuIcon"/>
                                            )}
                                        </a>
                                    </li>
                                    <li className="has-notification">
                                        <NavLink to="/dashboard/messages" activeClassName="active">
                                            <img src={envelopIcon} alt="envelopIcon"/>
                                        </NavLink>
                                    </li>
                                    <li
                                        className={
                                            notifications?.reduce(
                                              (sum, message) => sum + message.unreadMessagesCount,
                                              0
                                            ) || 0
                                              ? "has-notification"
                                              : ""
                                          }
                                    >
                                        <button
                                            onClick={showNotificationsHandler}
                                            className={`${showNotification ? "active" : ""}`}
                                        >
                                            <img src={notificationIcon} alt="notificationIcon"/>
                                        </button>
                                    </li>
                                    
                                </ul>
                                <div className="avatar-dropdown-wrapper">
                                    <DropdownButton
                                        id={`dropdown-button-drop-down`}
                                        drop={"down"}
                                        variant="secondary"
                                        className="custom-dropdown"
                                        title={
                                            <>
                                                <img
                                                    src={
                                                        brand?.avatar
                                                            ? parseImagePathToURL(brand?.avatar)
                                                            : avatarIcon
                                                    }
                                                    alt="avatarIcon"
                                                    className="avatar-img"
                                                />
                                                <span>{profile?.name}</span>
                                            </>
                                        }
                                    >
                                        <div className="dropdown-title-wrapper">
                                            {returnArray(brands?.results).map((_brand, index) => (
                                                <div
                                                    key={index}
                                                    className="brand-ttile-wrapper"
                                                    onClick={() => handleBrandSwitch(_brand)}
                                                >
                                                    <div className="img-wrapper">
                                                        <img
                                                            src={
                                                                _brand?.avatar
                                                                    ? parseImagePathToURL(_brand?.avatar)
                                                                    : avatarIcon
                                                            }
                                                            alt="brand"
                                                        />
                                                    </div>
                                                    <div className="text-wrapper">
                                                        <strong className="dark medium-bold">
                                                            {_brand?.name}
                                                        </strong>
                                                        {getId(brand) === getId(_brand) ? (
                                                            <FaCheckCircle className="verified-icon"/>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="add-brand-main-wrapper">
                                                <div className="add-brand-btn-wrrapper">
                                                    <button
                                                        className="blue trasparent-btn"
                                                        onClick={handleAddNewBrand}
                                                    >
                                                        <IoAddCircleSharp/> Add new brand
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <Dropdown.Item
                                            eventKey="1"
                                            as={Link}
                                            to="/dashboard/my-brand"
                                        >
                                            <img src={shopIcon} alt="shopIcon"/> My brands{" "}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey="2"
                                            as={Link}
                                            to="/dashboard/my-profile"
                                        >
                                            <img src={userLightIcon} alt="userLightIcon"/> View brand
                                            profile
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey="3"
                                            as={Link}
                                            to="/dashboard/settings"
                                        >
                                            <img src={settingsLightIcon} alt="userLightIcon"/> Settings
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="4">
                                            <img src={helpLightIcon} alt="userLightIcon"/> Help &
                                            Support
                                        </Dropdown.Item>
                                        <Dropdown.Divider/>
                                        <Dropdown.Item onClick={handleLogout} eventKey="4">
                                            <img src={logoutIcon} alt="userLightIcon"/> Log out
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationComponent
                    hasNotification={hasNotification}
                    showNotification={showNotification}
                    hideNotificationsHandler={hideNotificationsHandler}
                    notificationRef={notificationRef}
                    notifications={notifications}
                />
            </header>
        </>
    );
}
