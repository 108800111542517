import React from "react";

import { FiArrowRight } from "react-icons/fi";
import welcomeBmcImg from "../assets/images/welcome-bmc-img.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setScreenLock } from "../redux/pagesSlices/authSlice";
import { updateBrandProfileAsyncThunk } from "../redux/pagesSlices/brandSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";

export default function BrandSignUpWelcomeToBMC({ values, withoutMembership,previousSpace }) {
  const d = useDispatch();
  const { brand } = useSelector((s) => s.auth);
  const handleSubmit = async () => {
    const brandData = {
      steps: 5,
    };

    d(
      updateBrandProfileAsyncThunk({
        data: brandData,
      })
    );
  };

  return (
    <div className="job-post-inner-wrapper">
      <div className="welcome-to-bmc-wrapper">
        <div className="img-wrapper">
          <img
            src={
              brand?.avatar
                ? parseImagePathToURL(brand?.avatar)
                : values?.avatar ? URL.createObjectURL(values?.avatar) : welcomeBmcImg
            }
            className={brand?.avatar ? "rounded-circle" : ""}
            alt="welcomeBmcImg"
          />
        </div>
        <div className="modal-text-wrapper">
          <h2>{previousSpace ? "You’re all set!" : "Welcome to BMC 🎉"}</h2>
          <p className="medium medium-bold">
            {previousSpace ? (
              <>
              Your brand has been successfully added. You can now <br /> switch between multiple brands with ease
              </>
            ) : (
              <>
                You’re all set up and ready to go! Start exploring your
                dashboard or <br /> take a quick tour to get familiar with
                everything.{" "}
              </>
            )}
          </p>
        </div>
        <div className="welcome-footer">
          <button onClick={handleSubmit} type="submit" className="btn-style">
            {previousSpace
              ? "Go to brand dashboard"
              : "Give me a walkthrough"}{" "}
            <FiArrowRight />
          </button>
          {!previousSpace && (
            <button
              onClick={handleSubmit}
              className="blue btn btn-link text-decoration-none"
            >
              I don’t need help I’ll do it myself
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
