import React from "react";
import { useSelector } from "react-redux";
import { getRoi, newGetGMV } from "../utils/getStats";

const TableInfo = () => {
  const { hiredCreatorsProgress } = useSelector((s) => s.application);

  return (
    <div className="table-info-page">
      <strong>Total</strong>
      <div className="total-info">
        <ul>
          <li>
            {hiredCreatorsProgress?.completedVideos}/
            {hiredCreatorsProgress?.totalVideos}
          </li>
          <li>
            {hiredCreatorsProgress?.completedLives}/
            {hiredCreatorsProgress?.totalLives}
          </li>
          <li>
            {newGetGMV(
              hiredCreatorsProgress?.totalCreatorMedGmvRevenue,
              hiredCreatorsProgress?.totalCreatorMedGmvRevenueRange
            )}
          </li>
          <li>
            {" "}
            {Number(
              getRoi(
                hiredCreatorsProgress?.totalCreatorMedGmvRevenue,
                hiredCreatorsProgress?.totalCreatorMedGmvRevenueRange,
                hiredCreatorsProgress?.totalApplicationPaymentAmount
              )
            ).toFixed(0)}
            X
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TableInfo;
