import React, { useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { LuEyeOff } from "react-icons/lu";
import googleIcon from "../assets/images/icons/google-icon.svg";
import circularCrossIcon from "../assets/images/icons/circular-cross-icon.svg";
import { FaArrowLeftLong } from "react-icons/fa6";

import LogoTopBar from "../components/LogoTopBar";
import InvalidEmailPassword from "../components/InvalidEmailPassword";
import LoginAsBrandBottomBar from "../components/LoginAsBrandBottomBar";
import { Link, useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userForgetPasswordAsyncThunk } from "../redux/pagesSlices/authSlice";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export default function ForgetPassword() {
  const d = useDispatch();
  const loading = useSelector(
    (s) => s.auth?.loadings?.userForgetPasswordAsyncThunk
  );
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    await d(
      userForgetPasswordAsyncThunk({
        email: values.email,
        navigate,
      })
    );
  };
  return (
    <div className="forget-password-page">
      <div className="inner-main-page">
        <LogoTopBar />
        <div className="inner-page-content">
          <div className="login-page-content">
            <div className="title-wrapper text-center">
              <h2>Forgot Password?</h2>
              <p className="dark_text">
                {" "}
                Enter your email address and we’ll send you a link to reset your
                password.
              </p>
            </div>

            {/* {showError && <InvalidEmailPassword />} */}

            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              // onSubmit={(values, { setSubmitting }) => {
              //     console.log('Form submitted:', values);
              //     setSubmitting(false); // Stop submit button loading after submission
              // }}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, errors, setFieldValue }) => (
                <Form className="form transparent">
                  <div className="field-wrapper">
                    <div className="form-group with-right-icon">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="Enter your email address"
                        id="email"
                        name="email"
                      />
                      <img
                        src={circularCrossIcon}
                        alt="circularCrossIcon"
                        style={{ cursor: "pointer" }}
                        onClick={() => setFieldValue("email", "")}
                      />
                    </div>
                    <span className="error-msg">
                      <ErrorMessage name="email" />
                    </span>
                  </div>

                  <div className="form-footer">
                    {/* <button type="submit" disabled={isSubmitting} className='btn-style full'> Next  </button> */}
                    <button
                      className="btn-style full"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner as="span" animation="border" size="sm" />
                      ) : (
                        "Send reset link"
                      )}
                    </button>
                    <p className="have-account medium ">
                      <Link to="/sign-in" className=" ">
                        <FaArrowLeftLong className="mx-2" /> Return to login
                      </Link>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <LoginAsBrandBottomBar />
    </div>
  );
}
