import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { IoIosArrowDown } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { IoEllipseSharp } from "react-icons/io5";
import unreadImg from '../assets/images/notification-image.png'
import supplemnt from '../assets/images/icons/suplement.png'
import trainer from '../assets/images/trainer.png'
import person2 from '../assets/images/person-2.png'
import person1 from '../assets/images/person-1.png'
import money from '../assets/images/Money_light.png'
import user from '../assets/images/User_light.png'

const NotificationPage = () => {
    return (
        <div className='notification-section'>
            <div className='container'>
                <div className='notification-page'>
                    <div className='notification-page'>
                        <h2>Notifications</h2>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                All
                                <IoIosArrowDown />

                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className='unread-msg'>
                            <div className='show-unread'>
                                <div className='ellipse-msg'>

                                </div>
                            </div>
                            <p>Show only unread</p>
                        </div>
                    </div>
                    <div className='notification-page today-set'>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                TODAY
                                <IoIosArrowDown />

                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className='setting'>
                            <IoSettingsOutline />
                        </div>

                    </div>


                </div>

                <div className='notification-chat'>
                <div className='unread-chat'>
                    <p>Unread</p>
                    <div>
                        <span>Mark all as read</span>
                    </div>

                </div>
                <div className='unread-chat chat-box'>
                   <div className='unread-chat smackin-chat'>
                  
                    <img src={unreadImg} alt="img" />  
                  
                                  
                   <div>
                    <p >Your Smackin' Sunflower Seeds job application was successfully submitted. Keep track of your application status on the my job page.</p>
                    <span>5 hours ago</span>
                   </div>
                   </div>

                   <div className='read-status'></div>
                </div>
                <div className='unread-chat chat-box'>
                   <div className='unread-chat smackin-chat'>
                    <img src={trainer} alt="img" />                  
                   <div>
                   <p>You have a new message from Top Trainer.</p>
                    <span>3 hours ago</span>
                   </div>
                   </div>
                   
                   <div className='read-status'></div>
                </div>
                <div className='unread-chat chat-box'>
                   <div className='unread-chat smackin-chat'>
                   <div className='chat-img'>
                    <img src={supplemnt} alt="img" /> 
                    </div>                 
                   <div>
                    <p>Top Trainer Supplements will expire in two days.</p>
                    <span>7 hours ago</span>
                   </div>
                   </div>
                   
                   <div className='read-status'></div>
                </div>
                </div>
              
                <div className='notification-chat'>
                <div className='unread-chat'>
                    <p>Read</p>
                  
                </div>
                <div className='unread-chat chat-box'>
                   <div className='unread-chat smackin-chat'>  
                    <div className='chat-img'>
                    <img src={user} alt="img" />
                        </div>                
                               
                                  
                   <div>
                    <p >Profile update: Update your profile to attract more clients and get more offers</p>
                    <span>1 hours ago</span>
                   </div>
                   </div>
                </div>

                <div className='unread-chat chat-box'>
                   <div className='unread-chat smackin-chat'>  
               
                                 
                    <img src={trainer} alt="img" />                   
                                  
                   <div>
                    <p >Congratulations, Your proposal for Top Trainer Supplements looking for creators to make 10 videos per month has been accepted </p>
                    <span>4 hours ago</span>
                   </div>
                   </div>
                </div>
                <div className='unread-chat chat-box'>
                   <div className='unread-chat smackin-chat'>  
                <div  className='chat-img'>
                <img src={money} alt="img" />  
                    </div>                           
                                    
                                  
                   <div>
                    <p >New payment: Confirm that you received a payment from Top Trainer. </p>
                    <span>2 hours ago</span>
                   </div>
                   </div>
                </div>
                <div className='unread-chat chat-box'>
                   <div className='unread-chat smackin-chat'>  
               
                                 
                    <img src={person1} alt="img" />                   
                                  
                   <div>
                    <p >I’ll be there, looking forward to the meeting.</p>
                    <span>6 hours ago</span>
                   </div>
                   </div>
                </div>
                <div className='unread-chat chat-box'>
                   <div className='unread-chat smackin-chat'>                
                                 
                    <img src={person2} alt="img" />                   
                                  
                   <div>
                    <p >I’ll be available, see you at the meeting.</p>
                    <span>8 hours ago</span>
                   </div>
                   </div>
                </div>
             
                </div>
            </div>
        </div>


    )
}

export default NotificationPage
