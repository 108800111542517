import React from "react";
import Slider from "react-slick";
import { TiArrowRight } from "react-icons/ti";
import slideBgImg1 from '../assets/images/banner-slide-bg-img-1.png'
import slideBgImg2 from '../assets/images/banner-slide-bg-img-2.png'
import avatar from '../assets/images/icons/avatar-icon.svg'
import { Link } from "react-router-dom";

export default function DashboardHomeBannerSlider() {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nav: false,
    };

    return (
        <Slider {...settings}>
            <div style={{ height: '100%' }}>
                <div className="main-wrapper" style={{ backgroundImage: `url(${slideBgImg1})`, backgroundColor: '#ffeefb' }}>
                    <div className="text-wrapper">
                        <h3>Browse Top Creators</h3>
                        <p>Create a detailed job listing and let talented creators come to you.</p>
                        <Link to="/dashboard/find-creators" className="btn-style text search-creater">Search Creators <TiArrowRight /></Link>
                        <div className="all-uers-images-main">
                            <ul className="users-imgs-list">
                                <li>
                                    <a href="#">
                                        <img src={avatar} alt="img" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <img src={avatar} alt="img" />
                                    </a>
                                </li>
                            </ul>
                            <strong>
                                +4000 Creators
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: '100%' }}>
                <div className="main-wrapper" style={{ backgroundImage: `url(${slideBgImg2})`, backgroundColor: '#fff7ee' }}>
                    <div className="text-wrapper">
                        <h3>Need Help? We're Here for You!</h3>
                        <p>Access tailored support, browse FAQs, or connect with our team to resolve issues quickly and efficiently.</p>
                        <a href="#" className="btn-style text">Contact Support <TiArrowRight /></a>

                    </div>
                </div>
            </div>

        </Slider>
    )
}