import React, { useEffect } from "react";
import MyJobsPageHeader from "../components/MyJobsPageHeader";

import brandAvatar from "../assets/images/brand-img-1.svg";
import pointerIcon from "../assets/images/icons/pointer-icon.svg";
import trashIcon from "../assets/images/icons/trash-icon.svg";
import { HiArrowRight } from "react-icons/hi";
import MyBrandCardComponent from "../components/MyBrandCardComponent";
import { useDispatch, useSelector } from "react-redux";
import { getMyBrandsAsyncThunk } from "../redux/pagesSlices/brandSlice";
import { returnArray } from "../utils/utils";
export default function MyBrand() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyBrandsAsyncThunk({pagination:true}));
  }, []);
  const myBrands = useSelector((state) => state.brand.myBrands);
  const brandsResult = returnArray(myBrands?.results);
  console.log("myBrands", brandsResult);

  return (
    <div className="inner-page-wrapper active m-0">
      <div className="my-brands-page">
        <MyJobsPageHeader title={"My Brands"} />
        <div className="inner-page-section">
          <div className="brands-listings-section">
            <div className="container">
              <div className="row">
                {brandsResult?.map((item, index) => (
                  <div className="col-md-4 mb-3" key={index}>
                    <MyBrandCardComponent item={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
