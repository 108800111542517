import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authenticateAsyncThunk } from "../redux/pagesSlices/authSlice";
import Spinner from "react-bootstrap/Spinner";

export default function GoogleAuthCallback() {
  const dispatch = useDispatch();
  const { loadings } = useSelector((state) => state.auth);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenValue = queryParams.get("access_token");
    if (tokenValue) {
      localStorage.setItem("access-token", tokenValue);
      dispatch(authenticateAsyncThunk());
    }
  }, [dispatch]);

  return (
    <div className="d-flex justify-content-center">
      <Spinner
        animation="border"
        role="status"
        hidden={!loadings?.authenticateAsyncThunk}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}
