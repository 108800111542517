import React, { useEffect, useState } from "react";
import uploadBrandLogoIcon from "../assets/images/icons/upload-brand-logo-icon.svg";
import SampleProductImage from "./SampleProductImage";
import { IoMdAdd } from "react-icons/io";
import { ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { parseImagePathToURL } from "../utils/globalFunctions";

export default function ProductImageJobPostStep({ values, setFieldValue }) {
  const [imageURLs, setImageURLs] = useState([]); // Holds previous image URLs
  const [newImageFiles, setNewImageFiles] = useState([]); // Holds new uploaded files
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (values.image && Array.isArray(values.image)) {
      const existingURLs = values.image.filter(
        (img) => typeof img === "string"
      );
      setImageURLs(existingURLs);

      const newFiles = values.image.filter((img) => img instanceof File);
      setNewImageFiles(newFiles);
    }
  }, [values.image]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    addFiles(files);
  };

  const addFiles = (files) => {
    // Check if total images (existing + new) exceed the limit
    if (files.length + imageURLs.length + newImageFiles.length > 4) {
      toast.error("You can upload up to 4 images only.");
      return;
    }

    const updatedFiles = [...newImageFiles, ...files];
    setNewImageFiles(updatedFiles);
    setFieldValue("image", [...imageURLs, ...updatedFiles]); // Update Formik field
  };
  console.log("imageURLs", imageURLs);
  const handleDelete = (index, type) => {
    if (type === "url") {
      // Delete from existing URLs
      const updatedURLs = [...imageURLs];
      updatedURLs.splice(index, 1);
      setImageURLs(updatedURLs);
      setFieldValue("image", [...updatedURLs, ...newImageFiles]);
    } else if (type === "file") {
      // Delete from new files
      const updatedFiles = [...newImageFiles];
      updatedFiles.splice(index, 1);
      setNewImageFiles(updatedFiles);
      setFieldValue("image", [...imageURLs, ...updatedFiles]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files);
    addFiles(files);
  };

  // Function to generate preview URLs for new image files
  const getPreviewURL = (file) => {
    return URL.createObjectURL(file);
  };

  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper">
        <h3 className="step-title">Product Image</h3>
      </div>
      {values?.image?.length > 0 ? (
        <>
          <div className="field-wrapper">
            <div className="form-group">
              <label htmlFor="uploadImage">
                Upload up to four clear product images
              </label>
            </div>
          </div>
          <div className="sample-product-images-main">
            <div className="main-wrapper">
              {imageURLs.map((url, index) => (
                <SampleProductImage
                  key={`existing-${index}`}
                  sampleProductImage={[parseImagePathToURL(url)]} // Pass image URL
                  handleDelete={() => handleDelete(index, "url")}
                />
              ))}

              {newImageFiles.map((file, index) => (
                <SampleProductImage
                  key={`new-${index}`}
                  sampleProductImage={[getPreviewURL(file)]} // Generate preview URL
                  handleDelete={() => handleDelete(index, "file")}
                />
              ))}

              {/* Upload Button */}
              {imageURLs.length + newImageFiles.length < 4 && (
                <div className="upload-image-wrapper">
                  <label className="label" htmlFor="upload-brand">
                    <IoMdAdd />
                  </label>
                </div>
              )}

              <input
                type="file"
                id="upload-brand"
                className="visibility-hidden d-none"
                accept="image/png, image/jpg, image/jpeg, image/webp"
                multiple
                onChange={handleFileChange}
              />
            </div>
          </div>
        </>
      ) : (
        <form
          action="#"
          className={`form ${isDragging ? "dragging" : ""}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="field-wrapper">
            <div className="form-group">
              <label htmlFor="uploadImage">
                Upload up to four clear product images
              </label>
              <div className="upload-brand-logo-wrapper">
                <label className="label" htmlFor="upload-brand">
                  <input
                    type="file"
                    id="upload-brand"
                    className="visibility-hidden"
                    accept="image/png, image/jpg, image/jpeg, image/webp"
                    multiple
                    onChange={handleFileChange}
                  />
                  <div className="img-wrapper">
                    <img
                      src={uploadBrandLogoIcon}
                      alt="uploadBrandLogoIcon"
                      className="upload-icon"
                    />
                  </div>
                  <div className="text-wrapper">
                    <p className="dark_text">
                      Drop your image here, or{" "}
                      <span className="blue">browse</span>
                    </p>
                    <span className="ex-small">
                      Supports: PNG, JPG, JPEG, WEBP
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </form>
      )}

      <ErrorMessage name="image" component="div" className="error-msg" />
    </div>
  );
}
