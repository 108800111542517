import React, { memo, useEffect, useState } from "react";

import brandImg from "../../assets/images/profileImg.png";
import regroupIcon from "../../assets/images/icons/regroup-icon.svg";
import dollarSign from "../../assets/images/icons/dollar-sign.svg";
import videoFileIcon from "../../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../../assets/images/icons/live-icon.svg";
import userTallIcon from "../../assets/images/icons/user-tall-icon.svg";
import { HiArrowRight } from "react-icons/hi";
import { TbThumbDown } from "react-icons/tb";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { IoCheckmarkOutline } from "react-icons/io5";
import { getId, returnArray } from "../../utils/utils";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { Button, Spinner } from "react-bootstrap";
import {
  reviewApplicationAsyncThunk,
  toggleApplicationFavoriteAsyncThunk,
} from "../../redux/pagesSlices/applicationSlice";
import { useDispatch, useSelector } from "react-redux";
import ViewProfileDetailModal from "../ViewProfileDetailModal";
import {
  getMyFavoriteCreatorsAsyncThunk,
  toggleFavoriteCreatorAsyncThunk,
} from "../../redux/pagesSlices/creatorSlice";
import { getGMV, getGMVPerVideo } from "../../utils/getStats";

const CreatorCardComponent = memo(({ item = {}, applicantsParams }) => {
  const reviewLoading = useSelector(
    (s) => s.application?.loadings?.reviewApplicationAsyncThunk
  );
  const creatorLoading = useSelector((s)=> s.creator?.loadings?.getMyFavoriteCreatorsAsyncThunk)
  const { myFavoriteCreators } = useSelector((s) => s.creator);
  const [showModel, setShowModel] = useState(false);
  const [pricingView, setPricingView] = useState("video");
  const [creatorId, setCreatorId] = useState(null);
  const d = useDispatch();
  const pricingViewHandler = (view) => {
    setPricingView(view);
  };
  const creator = item || {};
  console.log("creator_details", creator);
  const application = item?.application || {};

  const handleCreatorId = () => {
    setCreatorId(creator?.id);
  };

  const isExistInFavorite = myFavoriteCreators?.some(
    (item) => item.creatorId === creator?.id
  );

  return (
    <div className="col-md-6 col-lg-4 mb-4">
      <div className="creator-card-main-wrapper">
        <div className="creator-title-info-wrapper">
          <div className="main-wrapper">
            <div className="img-wrapper">
              <a href="#">
                <img
                  src={
                    creator.avatar
                      ? parseImagePathToURL(creator.avatar)
                      : brandImg
                  }
                  alt="brandImg"
                />
              </a>
            </div>
            <div className="content-wrapper">
              <span
                className={`status-label ${
                  application?.isHired ? "hired" : application?.status
                }`}
              >
                {application?.isHired ? (
                  <>
                    <IoCheckmarkOutline /> Hired
                  </>
                ) : (
                  application?.status
                )}
              </span>
              <strong className="medium dark">
                <a href="#">{creator?.name}</a>
              </strong>
              <span className="small">@{creator?.userName}</span>
            </div>
          </div>
        </div>
        <div className="short-dec">
          <p>{creator?.bio}</p>
        </div>
        <div className="short-info-wrapper">
          {application?.isHired ? (
            <div className="main-wrapper">
              <div className="text">
                <div className="title">
                  <img src={videoFileIcon} alt="videoFileIcon" />
                  <p className="small">Videos</p>
                </div>
                <strong className="dark small semi_bold">
                  {item?.creator_profile?.live_streaming_cnt_30d?.value ||
                    "N/A"}
                </strong>
              </div>
              <div className="text">
                <div className="title">
                  <img src={liveIcon} alt="liveIcon" />
                  <p className="small">Lives</p>
                </div>
                <strong className="dark small semi_bold">
                  {item?.creator_profile?.video_publish_cnt_30d?.value || "N/A"}
                </strong>
              </div>
            </div>
          ) : (
            <div className="text">
              <div className="title">
                <img src={regroupIcon} alt="regroupIcon" />
                <p className="small">Estimated ROI</p>
              </div>
              <strong className="dark small semi_bold">3.4X</strong>
            </div>
          )}
          {application?.isHired ? (
            <div className="text">
              <div className="title">
                <img src={dollarSign} alt="dollarSign" />
                <p className="small">
                  ROI
                </p>
              </div>
              <strong className="dark small semi_bold">
                {item?.creator_profile?.med_gmv_revenue?.value?.format || "N/A"}
              </strong>
            </div>
          ) : (
            <div className="text">
              <div className="title">
                <img src={dollarSign} alt="dollarSign" />
                <p className="small">
                  GMV <span>(Last 28 days)</span>
                </p>
              </div>
              <strong className="dark small semi_bold">
                {item?.creator_profile?.med_gmv_revenue?.value?.format || "N/A"}
              </strong>
            </div>
          )}

          {application?.isHired ? (
            <div className="text">
              <div className="title">
                <img src={dollarSign} alt="dollarSign" />
                <p className="small">GMV Generated</p>
              </div>
              <strong className="dark small semi_bold">
                {getGMV(creator?.json) || "N/A"}
              </strong>
            </div>
          ) : (
            <div className="text">
              <div className="title">
                <img src={dollarSign} alt="dollarSign" />
                <p className="small">GMV Per Video</p>
              </div>
              <strong className="dark small semi_bold">
                {getGMVPerVideo(creator?.json) || "N/A"}
              </strong>
            </div>
          )}
        </div>
        <div className="pricing-main-wrapper">
          <ul className="pricing-tabs-wrapper">
            <li className={`${pricingView === "video" ? "active" : ""}`}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  pricingViewHandler("video");
                }}
              >
                <img src={videoFileIcon} alt="videoFileIcon" /> Videos
              </a>
            </li>
            <li className={`${pricingView === "live" ? "active" : ""}`}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  pricingViewHandler("live");
                }}
              >
                <img src={liveIcon} alt="liveIcon" /> Live
              </a>
            </li>
          </ul>
          {pricingView === "video" ? (
            <div className="pricing-content-wrapper">
              {returnArray(application?.offeredVideoPrice).length > 0 ? (
                returnArray(application?.offeredVideoPrice).map(
                  (price, index) => (
                    <div className="text" key={index}>
                      <strong className="small medium-bold dark">
                        {price?.count} Videos/Month
                      </strong>
                      <strong className="semi_bold dark">
                        ${price?.price}
                      </strong>
                    </div>
                  )
                )
              ) : (
                <div className="text">
                  <strong className="small medium-bold dark">
                    Not available for this creator
                  </strong>
                </div>
              )}
            </div>
          ) : (
            <div className="pricing-content-wrapper">
              {returnArray(application?.offeredLivePrice).length > 0 ? (
                returnArray(application?.offeredLivePrice).map(
                  (price, index) => (
                    <div className="text">
                      <strong className="small medium-bold dark">
                        5 Live/Month
                      </strong>
                      <strong className="semi_bold dark">$100</strong>
                    </div>
                  )
                )
              ) : (
                <div className="text">
                  <strong className="small medium-bold dark">
                    Not available for this creator
                  </strong>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="btn-wrapper">
          <Button
            href="#"
            onClick={(e) =>
              d(
                reviewApplicationAsyncThunk({
                  id: getId(application),
                  data: { status: "pass" },
                  callBack: () => {
                    // d(getJobApplicationsAsyncThunk({
                    //     id: item?.jobId,
                    //     params
                    // }))
                  },
                })
              )
            }
            className="btn-style light-red auto-width"
          >
            {reviewLoading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              <>
                <TbThumbDown /> Pass
              </>
            )}
          </Button>
          <Button
            href="#"
            className="btn-style light-blue"
            onClick={(e) => {
              e.preventDefault();
              setShowModel(true);
              handleCreatorId();
            }}
          >
            {" "}
            View profile <HiArrowRight />{" "}
          </Button>
        </div>
        <Button
          onClick={() => {
            d(
              toggleFavoriteCreatorAsyncThunk({
                id: getId(creator),
                callBack: () => {
                  d(
                    getMyFavoriteCreatorsAsyncThunk({
                      pagination: false,
                    })
                  );
                },
              })
            );
          }}
          className="book-mark-icon"
        >
          {isExistInFavorite ? (
            <FaHeart color={"#1E1E1E"} />
          ) : (
            <FaRegHeart color={"#1E1E1E"} />
          )}
        </Button>
      </div>
      {showModel && (
        <ViewProfileDetailModal
          viewProfileModal={showModel}
          viewProfileModalCloseHandler={() => setShowModel(false)}
          application={item}
          creatorId={creatorId}
          applicantsParams={applicantsParams}
          creatorLoading={creatorLoading}
        />
      )}
    </div>
  );
});

export default CreatorCardComponent;
