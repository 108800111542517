import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import { LuEyeOff, LuEye } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import { getMyAllJobsAsyncThunk } from "../../redux/pagesSlices/jobSlice";
import { getId, returnArray } from "../../utils/utils";
import { createInviteAsyncThunk } from "../../redux/pagesSlices/invitationSlice";
import { findCreatorsCountAsyncThunk } from "../../redux/pagesSlices/creatorSlice";

const inviteValidationSchema = Yup.object().shape({
  jobId: Yup.string().required("Job field is required"),
  videos: Yup.number().required("Videos field is required"),
  lives: Yup.number().required("Lives field is required"),
  price: Yup.number().required("Price field is required"),
  // jobIds: Yup.array()
  //   .of(Yup.string().required("Each job must be a valid string"))
  //   .min(1, "You must select at least one job")
  //   .required("Jobs field is required"),
});

export default function InviteCreatorModal({ creator, isOpen, handleClose }) {
  const { myAllJobs } = useSelector((s) => s.job);
  const createInviteLoading = useSelector(
    (s) => s.invite?.loadings?.createInviteAsyncThunk
  );
  const d = useDispatch();

  useEffect(() => {
    d(getMyAllJobsAsyncThunk({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    // d(
    //   createInvitesFromJobAsyncThunk({
    //     data: { ...values, creatorId },

    //     callBack: () => {
    //       handleClose();
    //       resetForm();
    //       d(findCreatorsCountAsyncThunk({}));
    //     },
    //   })
    // );
    d(
      createInviteAsyncThunk({
        data: { ...values, creatorId: getId(creator) },

        callBack: () => {
          handleClose();
          resetForm();
          d(findCreatorsCountAsyncThunk({}));
        },
      })
    );
  };

  return (
    <div className="change-password-modal-wrapper">
      <Modal
        show={isOpen}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal change-password-modal invite-milo-modal"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            jobId: "",
            videos: "",
            lives: "",
            price: "",
          }}
          validationSchema={inviteValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
          }) => {
            return (
              <>
                <Form className="form">
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      What job do you want to invite{" "}
                      {creator?.name || creator?.tikTokUserName} to?
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="modal-inner-wrapper">
                      <div className="col-md-12 mb-4">
                        <div className="field-wrapper">
                          <div className="form-group">
                            <label htmlFor="jobIds">Select Job</label>
                            <Select
                              styles={{ backgroundColor: "orange" }}
                              id="jobIds"
                              name="jobIds"
                              // isMulti
                              className="form-select shadow-none select-package"
                              classNamePrefix="react-select"
                              options={returnArray(myAllJobs).map((job) => ({
                                label: job?.jobTitle,
                                value: getId(job),
                              }))}
                              value={(() => {
                                const job = myAllJobs.find(
                                  (job) => getId(job) === values.jobId
                                );
                                return {
                                  label: job?.jobTitle || values.jobId,
                                  value: values.jobId,
                                };
                              })()}
                              //.map((jobId) => {
                              // const job = myAllJobs.find(
                              //   (job) => getId(job) === jobId
                              // );
                              // return {
                              //   label: job?.jobTitle || jobId,
                              //   value: jobId,
                              // };
                              // })
                              onChange={(selectedOptions) => {
                                const selectedValues = selectedOptions.value;
                                // ? selectedOptions.map(
                                //   (option) => option.value
                                // )
                                // : [];
                                setFieldValue("jobId", selectedValues);
                              }}
                              onBlur={() => {
                                setFieldValue("jobId", values.jobId);
                                setFieldTouched("jobId", true);
                              }}
                            />
                          </div>
                          <ErrorMessage
                            component="span"
                            className="error-msg"
                            name="jobIds"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <strong className="semi_bold medium mb-3 d-block">
                          Set your project offer
                        </strong>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="field-wrapper">
                              <div className="form-group">
                                <label htmlFor="videos">No. of Videos</label>
                                <input
                                  type="number"
                                  value={values.videos}
                                  className="form-control"
                                  placeholder="0"
                                  name="videos"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              <ErrorMessage
                                component="span"
                                className="error-msg"
                                name="videos"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="field-wrapper">
                              <div className="form-group">
                                <label htmlFor="lives">
                                  No. of Lives{" "}
                                  <span className="light">(optional)</span>
                                </label>
                                <input
                                  type="number"
                                  value={values.lives}
                                  className="form-control"
                                  placeholder="0"
                                  name="lives"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              <ErrorMessage
                                component="span"
                                className="error-msg"
                                name="lives"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="field-wrapper">
                              <div className="form-group">
                                <label htmlFor="price">Price</label>
                                <input
                                  type="number"
                                  value={values.price}
                                  className="form-control"
                                  placeholder="0"
                                  name="price"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              <ErrorMessage
                                component="span"
                                className="error-msg"
                                name="price"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn-style transparent"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={createInviteLoading}
                      className="btn-style"
                    >
                      {createInviteLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Save invite"
                      )}
                    </button>
                  </Modal.Footer>
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
