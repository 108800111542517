import React, { useEffect, useLayoutEffect, useState } from "react";

import { motion, AnimatePresence } from "framer-motion";

import { FiArrowRight } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import JobPostStepperComponent from "../components/JobPostStepperComponent";
import BasicInfoJobPostStep from "../components/BasicInfoJobPostStep";
import JobDescriptionJobPostStep from "../components/JobDescriptionJobPostStep";
import ProductImageJobPostStep from "../components/ProductImageJobPostStep";
import VideoSamplesJobPostStep from "../components/VideoSamplesJobPostStep";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ApplicationSettingPostJobStep from "../components/ApplicationSettingPostJobStep";
import ReviewJobPostStep from "../components/ReviewJobPostStep";
import ReviewJobDescriptionPostJob from "../components/ReviewJobDescriptionPostJob";
import { useDispatch, useSelector } from "react-redux";
import {
  createJobAsyncThunk,
  getJobAsyncThunk,
} from "../redux/pagesSlices/jobSlice";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderComponent from "../components/LoaderComponent";
import { Spinner } from "react-bootstrap";

const stepZeroSchema = Yup.object({
  jobTitle: Yup.string()
    .required("Job title is required")
    .max(150, "Job title cannot exceed 150 characters"),
  sellerShopCode: Yup.string().required("Seller shop code is required"),
  types: Yup.array()
    .of(
      Yup.string().oneOf(
        ["video", "live"],
        "Invalid type. Allowed types are 'video' or 'live'"
      )
    )
    .required("Types are required")
    .min(1, "At least one type must be selected"),
  ageRange: Yup.array()
    .of(
      Yup.string().oneOf(
        ["18-24", "24-35", "35-55", "55+"],
        "Invalid age range."
      )
    )
    .required("Age range required")
    .min(1, "At least one age range must be selected"),
  gender: Yup.array()
    .of(Yup.string().oneOf(["male", "female", "other"], "Invalid gender."))
    .required("Gender required")
    .min(1, "At least one gender must be selected"),
  categories: Yup.array()
    .of(
      Yup.string().oneOf(
        [
          "Electronics",
          "Beauty & Health",
          "Home & Living",
          "Toys & Games",
          "Automotive",
          "Books & Media",
          "Groceries & Food",
          "Pet Supplies",
          "Office Supplies",
          "Tools & Hardware",
          "Jewellery & Watches",
          "Sports & Outdoors",
          "Baby & Kids",
          "Arts & Collectibles",
        ],
        "Invalid category."
      )
    )
    .required("Category is required")
    .min(1, "At least one category must be selected"),
  product_id: Yup.string().required("Product ID is required"),
  product_link: Yup.string()
    .url("Enter a valid URL")
    .matches(/tiktok/, "The URL must contain the word 'tiktok'")
    .required("Product link is required"),
  tiktokShopUrl: Yup.string().url("Enter a valid URL").required("Tiktok shop URL is required"),
});

const stepOneSchema = Yup.object({
  jobAttributes: Yup.object({
    painPoints: Yup.string().required("Challenges or problems are required").max(2000, "Pain points cannot exceed 2000 characters"),
    benefits: Yup.string().required("Benefits are required").max(1000, "Benefits cannot exceed 1000 characters"),
    creatorType: Yup.string().required("Creator type is required").max(1000, "Creator type cannot exceed 1000 characters"),
    contentStrategy: Yup.string().required("Content strategy is required").max(2000, "Content strategy cannot exceed 2000 characters"),
  }),
});

const stepTwoSchema = Yup.object({
  image: Yup.array()
    .of(
      Yup.mixed().test("fileFormat", "Unsupported file format", (value) => {
        // Skip validation if value is a string (existing image URL)
        if (typeof value === "string") return true;

        // If value is a File, validate its type
        if (value && value.type) {
          const validFormats = [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "image/webp",
          ];
          return validFormats.includes(value.type);
        }

        return false; // Fails if value is invalid
      })
    )
    .min(1, "At least one image is required")
    .max(4, "You can upload up to 4 images only.")
    .required("At least one image is required."),
  videoUrl: Yup.string()
    .test("validate-urls", "Each URL must be valid", (value) => {
      if (!value) return false; // Fails if the string is empty or null
      const urls = value.split(",").map((url) => url.trim());
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      return urls.every((url) => urlRegex.test(url)); // Validate each URL
    })
    .required("At least one video link is required"),
});

const stepThreeSchema = Yup.object({
  // deadlineDate: Yup.date().required("Deadline date is required"),
  isPrivate: Yup.boolean().required("Job type is required"),
});

export default function JobPostPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [initialValues, setInitialValues] = useState(null);
  const { profile, user } = useSelector((s) => s.auth);
  const { job, loadings } = useSelector((s) => s.job);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const draftJobId = searchParams.get("draftJobId");

  const getJobDetails = async () => {
    const jobDetails = await dispatch(getJobAsyncThunk(draftJobId)).unwrap();

    setInitialValues({
      jobTitle: jobDetails?.jobTitle || "",
      position: jobDetails?.position || "",
      description: jobDetails?.description || "",
      avatar: jobDetails?.avatar || null,
      phone: jobDetails?.phone || "",
      website: jobDetails?.website || "",
      categories: jobDetails?.categories || [],
      sellerShopCode: jobDetails?.sellerShopCode || "",
      types: jobDetails?.types || [],
      ageRange: jobDetails?.ageRange || [],
      gender: jobDetails?.gender || [],
      categories: jobDetails?.categories || [],
      product_link: jobDetails?.product_link || "",
      product_id: jobDetails?.product_id || "",
      tiktokShopUrl: jobDetails?.tiktokShopUrl || "",
      jobAttributes: jobDetails?.jobAttributes || {
        painPoints: "",
        benefits: "",
        creatorType: "",
        contentStrategy: "",
      },

      image: jobDetails?.image || [],
      videoUrl: jobDetails?.videoUrl || "",
      isPrivate: jobDetails?.isPrivate || false,
    });
  };

  useEffect(() => {
    if (draftJobId) {
      getJobDetails();
    } else {
      setInitialValues({
        jobTitle: "",
        position: "",
        description: "",
        avatar: null,
        phone: "",
        website: "",
        categories: [],
        sellerShopCode: "",
        types: [],
        ageRange: [],
        gender: [],
        categories: [],
        tiktokShopUrl: "",
        product_id: "",
        product_link: "",
        jobAttributes: {
          painPoints: "",
          benefits: "",
          creatorType: "",
          contentStrategy: "",
        },

        image: [],
        videoUrl: "",
        isPrivate: false,
      });
    }
  }, [draftJobId, dispatch]);

  const handleNext = () => {
    if (activeStep < 6) setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };
  const isActive = (step) => step === activeStep;
  const isCompleted = (step) => step < activeStep;

  const handleSubmit = async (values, { resetForm, errors }) => {
    const formData = new FormData();

    // Append simple key-value pairs
    formData.append("jobTitle", values.jobTitle);
    formData.append("sellerShopCode", values.sellerShopCode);
    // formData.append("deadlineDate", values.deadlineDate);
    formData.append("isPrivate", values.isPrivate);
    formData.append("tiktokShopUrl", values.tiktokShopUrl);
    formData.append("product_id", values.product_id);
    formData.append("product_link", values.product_link);
    formData.append("videoUrl", values.videoUrl);

    if (draftJobId) {
      formData.append("draftJobId", draftJobId);
    }
    formData.append("brandName", profile?.name);

    // Separate old image URLs and new image files
    const oldImageUrls = values.image.filter((img) => typeof img === "string");
    const newImageFiles = values.image.filter((img) => img instanceof File);

    // Append arrays
    newImageFiles.forEach((image) => formData.append("image", image));
    formData.append("oldImageUrls", JSON.stringify(oldImageUrls));
    values.types.forEach((type) => formData.append("types[]", type));
    values.gender.forEach((gender) => formData.append("gender[]", gender));
    values.ageRange.forEach((age) => formData.append("ageRange[]", age));
    values.categories.forEach((category) =>
      formData.append("categories[]", category)
    );

    for (const key in values.jobAttributes) {
      if (values.jobAttributes.hasOwnProperty(key)) {
        formData.append(`jobAttributes[${key}]`, values.jobAttributes[key]);
      }
    }

    const contactInfo = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    };

    for (const key in contactInfo) {
      if (contactInfo.hasOwnProperty(key)) {
        formData.append(`contactInfo[${key}]`, contactInfo[key]);
      }
    }

    // Append brandId
    // formData.append("brandId", profile?._id || profile?.id);

    // Dispatch action
    dispatch(
      createJobAsyncThunk({
        data: formData,
        callBack: () => {
          navigate(`/dashboard/my-jobs?status=approved`);
          resetForm();
        },
      })
    );
  };

  if (loadings?.getJobAsyncThunk || !initialValues) {
    return <LoaderComponent />;
  }
  return (
    <div className="job-post-page">
      <Formik
        initialValues={initialValues}
        validationSchema={
          activeStep === 0
            ? stepZeroSchema
            : activeStep === 1
            ? stepOneSchema
            : activeStep === 2
            ? stepTwoSchema
            : activeStep === 3
            ? stepThreeSchema
            : stepZeroSchema
        }
        onSubmit={activeStep === 4 ? handleSubmit : handleNext}
      >
        {({ isSubmitting, values, errors, setFieldValue, setFieldTouched }) => {
          console.log("errors", errors);
          return (
            <>
              <Form className="form">
                <JobPostStepperComponent
                  activeStep={activeStep}
                  isActive={isActive}
                  isCompleted={isCompleted}
                  values={values}
                  draftJobId={draftJobId}
                />
                <div className="job-post-steps-main-content-wrapper">
                  <div className="container ex-small">
                    <div className="inner-main-step-wrapper">
                      <motion.div layout className="step-content">
                        <AnimatePresence mode="wait">
                          <motion.div
                            key={activeStep}
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -100 }}
                            transition={{ duration: 0.5 }}
                          >
                            {activeStep === 0 && (
                              <>
                                <BasicInfoJobPostStep
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                />
                              </>
                            )}
                            {activeStep === 1 && (
                              <>
                                <JobDescriptionJobPostStep />
                              </>
                            )}
                            {activeStep === 2 && (
                              <>
                                <ProductImageJobPostStep
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                                <VideoSamplesJobPostStep
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                              </>
                            )}
                            {activeStep === 3 && (
                              <>
                                <ApplicationSettingPostJobStep />
                              </>
                            )}
                            {activeStep === 4 && (
                              <>
                                <ReviewJobPostStep
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  setActiveStep={setActiveStep}
                                />
                              </>
                            )}
                          </motion.div>
                        </AnimatePresence>
                      </motion.div>
                    </div>
                    <div className="btn-wrapper">
                      {activeStep === 0 && (
                        <>
                          <span></span>
                        </>
                      )}
                      {activeStep === 4 ? (
                        <button
                          type="button"
                          className={`opacity-0 `}
                          disabled
                        ></button>
                      ) : (
                        <>
                          {activeStep !== 0 && (
                            <button
                              type="button"
                              className={`btn-style transparent-border ${
                                activeStep === 4
                              }`}
                              onClick={handleBack}
                              disabled={activeStep === 0}
                            >
                              <FiArrowLeft /> Previous
                            </button>
                          )}
                        </>
                      )}

                      {activeStep < 5 && (
                        <button
                          type="submit"
                          className="btn-style "
                          disabled={loadings?.createJobAsyncThunk}
                        >
                          {activeStep === 4 ? (
                            loadings?.createJobAsyncThunk ? (
                              <Spinner size="sm" />
                            ) : (
                              "Post"
                            )
                          ) : (
                            <>
                              Next <FiArrowRight />{" "}
                            </>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
