import React, { useState } from "react";
import leftCheveIcon from "../assets/images/icons/left-chev-icon.svg";
import RangeComponent from "./RangeComponent";
import { useLocation } from "react-router-dom";

export default function SideBarComponent({
  selectedFilters = {
    categories: [],
    promotionType: [],
    preferredGender: [],
    AgeRange: [],
  },
  setSelectedFilters = () => {},
  clearAllFilters = () => {},
  sideBarOpen,
  sideBarOpenHandler,
  arrangementArray = [
    { name: "GMVRange", type: "multiple" },
    { name: "ROIRange", type: "multiple" },
    { name: "Categories", type: "multiple" },
    { name: "PromotionType", type: "multiple" },
    { name: "PreferredGender", type: "single" },
    { name: "AgeRange", type: "single" },
  ],
}) {
  const [activeMenu, setActiveMenu] = useState([]);

  const toggleMenu = (menu) => {
    console.log("🚀 ~ toggleMenu ~ menu:", menu);
    setActiveMenu((prevMenu) => {
      if (prevMenu.includes(menu)) {
        return prevMenu.filter((item) => item !== menu);
      }
      return [...prevMenu, menu];
    });
  };

  const handleFilterChange = (category, value, type = "multiple") => {
    setSelectedFilters((prevFilters) => {
      if (type === "single") {
        // For single selection, directly assign the value
        return {
          ...prevFilters,
          [category]: value,
        };
      }

      // For multiple selection, ensure currentValues is an array
      const currentValues = Array.isArray(prevFilters[category])
        ? prevFilters[category]
        : [];
      const isSelected = currentValues.includes(value);
      const isAllSelected = value === "All";

      return {
        ...prevFilters,
        [category]: isAllSelected
          ? ["All"]
          : isSelected
          ? currentValues.filter((item) => item !== value)
          : [...currentValues, value].filter((item) => item !== "All"),
      };
    });
  };

  const components = {
    GMVRange: GMVRange,
    ROIRange: ROIRange,
    Categories: Categories,
    PromotionType: PromotionType,
    PreferredGender: PreferredGender,
    StatusFilter: StatusFilter,
    AgeRange: AgeRange,
  };

  return (
    <div className={`sidebar-main-wrapper ${sideBarOpen ? "active" : ""}`}>
      <div className="sidebar-top-bar">
        <div className="filter-text-wrapper" onClick={sideBarOpenHandler}>
          <strong className="medium">Filter</strong>
          <img
            src={leftCheveIcon}
            alt="leftCheveIcon"
            className="left-chev-icon"
          />
        </div>
        {!sideBarOpen && (
          <button
            onClick={clearAllFilters}
            className="blue custom-btn semi_bold"
          >
            Clear All
          </button>
        )}
      </div>
      <div className="sidebar-main-listings-wrapper">
        <div className="listing-wrapper">
          <ul className="listings listing-category-titles">
            {arrangementArray.map((category, index) => {
              const Component = components[category.name];
              return (
                <Component
                  key={index}
                  toggleMenu={toggleMenu}
                  activeMenu={activeMenu}
                  selectedFilters={selectedFilters}
                  handleFilterChange={(key, value) =>
                    handleFilterChange(key, value, category.type)
                  }
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

const Categories = ({
  toggleMenu,
  activeMenu,
  selectedFilters,
  handleFilterChange,
}) => {
  const location = useLocation();

  const categoriesArray = [
    "All",
    "Electronics",
    "Fashion",
    "Beauty & Health",
    "Home & Living",
    "Toys & Games",
    "Automotive",
    "Books & Media",
    "Groceries & Food",
    "Pet Supplies",
    "Office Supplies",
    "Tools & Hardware",
    "Jewellery & Watches",
    "Sports & Outdoors",
    "Baby & Kids",
    "Arts & Collectibles",
  ];

  return (
    <>
      <li
        onClick={() => toggleMenu("categories")}
        className={`${activeMenu.includes("categories") ? "active" : ""}`}
      >
        Categories
        <ul className="sub-menu-list" onClick={(e) => e.stopPropagation()}>
          {categoriesArray.map((category, index) => (
            <li key={index}>
              <div className="filter-category">
                <div className="custom-checkbox-wrapper category-checkbox">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={selectedFilters.categories.includes(category)}
                      onChange={() =>
                        handleFilterChange("categories", category)
                      }
                    />
                    <span className="checkmark"></span>
                    <span className="sub-category-name">{category}</span>
                  </label>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </li>
    </>
  );
};

const PromotionType = ({
  toggleMenu,
  activeMenu,
  selectedFilters,
  handleFilterChange,
}) => {
  const promotionTypeArray = ["Video", "Live"];
  return (
    <li
      onClick={() => toggleMenu("promotionType")}
      className={activeMenu.includes("promotionType") ? "active" : ""}
    >
      Promotion type
      <ul className="sub-menu-list" onClick={(e) => e.stopPropagation()}>
        {promotionTypeArray.map((promotionType, index) => (
          <li key={index}>
            <div className="filter-category">
              <div className="custom-checkbox-wrapper category-checkbox">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedFilters.promotionType.includes(
                      promotionType
                    )}
                    onChange={() =>
                      handleFilterChange("promotionType", promotionType)
                    }
                  />
                  <span className="checkmark"></span>
                  <span className="sub-category-name">{promotionType}</span>
                </label>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </li>
  );
};

const GMVRange = ({
  toggleMenu,
  activeMenu,
  selectedFilters,
  handleFilterChange,
}) => {
  const defaultValue = [100, 700];
  const min = 20;
  const max = 800;
  return (
    <li>
      GMV Range
      <div className="range-wrapper mt-3">
        <RangeComponent defaultValue={defaultValue} min={min} max={max} />
      </div>
    </li>
  );
};

const ROIRange = ({
  toggleMenu,
  activeMenu,
  selectedFilters,
  handleFilterChange,
}) => {
  const defaultValue = [100, 700];
  const min = 20;
  const max = 800;
  return (
    <li>
      Estimated ROI Range
      <div className="range-wrapper mt-3">
        <RangeComponent />
      </div>
    </li>
  );
};

const PreferredGender = ({
  toggleMenu,
  activeMenu,
  selectedFilters,
  handleFilterChange,
}) => {
  const preferredGenderArray = ["All", "Male", "Female", "Other"];
  return (
    <li
      onClick={() => toggleMenu("preferredGender")}
      className={activeMenu.includes("preferredGender") ? "active" : ""}
    >
      Preferred Gender
      <ul className="sub-menu-list" onClick={(e) => e.stopPropagation()}>
        {preferredGenderArray.map((preferredGender, index) => (
          <li key={index}>
            <div className="filter-category">
              <div className="custom-checkbox-wrapper category-checkbox">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={
                      selectedFilters.preferredGender === preferredGender
                    }
                    onChange={() =>
                      handleFilterChange("preferredGender", preferredGender)
                    }
                  />
                  <span className="checkmark"></span>
                  <span className="sub-category-name">{preferredGender}</span>
                </label>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </li>
  );
};

const StatusFilter = ({
  toggleMenu,
  activeMenu,
  selectedFilters,
  handleFilterChange,
}) => {
  const array = [
    { label: "Applied", value: "applied" },
    { label: "Hired", value: "approved" },
    { label: "Rejected", value: "pass" },
  ];
  return (
    <li
      onClick={() => toggleMenu("preferredGender")}
      className={activeMenu.includes("preferredGender") ? "active" : ""}
    >
      Status Filter
      <ul className="sub-menu-list" onClick={(e) => e.stopPropagation()}>
        {array.map((item, index) => (
          <li key={index}>
            <div className="filter-category">
              <div className="custom-checkbox-wrapper category-checkbox">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedFilters.status === item.value}
                    onChange={() => handleFilterChange("status", item.value)}
                  />
                  <span className="checkmark"></span>
                  <span className="sub-category-name">{item.label}</span>
                </label>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </li>
  );
};

const AgeRange = ({
  toggleMenu,
  activeMenu,
  selectedFilters,
  handleFilterChange,
}) => {
  const array = [
    { label: "All", value: "All" },
    { label: "18-24", value: "18-24" },
    { label: "24-35", value: "24-35" },
    { label: "35-55", value: "35-55" },
    { label: "55+", value: "55+" },
  ];
  return (
    <li
      onClick={() => toggleMenu("AgeRange")}
      className={activeMenu.includes("AgeRange") ? "active" : ""}
    >
      Age
      <ul className="sub-menu-list" onClick={(e) => e.stopPropagation()}>
        {array.map((item, index) => (
          <li key={index}>
            <div className="filter-category">
              <div className="custom-checkbox-wrapper category-checkbox">
                <label className="checkbox-label">
                  <input
                    type="radio" // Single selection, use 'radio' instead of 'checkbox'
                    name="ageRange" // Ensure all inputs are part of the same group
                    checked={selectedFilters.AgeRange.includes(item.value)}
                    value={item.value}
                    onChange={() =>
                      handleFilterChange("AgeRange", item.value, "single")
                    }
                  />
                  <span className="checkmark"></span>
                  <span className="sub-category-name">{item.label}</span>
                </label>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </li>
  );
};
