import React from "react";

import jobImg from "../assets/images/job-img.svg";
import brandImg from "../assets/images/brand-img.svg";
import videoFileIcon from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import applyNowRightArrow from "../assets/images/icons/apply-now-right-arrow.svg";
import { getId, returnArray } from "../utils/utils";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { dateFormat } from "../utils/format";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineTimer } from "react-icons/md";

export default function JobCardComponent({
  job,
  jobTitle,
  brandImg,
  brandName,
  jobPostedTime,
  key,
}) {
  // console.log("🚀 ~ job:", job);
  const router = useNavigate();
  const { id } = useParams();
  // console.log("🚀 ~ id:", id);
  return (
    <div className="col-md-4 mb-5" key={key}>
      <div className="job-card-main-wrapper">
        <div className="job-img-wrapper">
          <Link to={`/dashboard/job-detail/${job?.id}`}>
            <img
              src={
                returnArray(job?.image).length > 0
                  ? parseImagePathToURL(job?.image[0])
                  : jobImg
              }
              alt="jobImg"
              className="job-img"
            />
          </Link>
          {/* <span className="pending-time white"><MdOutlineTimer/> 2 days left</span> */}
        </div>
        <div className="job-content-wrapper">
          <div className="brand-top-info">
            <div className="platforms-wrapper">
              {returnArray(job?.types).map((type, index) => (
                <div
                  key={index}
                  className={`platform ${
                    type == "live" ? "light-pink-bg" : "light-gray-bg"
                  }`}
                >
                  <img
                    src={type === "live" ? liveIcon : videoFileIcon}
                    alt="videoFileIcon"
                  />
                  <span>{type}</span>
                </div>
              ))}
            </div>
            <div className="job-status">
              <span className="status">
                {job?.status === "pending"
                  ? "Pending"
                  : job?.status === "approved"
                  ? "In progress"
                  : job?.status === "draft"
                  ? "Drafts"
                  : job?.status === "completed"
                  ? "Completed"
                  : "Closed"}
              </span>
            </div>
          </div>
          <div className="job-title-wrapper">
            <strong className="dark">
              <Link to={`/dashboard/job-detail/${job?.id}`}>
                {job.jobTitle || jobTitle}
              </Link>
            </strong>
          </div>
          {job?.status === "draft" ? (
            <div className="short-description-wrapper-draft">
              <div className="text">
                <span>Created On</span>
                <strong className="small">{dateFormat(job?.createdAt)}</strong>
              </div>
              <div className="text">
                <span>Proposals</span>
                <strong className="small">---</strong>
              </div>
            </div>
          ) : (
            <div className="short-description-wrapper">
              <div className="text">
                <span>Video</span>
                <strong className="small">1/3</strong>
              </div>
              <div className="text">
                <span>Lives</span>
                <strong className="small">1/3</strong>
              </div>
              <div className="text">
                <span>ROI.</span>
                <strong className="small">1/3</strong>
              </div>
              <div className="text">
                <span>GMV Gen..</span>
                <strong className="small">$87</strong>
              </div>
            </div>
          )}
        </div>
        {job?.status === "draft" ? (
          <div className="apply-now-btn">
            <Link
              to={`/job-post?draftJobId=${job?.id}`}
              className="btn-style full"
            >
              Publish Job
            </Link>
          </div>
        ) : (
          <div className="apply-now-btn">
            <Link
              to={`/dashboard/job-detail/${job?.id}`}
              className="btn-style full"
            >
              View job overview{" "}
              <img src={applyNowRightArrow} alt="applyNowRightArrow" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
