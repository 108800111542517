import React, { useState } from "react";
import { Link } from "react-router-dom";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import { parseImagePathToURL } from "../utils/globalFunctions";
import smsIcon from "../assets/images/icons/sms-icon.svg";
import eyeIcon from "../assets/images/icons/eye-icon.svg";
import { dateFormat } from "../utils/format";
import { getId } from "../utils/utils";
import { getRoi, newGetGMV } from "../utils/getStats";
import { GoEye } from "react-icons/go";

export default function MyJob({ application }) {
  console.log("application", application);

  return (
    <tr>
      <td>
        <div className="main-wrapper circular">
          <div className="img-wrapper">
            <Link
              to={`/dashboard/creators/${getId(
                application
              )}?applicationId=${getId(application?.application)}`}
            >
              <img
                src={
                  application?.avatar
                    ? parseImagePathToURL(application?.avatar)
                    : avatarIcon
                }
                alt="JobsImg"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <p className="dark">
              <Link
                to={`/dashboard/creators/${getId(
                  application
                )}?applicationId=${getId(application?.application)}`}
              >
                {application?.userName}
              </Link>
            </p>
          </div>
        </div>
      </td>
      <td>
        <div className="applied-status rejected">
          <span className="icon"></span>
          <p className="dark">{application?.jobDetails?.status}</p>
        </div>
      </td>
      <td>
        <p className="dark">{application?.jobDetails?.jobTitle}</p>
      </td>
      <td>
        <p className="dark">
          {dateFormat(application?.jobProgressInfo?.ongoingTaskStartTime)}
        </p>
      </td>
      <td>
        <p className="dark">
          {dateFormat(application?.jobProgressInfo?.ongoingTaskEndTime)}
        </p>
      </td>
      <td>
        <p className="dark">
          {application?.jobProgressInfo?.completedVideoCount ?? 0}/
          {application?.jobProgressInfo?.totalVideoCount ?? 0}
        </p>{" "}
      </td>
      <td>
        <p className="dark">
          {application?.jobProgressInfo?.completedLiveCount ?? 0}/
          {application?.jobProgressInfo?.totalLiveCount ?? 0}
        </p>{" "}
      </td>
      <td>
        <p className="dark">
          {newGetGMV(
            application?.json?.creator_profile?.med_gmv_revenue?.value?.value,
            application?.json?.creator_profile?.med_gmv_revenue_range?.value
          )}
        </p>{" "}
      </td>
      <td>
        <p className="dark">
          {" "}
          {Number(
            getRoi(
              application?.json?.creator_profile?.med_gmv_revenue?.value?.value,
              application?.json?.creator_profile?.med_gmv_revenue_range?.value,
              application?.application?.paymentInfo?.amount
            )
          ).toFixed(0)}X
        </p>
      </td>
      <td style={{ textAlign: "right" }}>
        <Link
          to={`/dashboard/creators/${getId(application)}?applicationId=${getId(
            application?.application
          )}`}
        >
          <div className="eye-icon">
            {/* <img src={eyeIcon} alt="smsIcon" /> */}
            <GoEye />
          </div>
        </Link>
      </td>
    </tr>
  );
}
