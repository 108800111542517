import React, { useState } from "react";
import pointericon from "../assets/images/icons/pointer-icon.svg";
import { FaPause, FaPlay } from "react-icons/fa";
import videoMediaImg from "../assets/images/video-media-img.svg";

  export default function ReviewVideoSamplesJobPostStep({
    values,
    setActiveStep,
  }) {
    // Split the video URLs from the string
    const videoUrls = values.videoUrl
    ? values.videoUrl.split(",").map((url) => url.trim())
    : [];

  // State to track which video is currently playing
  const [playingIndex, setPlayingIndex] = useState(null);

  const handlePlay = (index) => {
    // Pause all videos except the one being played
    videoUrls.forEach((_, idx) => {
      const videoElement = document.getElementById(`video-${idx}`);
      if (videoElement) {
        if (idx !== index) videoElement.pause();
      }
    });

    // Set the current playing video index
    setPlayingIndex(index);
  };

  const handlePause = (index) => {
    // Pause the current video and reset the playing index
    const videoElement = document.getElementById(`video-${index}`);
    if (videoElement) videoElement.pause();
    setPlayingIndex(null);
  };

    return (
      <div className="inner-step-wrapper">
        <div className="title-wrapper has-icon mb-4">
          <h3 className="step-title">Video samples</h3>
          <button className="edit-icon-wrapper" onClick={() => setActiveStep(1)}>
            <img src={pointericon} alt="Edit icon" />
          </button>
        </div>
        <div className="sample-product-videos-listing">
          <ul className="sample-videos-list">
          {videoUrls.map((url, index) => (
            <li key={index} style={{ position: "relative" }}>
              <video
                id={`video-${index}`}
                controls
                width="600"
                poster={videoMediaImg}
                onPlay={() => handlePlay(index)}
                onPause={() => setPlayingIndex(null)}
              >
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {playingIndex === index ? (
                <div
                  className="icon-wrapper"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => handlePause(index)}
                >
                  <FaPause />
                </div>
              ) : (
                <div
                  className="icon-wrapper"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const videoElement = document.getElementById(
                      `video-${index}`
                    );
                    if (videoElement) videoElement.play();
                  }}
                >
                  <FaPlay />
                </div>
              )}
            </li>
          ))}
          </ul>
        </div>
      </div>
  );
}
