import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import dollarSign from "../../assets/images/icons/dollar-sign.svg";
import avatarIcon from "../../assets/images/icons/avatar-icon.svg";
import closeIcon from "../../assets/images/icons/x-icon.svg";
import {
  acceptApplicationAsyncThunk,
  getJobApplicantsAsyncThunk,
} from "../../redux/pagesSlices/applicationSlice";
import { returnArray, getId } from "../../utils/utils";
import { createPaymentAsyncThunk } from "../../redux/pagesSlices/paymentSlice";
import { getStripe } from "../../utils/stripe/client";
import {
  getCreatorAsyncThunk,
  getCreatorDetailAsyncThunk,
} from "../../redux/pagesSlices/creatorSlice";
import {
  generateObjectId,
  parseImagePathToURL,
} from "../../utils/globalFunctions";
import { ApiRequests } from "../../service/ApiRequests";
import { useChannel } from "ably/react";
import { toast } from "react-toastify";

const ContractSettingModal = ({
  contractSettingModalCloseHandler,
  contractSettingModal,
  applicationDetails,
  creator,
  viewProfileModalCloseHandler,
}) => {
  const dispatch = useDispatch();
  const acceptApplicationLoading = useSelector(
    (s) => s.application.loadings.acceptApplicationAsyncThunk
  );
  const { channel: notification } = useChannel("notifications");
  const { brand } = useSelector((s) => s.auth);
  const { creator: creatorDetails } = useSelector((s) => s.creator);
  console.log("applicationDetails", applicationDetails);
  useEffect(() => {
    if (creator) {
      dispatch(getCreatorAsyncThunk(creator));
    }
  }, [creator]);

  // Formik setup
  const formik = useFormik({
    initialValues: {
      videoPrice: {},
      livePrice: {},
      shouldAutoRenew: false,
      expected_gmv: "",
      endTime: "",
      termsAccepted: false,
    },
    validationSchema: Yup.object({
      videoPrice: Yup.object().test(
        "not-empty-or-null",
        "Video scope must be selected",
        (value) =>
          !value ||
          Object.keys(value).length === 0 ||
          Object.keys(value).length > 0
      ),
      livePrice: Yup.object().test(
        "not-empty-or-null",
        "Live scope must be selected",
        (value) =>
          !value ||
          Object.keys(value).length === 0 ||
          Object.keys(value).length > 0
      ),
      expected_gmv: Yup.string()
        .required("Expected GMV is required")
        .matches(/^\$?\d+$/, "Expected GMV must be a valid amount"),
      endTime: Yup.string().required("Job valid until is required"),
      termsAccepted: Yup.boolean()
        .oneOf([true], "You must accept the terms and conditions")
        .required("Terms acceptance is required"),
    }),
    onSubmit: async (values) => {
      const data = {
        ...values,
        status: "approved",
      };
      delete data.termsAccepted;
      try {
        const livePrice = values?.livePrice?.price ?? 0;
        const videoPrice = values?.videoPrice?.price ?? 0;
        const amount = Number(livePrice + videoPrice).toFixed(1);
        let jobID = applicationDetails?.jobId;

        const applicationData = { ...data };
        if (amount < 1) {
          return toast.error("Minimum amount is 1$");
        }
        if (typeof applicationDetails?.jobId !== "string") {
          jobID = getId(applicationDetails?.jobId);
        }
        console.log("applicationData", applicationData);

        await dispatch(
          acceptApplicationAsyncThunk({
            data: applicationData,
            id: getId(applicationDetails),
            callBack: async () => {
              //   await handlePayCreator(amount, getId(applicationDetails));
              await dispatch(
                getJobApplicantsAsyncThunk({
                  id: jobID,
                  params: {
                    populate: "creatorId",
                  },
                })
              );
              const BrandMessage = `Great news! <br/> Your proposal has been reviewed and accepted by <strong>${brand?.name}!</strong> <br/><br/> We're excited to get started and can’t wait to see your job's success with this creator
To get started smoothly, please follow these steps
 <br/><br/> <strong> 1. Arrange Payment with the Brand </strong><br/> Connect Directly: Reply to this message to start a conversation with the brand and coordinate payment details. <br/><br/><strong> 2. Discuss Project Details </strong><br/> Open Communication: Use this conversation to discuss the project's scope, deadlines, and any specific requirements.<br/><br/><strong> 3. Confirm Samples </strong><br/> If you haven't already, make sure you submit a sample request and that your sample is on the way. Please communicate with the brand if you haven't received a sample.`;
              const messageData = {
                _id: generateObjectId(),
                sender_id: getId(brand),
                recipient_id: getId(creatorDetails),
                content: BrandMessage,
                applicationId: getId(applicationDetails),
                type: "system",
                read_by: [getId(brand)],
              };
              const response = await ApiRequests.saveMessage(messageData);
              notification.publish({
                name: "notification",
                data: response?.data?.message,
              });
              notification.publish({
                name: "new room",
                data: response?.data?.message,
              });
              // Create and send creator's message
              const creatorMessageData = {
                _id: generateObjectId(),
                sender_id: getId(creatorDetails),
                recipient_id: getId(brand),
                type: "system",
                applicationId: getId(applicationDetails),
                content: `Great news! <br/> Your offer has been reviewed and accepted by <strong>${creatorDetails?.name}!</strong> <br/><br/>We're excited to have you on board and can't wait to see what you'll create.<br/></br> To get started smoothly, please follow these steps:<br/><br/><strong>1. Arrange Payment with the Brand</strong><br/>Connect Directly: Reply to this message to start a conversation with the brand and coordinate payment details.<br/><br/><strong>2. Discuss Project Details</strong><br/>Open Communication: Use this conversation to discuss the project's scope, deadlines, and any specific requirements.<br/><br/><strong>3. Confirm Samples</strong><br/>If you haven't already, make sure you submit a sample request and that your sample is on the way. Please communicate with the brand if you haven't received a sample.`,
                read_by: [getId(creatorDetails)],
              };
              const response2 = await ApiRequests.saveMessage(
                creatorMessageData
              );
              notification.publish({
                name: "notification",
                data: response2?.data?.message,
              });
              notification.publish({
                name: "new room",
                data: response2?.data?.message,
              });
              contractSettingModalCloseHandler();
              if (viewProfileModalCloseHandler) {
                viewProfileModalCloseHandler();
              }
            },
          })
        );
      } catch (error) {
        console.error("Error in onSubmit:", error.message);
        toast.error("Something went wrong. Please try again.");
      }
    },
  });
  //   const handlePayCreator = async (amount, applicationId) => {
  //     try {
  //       if (amount > 1) {
  //         const stripe = await getStripe();

  //         const { clientSecret } = await dispatch(
  //           createPaymentAsyncThunk({
  //             data: {
  //               amount,
  //               currency: "usd",
  //               paymentMethod: "card",
  //               creatorAccount: creatorDetails?.stripe_account_id,
  //               applicationId,
  //             },
  //           })
  //         ).unwrap();

  //         const paymentResult = await stripe.confirmCardPayment(clientSecret);

  //         if (paymentResult.error) {
  //           console.error("Payment Error:", paymentResult.error.message);
  //           throw new Error(paymentResult.error.message);
  //         }

  //         console.log("Payment Success:", paymentResult.paymentIntent.id);
  //         return {
  //           success: true,
  //           paymentIntentId: paymentResult.paymentIntent.id,
  //         };
  //       }
  //     } catch (error) {
  //       console.error("Payment Intent Error:", error.message);
  //       return { success: false, error: error.message };
  //     }
  //   };

  return (
    <Modal
      show={contractSettingModal}
      onHide={contractSettingModalCloseHandler}
      centered
      className="custom-modal contract-settings-modal"
    >
      <Modal.Body>
        <div className="main-body-wrapper">
          <div className="row">
            <div className="col-md-7">
              <div className="left-body-wrapper">
                <div className="title-wrapper">
                  <h3>Contract settings and payment</h3>
                </div>
                <form onSubmit={formik.handleSubmit} className="form">
                  <div className="row">
                    {returnArray(applicationDetails?.offeredVideoPrice).length >
                    0 ? (
                      <div className="col-md-7">
                        <div className="field-wrapper">
                          <div className="form-group">
                            <label htmlFor="videoPrice">
                              Video scope and timeline
                            </label>
                            <DropdownButton
                              id={`dropdown-button-drop-down`}
                              drop={"down"}
                              variant="secondary"
                              className="custom-dropdown"
                              title={
                                formik.values.videoPrice?.count
                                  ? `${formik.values.videoPrice?.count} Videos, ${formik.values.videoPrice?.durationCount} ${formik.values.videoPrice?.duration}, $${formik.values.videoPrice?.price}`
                                  : "Select Video Scope"
                              }
                            >
                              {returnArray(
                                applicationDetails?.offeredVideoPrice
                              ).map((item, i) => (
                                <Dropdown.Item
                                  key={i}
                                  onClick={() =>
                                    formik.setFieldValue("videoPrice", item)
                                  }
                                >
                                  {item?.count} Videos, {item?.durationCount}{" "}
                                  {item?.duration}, ${item?.price}
                                </Dropdown.Item>
                              ))}
                            </DropdownButton>
                            {formik.errors.videoPrice &&
                              formik.touched.videoPrice && (
                                <div className="text-danger">
                                  {formik.errors.videoPrice}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {returnArray(applicationDetails?.offeredLivePrice).length >
                    0 ? (
                      <div className="col-md-7">
                        <div className="field-wrapper">
                          <div className="form-group">
                            <label htmlFor="livePrice">
                              Live scope and timeline
                            </label>
                            <DropdownButton
                              id={`dropdown-button-drop-down`}
                              drop={"down"}
                              variant="secondary"
                              className="custom-dropdown"
                              title={
                                formik.values.livePrice?.count
                                  ? `${formik.values.livePrice?.count} Lives, ${formik.values.livePrice?.durationCount} ${formik.values.livePrice?.duration}, $${formik.values.livePrice?.price}`
                                  : "Select Live Scope"
                              }
                            >
                              {returnArray(
                                applicationDetails?.offeredLivePrice
                              ).map((item, i) => (
                                <Dropdown.Item
                                  key={i}
                                  onClick={() =>
                                    formik.setFieldValue("livePrice", item)
                                  }
                                >
                                  {item?.count} Lives, {item?.durationCount}{" "}
                                  {item?.duration}, ${item?.price}
                                </Dropdown.Item>
                              ))}
                            </DropdownButton>
                            {formik.errors.livePrice &&
                              formik.touched.livePrice && (
                                <div className="text-danger">
                                  {formik.errors.livePrice}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="single-field d-flex gap-3 my-2">
                      <div className="text-wrapper">
                        <div className="form-group">
                          <strong className="dark medium medium-bold">
                            Auto-renew contract
                          </strong>
                          <span>
                            Auto renew this contract if the creator meets the{" "}
                            <br /> expected GMV within the stipulated timeframe
                          </span>
                        </div>
                      </div>
                      <div className="custom-toggle-switch-wrapper">
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="shouldAutoRenew"
                            checked={formik.values.shouldAutoRenew}
                            onChange={formik.handleChange}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-md-7">
                      <div className="field-wrapper disabled">
                        <div className="form-group with-left-sign">
                          <label
                            htmlFor="expected_gmv"
                            className="readonly-input-label"
                          >
                            Expected GMV
                          </label>
                          <input
                            type="text"
                            id="expected_gmv"
                            className="form-control"
                            placeholder="100"
                            value={formik.values.expected_gmv}
                            onChange={formik.handleChange}
                          />
                          <img
                            src={dollarSign}
                            alt="dollarSign"
                            className="sign-wrapper"
                          />
                        </div>
                        {formik.errors.expected_gmv &&
                          formik.touched.expected_gmv && (
                            <div className="text-danger">
                              {formik.errors.expected_gmv}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="field-wrapper disabled">
                        <div className="form-group with-left-sign">
                          <label
                            htmlFor="endTime"
                            className="readonly-input-label"
                          >
                            Job Valid until
                          </label>
                          <input
                            type="date"
                            id="endTime"
                            min={new Date().toISOString().split("T")[0]}
                            className="form-control"
                            placeholder="job valid until"
                            value={formik.values.endTime}
                            onChange={formik.handleChange}
                          />
                          <img
                            src={dollarSign}
                            alt="dollarSign"
                            className="sign-wrapper"
                          />
                        </div>
                        {formik.errors.endTime && formik.touched.endTime && (
                          <div className="text-danger">
                            {formik.errors.endTime}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="custom-checkbox-wrapper">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          name="termsAccepted"
                          checked={formik.values.termsAccepted}
                          onChange={formik.handleChange}
                        />
                        <span className="checkmark"></span>
                        By continuing I agree with BMC{" "}
                        <a
                          onClick={(e) => e.preventDefault()}
                          href="#"
                          className="blue"
                        >
                          Terms
                        </a>{" "}
                        and{" "}
                        <a
                          onClick={(e) => e.preventDefault()}
                          href="#"
                          className="blue"
                        >
                          Conditions
                        </a>
                      </label>
                      {formik.errors.termsAccepted &&
                        formik.touched.termsAccepted && (
                          <div className="text-danger">
                            {formik.errors.termsAccepted}
                          </div>
                        )}
                    </div>
                  </div>
                </form>
                <div className="hire-btn-wrapper">
                  <button
                    type="submit"
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                    className="btn-style"
                  >
                    {formik.isSubmitting ? <Spinner size="sm" /> : "Confirm"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="right-body-wrapper">
                <div className="cross-icon-wrapper">
                  <a href="#">
                    {" "}
                    <img src={closeIcon} alt="closeIcon" />{" "}
                  </a>
                </div>
                <div className="creator-info-main">
                  <div className="creator-info-wrapper">
                    <span className={"label"}>Creator</span>
                    <div className="creator">
                      <div className="creator-img">
                        <img
                          src={
                            creatorDetails?.avatar
                              ? parseImagePathToURL(creatorDetails?.avatar)
                              : avatarIcon
                          }
                          alt="creartor-img"
                        />
                      </div>
                      <div className="text-wrapper">
                        <strong className={"dark"}>
                          {creatorDetails?.name ||
                            creatorDetails?.tikTokUserName}
                        </strong>
                        <span>@{creatorDetails?.tikTokUserName}</span>
                      </div>
                    </div>
                  </div>
                  <div className="project-detail">
                    <span className="label">Project</span>
                    <strong className="semi_bold dark">
                      Physician's Choice Digestiv#1 Probiotic{" "}
                    </strong>
                  </div>
                  <div className="cost-info">
                    <strong className={"dark"}> Cost breakdown</strong>
                    <div className="main-wrapper">
                      <div className="text-wrapper">
                        <span>5 videos</span>
                        <strong>$500</strong>
                      </div>
                      <div className="text-wrapper">
                        <span>1 live</span>
                        <strong>$100</strong>
                      </div>
                      <div className="text-wrapper">
                        <span>Total</span>
                        <strong>$600</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContractSettingModal;
