import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Dropdown, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateBrandAsyncThunk } from "../../redux/pagesSlices/brandSlice";
import { updateProfile } from "../../redux/pagesSlices/authSlice";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  name: Yup.string()
    .required("Business name is required")
    .max(50, "Business name cannot exceed 50 characters"),
  address: Yup.string(),
  phone: Yup.string().matches(
    /^\+?[1-9]\d{1,14}$/,
    "Phone number is not valid. Must be in international format (e.g., +123456789)."
  ),
  businessEmail: Yup.string()
    .email("Invalid email")
    .required("Business email is required"),
  taxType: Yup.string(),
  idNumber: Yup.string(),
});

export default function AccountTab() {
  const { user, brand } = useSelector((s) => s.auth);
  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const {
      firstName,
      lastName,
      name,
      address,
      phone,
      businessEmail,
      taxType,
      idNumber,
    } = values;

    const userData = { firstName, lastName, name: firstName + " " + lastName };
    const brandData = {
      name,
      address,
      phone,
      taxType,
      idNumber,
    };

    if (businessEmail !== brand?.email) {
      brandData.email = businessEmail;
    }

    dispatch(
      updateProfile({
        data: userData,
        noToastify: true,
      })
    );
    dispatch(
      updateBrandAsyncThunk({
        id: brand?.id || brand?._id,
        data: brandData,
      })
    );
  };

  return (
    <div className="accounts-tabs-main-wrapper">
      <div className="portal-settings-tab-header">
        <strong className="dark large">Account</strong>
      </div>
      <div className="form-section">
        <Formik
          initialValues={{
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            email: user?.email || "",
            name: brand?.name || "",
            address: brand?.address || "",
            phone: brand?.phone || "",
            businessEmail: brand?.email || "",
            taxType: brand?.taxType || "",
            idNumber: brand?.idNumber || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, dirty, isSubmitting }) => {
            console.log("errors", errors);
            return (
              <Form className="form">
                <div className="row small">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="firstName">First Name</label>
                      <Field
                        type="text"
                        name="firstName"
                        className="form-control"
                        placeholder="John"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error-msg"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <Field
                        type="text"
                        name="lastName"
                        className="form-control"
                        placeholder="Doe"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error-msg"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        name="email"
                        disabled
                        className="form-control"
                        placeholder="email@example.com"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-msg"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="portal-settings-tab-header mt-5 mb-4 mx-0 p-0 pb-1">
                    <h3>Brand information</h3>
                    <span className="mb-3">
                      This information will appear on your future bills and
                      invoices
                    </span>
                  </div>
                </div>
                <div className="row small">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="name">Legal Business Name</label>
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Burger King"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error-msg"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="address">Business Address</label>
                      <Field
                        type="text"
                        name="address"
                        className="form-control"
                        placeholder="Texas, United States"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="phone">Business Phone Number</label>
                      <Field
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="+1 000 000 000 00"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error-msg"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="businessEmail">
                        Business Email Address
                      </label>
                      <Field
                        type="email"
                        name="businessEmail"
                        className="form-control"
                        placeholder="email@example.com"
                      />
                      <ErrorMessage
                        name="businessEmail"
                        component="div"
                        className="error-msg"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="taxType">Tax Type (VAT, EIN)</label>
                      <Dropdown
                        className="portalsettings-dropdown custom-dropdown"
                        onSelect={(e) => setFieldValue("taxType", e)}
                      >
                        <Dropdown.Toggle>
                          <span>{values.taxType || "Select"}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="VAT">VAT</Dropdown.Item>
                          <Dropdown.Item eventKey="EIN">EIN</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <ErrorMessage
                        name="taxType"
                        component="div"
                        className="error-msg"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="idNumber">ID Number</label>
                      <Field
                        type="text"
                        name="idNumber"
                        className="form-control"
                        placeholder="ID number"
                      />
                      <ErrorMessage
                        name="idNumber"
                        component="div"
                        className="error-msg"
                      />
                    </div>
                  </div>
                </div>
                <div className="save-button-parent">
                  <button
                    type="submit"
                    className="btn-style"
                    disabled={!dirty || isSubmitting}
                  >
                    {isSubmitting ? <Spinner size="sm" /> : "Save"}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
