import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import profileImg from "../assets/images/icons/avatar-icon.svg";
import proposalImg from "../assets/images/proposal-img.svg";
import countryIcon from "../assets/images/icons/map-icon.svg";
import dollarSign from "../assets/images/icons/dollar-sign.svg";
import regroupIcon from "../assets/images/icons/regroup-icon.svg";
import profileBagIcon from "../assets/images/icons/shopping-bag-icon.svg";
import message from "../assets/images/icons/sms-icon.svg";
import { CiHeart } from "react-icons/ci";
import { BsThreeDots } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getUserAsyncThunk } from "../redux/pagesSlices/userSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { getId, returnArray } from "../utils/utils";
import { Link } from "react-router-dom";
import ContractSettingModal from "./modals/ContractSettingModal";
import {
  getCreatorAsyncThunk,
  getMyFavoriteCreatorsAsyncThunk,
  toggleFavoriteCreatorAsyncThunk,
} from "../redux/pagesSlices/creatorSlice";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import {
  getJobApplicantsAsyncThunk,
  toggleApplicationFavoriteAsyncThunk,
  updateApplicationAsyncThunk,
} from "../redux/pagesSlices/applicationSlice";
import { RiSendPlane2Fill } from "react-icons/ri";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-toastify";

function ViewProfileDetailModal({
  viewProfileModalCloseHandler = () => {},
  viewProfileModal,
  application,
  creatorId,
  applicantsParams,
  creatorLoading,
}) {
  const dispatch = useDispatch();
  const { myFavoriteCreators } = useSelector((s) => s.creator);
  const { loadings } = useSelector((s) => s.application);
  const creator = application?.creatorId || application?.application?.creatorId;
  const applicationDetails = application.application;
  console.log("applied creator", applicationDetails);

  const [contractSettingModal, setContractSettingModal] = useState(false);
  const contractSettingModalOpenHandler = () => {
    setContractSettingModal(true);
  };
  const contractSettingModalCloseHandler = () => {
    setContractSettingModal(false);
  };

  const handleFireCreator = () => {
    dispatch(
      updateApplicationAsyncThunk({
        id: getId(applicationDetails),
        data: { isFired: true, isHired: false },
        callBack: () => {
          toast.success("Creator Fired Successfully!");
        },
      })
    );
  };

  useEffect(() => {
    if (creatorId) {
      dispatch(getCreatorAsyncThunk(creatorId));
    }
  }, [creatorId, dispatch]);

  useEffect(() => {
    if (!applicationDetails?.viewed) {
      dispatch(
        updateApplicationAsyncThunk({
          id: getId(application?.application),
          data: { viewed: true },
        })
      );
    }
  }, [applicationDetails]);

  const isExistInFavorite = myFavoriteCreators?.some(
    (item) => item.creatorId === creatorId
  );

  const appliedCreator = useSelector((s) => s.creator?.creator);
  const userLocation = application?.location;

  const formik = useFormik({
    initialValues: {
      brandNote: applicationDetails?.brandNote || "",
    },
    validationSchema: Yup.object({
      brandNote: Yup.string()
        .required("This field is required")
        .min(3, "Must be at least 3 characters")
        .max(150, "Must be at most 150 characters"),
    }),
    onSubmit: (values) => {
      dispatch(
        updateApplicationAsyncThunk({
          id: getId(application?.application),
          data: values,
          callBack: () => {
            toast.success("Brand Note Sent Successfully!");
            dispatch(
              getJobApplicantsAsyncThunk({
                id: application?.application?.jobId,
                params: { ...applicantsParams },
              })
            );
          },
        })
      );
    },
  });

  return (
    <div className="container">
      <Modal
        show={viewProfileModal}
        onHide={viewProfileModalCloseHandler}
        className="view-profile-short-info-modal"
        centered
      >
        <Modal.Header>
          <Button
            variant="close"
            onClick={viewProfileModalCloseHandler}
            aria-label="Close"
            style={{ marginLeft: 0 }}
            className="cancel-btn "
          ></Button>

          <Link
            className="m-0 blue"
            to={
              applicationDetails?.isHired
                ? `/dashboard/track-project/${getId(applicationDetails)}`
                : `/dashboard/creators/${getId(
                    application
                  )}?applicationId=${getId(application?.application)}`
            }
          >
            View full Profile
          </Link>
        </Modal.Header>

        <Modal.Body>
          <div className="top-profile-box">
            <div className="profile-info">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  {creatorLoading ? (
                    <Spinner animation="border" />
                  ) : (
                    <div className="profile-left">
                      {appliedCreator?.avatar ? (
                        <img
                          src={parseImagePathToURL(appliedCreator?.avatar)}
                          alt=""
                          className="profile-img"
                        />
                      ) : (
                        <img src={profileImg} alt="" className="profile-img" />
                      )}
                      <div className="profile-text">
                        <h5>
                          {appliedCreator?.name} {appliedCreator?.lastName}
                        </h5>
                        <p>@{appliedCreator?.userName}</p>
                        <div className="profile-icons">
                          <div className="profile-text-icon">
                            <img src={countryIcon} alt="" />
                            <p>{userLocation}</p>
                          </div>
                          <div className="profile-text-icon">
                            <img src={profileBagIcon} alt="" />
                            <p>
                              {appliedCreator?.activeJobs
                                ? appliedCreator?.activeJobs + " jobs"
                                : "No jobs"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="profile-btn">
                    {applicationDetails?.isHired ? (
                      <>
                        <button>
                          <Link
                            to={`/dashboard/messages?id=${application?.application?.creatorId}&jobId=${application?.application?.jobId?._id}`}
                            style={{ color: "white" }}
                          >
                            <img
                              src={message}
                              alt=""
                              style={{ color: "white" }}
                            />
                            Message Creator
                          </Link>
                        </button>
                        <div
                          className="heart-icon"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            dispatch(
                              toggleFavoriteCreatorAsyncThunk({
                                id: creatorId,
                                callBack: () => {
                                  dispatch(
                                    getMyFavoriteCreatorsAsyncThunk({
                                      pagination: false,
                                    })
                                  );
                                },
                              })
                            );
                          }}
                        >
                          {isExistInFavorite ? (
                            <FaHeart color={"#578BFC"} />
                          ) : (
                            <FaRegHeart color={"#578BFC"} />
                          )}
                        </div>
                        <div className="fire-dropdown">
                          <Dropdown className="fire-creator-dropdown">
                            <DropdownToggle>
                              <div className="three-dot-icon">
                                <BsThreeDots fontSize={20} />
                              </div>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={handleFireCreator}>
                                Fire Creator
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </>
                    ) : (
                      <button onClick={contractSettingModalOpenHandler}>
                        Hire creator
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="top-profile-bottom-text">
              <div className="profile-bottom-text">
                <p>Estimated ROI</p>
                <span>3.5X</span>
              </div>
              <div className="profile-bottom-text">
                <p>GMV (last 28 days)</p>
                <span>$500</span>
              </div>
              <div className="profile-bottom-text">
                <p>GMV per video</p>
                <span>$900</span>
              </div>
            </div>
          </div>
          <div className="profile-center-box">
            <div className="main-center-profile">
              <div className="center-text">
                <h1>Proposal details</h1>
                <h6>What makes you unique for this job?</h6>
                <p>{appliedCreator?.bio}</p>
              </div>
            </div>

            {applicationDetails?.isHired && (
              <div className="image-container">
                <strong className="dark semi_bold">Recent Works</strong>
                <div className="row mt-3">
                  <div className="col-md-4">
                    <div className="image-side-text-parent">
                      <img src={proposalImg} alt="png img" />
                      <div className="text-icon-parent">
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={regroupIcon} alt="Regrouplight icon" />
                            <span className="roi-text">ROI</span>
                          </div>
                          <span className="text-3x">3x</span>
                        </div>
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={dollarSign} alt="dollarSign" />
                            <span className="roi-text">GMV</span>
                          </div>
                          <span className="text-3x">$89</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="image-side-text-parent">
                      <img src={proposalImg} alt="png img" />
                      <div className="text-icon-parent">
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={regroupIcon} alt="Regrouplight icon" />
                            <span className="roi-text">ROI</span>
                          </div>
                          <span className="text-3x">3x</span>
                        </div>
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={dollarSign} alt="dollarSign" />
                            <span className="roi-text">GMV</span>
                          </div>
                          <span className="text-3x">$89</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="image-side-text-parent">
                      <img src={proposalImg} alt="png img" />
                      <div className="text-icon-parent">
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={regroupIcon} alt="Regrouplight icon" />
                            <span className="roi-text">ROI</span>
                          </div>
                          <span className="text-3x">3x</span>
                        </div>
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={dollarSign} alt="dollarSign" />
                            <span className="roi-text">GMV</span>
                          </div>
                          <span className="text-3x">$89</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bordered-box-text">
                  <div className="video-prices">
                    <h4>Live prices</h4>
                    <div class="price-option">
                      <div className="price-card-sec">
                        {returnArray(
                          application?.livePrices ??
                            application?.offeredLivePrice
                        ).length > 0 ? (
                          returnArray(
                            application?.livePrices ??
                              application?.offeredLivePrice
                          ).map((price, index) => (
                            <div class="price-card">
                              <span className="alpha-text">
                                {price?.count} Live/m
                              </span>
                              <span className="dollar-text">
                                ${price?.price}
                              </span>
                            </div>
                          ))
                        ) : (
                          <div class="price-card">
                            <span className="alpha-text">
                              Not available for this creator
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="price-card-sec"></div>
                    </div>
                  </div>
                  <div className="video-prices">
                    <h4>Video prices</h4>
                    <div class="price-option">
                      <div className="price-card-sec">
                        {returnArray(
                          application?.videoPrices ??
                            application?.offeredVideoPrice
                        ).length > 0 ? (
                          returnArray(
                            application?.videoPrices ??
                              application?.offeredVideoPrice
                          ).map((price, index) => (
                            <div class="price-card">
                              <span className="alpha-text">
                                {price.count} Videos/m
                              </span>
                              <span className="dollar-text">
                                ${price.price}
                              </span>
                            </div>
                          ))
                        ) : (
                          <div class="price-card">
                            <span className="alpha-text">
                              Not available for this creator
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!applicationDetails?.isHired && (
              <>
                <div className="profile-price-cards">
                  <strong className="dark semi_bold">Price Videos</strong>
                  <div className="row">
                    {returnArray(applicationDetails?.offeredVideoPrice).length >
                    0 ? (
                      returnArray(applicationDetails?.offeredVideoPrice).map(
                        (price, index) => (
                          <div className="col-lg-3 col-md-6">
                            <div className="price-cards">
                              <p>{price?.count} Videos per month</p>
                              <h1>${price?.price}</h1>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="col-lg-3 col-md-6">
                        <div className="price-cards">
                          <p>Not available for this creator</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="profile-price-cards live-cards">
                  <h6>Price Lives</h6>
                  <div className="row">
                    {returnArray(applicationDetails?.offeredLivePrice).length >
                    0 ? (
                      returnArray(applicationDetails?.offeredLivePrice).map(
                        (price, index) => (
                          <div className="col-lg-3 col-md-6">
                            <div className="price-cards">
                              <p>{price?.count} Live per month</p>
                              <h1>${price?.price}</h1>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="col-lg-3 col-md-6">
                        <div className="price-cards">
                          <p>Not available for this creator</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {!applicationDetails?.isHired && (
            <div className="main-center-profile">
              <div className="center-text">
                <h6>Send note to creator</h6>
                <form onSubmit={formik.handleSubmit}>
                  <textarea
                    type="text"
                    value={formik.values.brandNote}
                    name="brandNote"
                    rows={3}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`form-control ${
                      formik.touched.brandNote && formik.errors.brandNote
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Send a note to the creator if you have any issues with their proposal"
                  />
                  {formik.touched.brandNote && formik.errors.brandNote && (
                    <div className="invalid-feedback">
                      {formik.errors.brandNote}
                    </div>
                  )}
                  <Button
                    type="submit"
                    className="btn-style light-blue mt-2"
                    disabled={
                      loadings?.updateApplicationAsyncThunk || !formik.dirty
                    }
                  >
                    {loadings?.updateApplicationAsyncThunk ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <>
                        Send
                        <RiSendPlane2Fill />
                      </>
                    )}
                  </Button>
                </form>
              </div>
            </div>
          )}

          {applicationDetails?.isHired && (
            <div className="bottom-box">
              <h6>Brand note</h6>
              <p>
                {applicationDetails?.brandNote
                  ? applicationDetails?.brandNote
                  : "No brand note"}
              </p>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {contractSettingModal && (
        <ContractSettingModal
          creator={creator}
          viewProfileModalCloseHandler={viewProfileModalCloseHandler}
          applicationDetails={applicationDetails}
          contractSettingModal={contractSettingModal}
          contractSettingModalCloseHandler={contractSettingModalCloseHandler}
        />
      )}
    </div>
  );
}

export default ViewProfileDetailModal;
