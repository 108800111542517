import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
import { toast } from "react-toastify";
///////////////////////////////////////////////////

export const getJobTaskWithApplicationIdAsyncThunk = createAsyncThunk(
  "Contract/getJobTaskWithApplicationIdAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getJobTaskWithApplicationId(id);
    return response?.data;
  })
);

export const updateJobTaskWithIdAsyncThunk = createAsyncThunk(
  "Contract/updateJobTaskWithIdAsyncThunk",
  catchAsync(async ({ id, data, callback }) => {
    const response = await ApiRequests.updateJobTaskWithId(id, data);
    if (callback) callback();
    return response?.data;
  })
);

///////////////////////////////////////////////////

const initialState = {
  jobTask: null,
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const JobTaskSlice = createSlice({
  name: "jobTask",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getJobTaskWithApplicationIdAsyncThunk.pending,
        (state, action) => {
          state.jobTask = {};
        }
      )
      .addCase(
        getJobTaskWithApplicationIdAsyncThunk.fulfilled,
        (state, action) => {
          state.jobTask = action.payload;
        }
      )
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getJobTaskWithApplicationIdAsyncThunk,
            updateJobTaskWithIdAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default JobTaskSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  JobTaskSlice.actions;
