import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// Start Payment Slices
///////////////////////////////////////////////////

export const checkoutAsyncThunk = createAsyncThunk(
  "payment/checkoutAsyncThunk",
  catchAsync(async ({ data }) => {
    const response = await ApiRequests.checkout({ ...data });
    return response?.data;
  })
);

export const createPaymentAsyncThunk = createAsyncThunk(
  "payment/createPaymentAsyncThunk",
  catchAsync(async ({ data, callback }) => {
    const response = await ApiRequests.createPayment(data);
    if (response.status === 201) {
      toast.success("Payment created successfully!");
    }
    if (callback) callback();
    return response?.data;
  })
);

export const confirmPaymentAsyncThunk = createAsyncThunk(
  "payment/confirmPaymentAsyncThunk",
  catchAsync(async ({ id, callback }) => {
    const response = await ApiRequests.confirmPayment(id);
    if (response.status === 200) {
      toast.success("Payment confirmed and send to creator!");
    }
    if (callback) callback();
    return response?.data;
  })
);

export const disputePaymentAsyncThunk = createAsyncThunk(
  "payment/disputePaymentAsyncThunk",
  catchAsync(async ({ id, callback }) => {
    const response = await ApiRequests.disputePayment(id);
    if (response.status === 200) {
      toast.success("Payment disputed successfully!");
    }
    if (callback) callback();
    return response?.data;
  })
);

export const addPaymentMethodAsyncThunk = createAsyncThunk(
  "payment/addPaymentMethodAsyncThunk",
  catchAsync(async ({ data, callback }, { dispatch }) => {
    const response = await ApiRequests.addPaymentMethod(data);
    console.log("response", response);
    if (response.status == 201) {
      dispatch(getPaymentMethodsAsyncThunk({}));
    }
    if (callback) callback();
    return response?.data;
  })
);

export const getPaymentMethodsAsyncThunk = createAsyncThunk(
  "payment/getPaymentMethodsAsyncThunk",
  catchAsync(async ({ callback }) => {
    const response = await ApiRequests.getPaymentMethods();
    if (callback) callback();
    return response?.data;
  })
);

export const getBrandBillingHistoryAsyncThunk = createAsyncThunk(
  "payment/getBrandBillingHistoryAsyncThunk",
  catchAsync(async ({params},{ callback }) => {
    const response = await ApiRequests.getBrandBillingHistory(params);
    if (callback) callback();
    return response?.data;
  })
)

const initialState = {
  //news states
  story: null,
  assets: null,
  asset: null,
  subscriptions: null,
  analytics: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  billing : null,
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  paymentMethods: [],
  order: "asce",
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethodsAsyncThunk.fulfilled, (state, action) => {
        state.paymentMethods = action.payload;
      })
      .addCase(getBrandBillingHistoryAsyncThunk.fulfilled, (state, action) => {
        state.billing = action.payload
      })
      .addCase(getBrandBillingHistoryAsyncThunk.pending, (state, action) => {
        state.billing = null
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            checkoutAsyncThunk,
            addPaymentMethodAsyncThunk,
            getPaymentMethodsAsyncThunk,
            createPaymentAsyncThunk,
            confirmPaymentAsyncThunk,
            disputePaymentAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default paymentSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  paymentSlice.actions;
