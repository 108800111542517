import React from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

export default function RangeComponent({ defaultValue, min, max }) {
  const handleRangeChange = (values) => {
    // setRange(values);
    console.log("Selected range:", values);
  };
  return (
    <div className="range-slider-main-wrapper">
      <div className="title-wrapper">
        <strong className="dark semi_bold">$20</strong>
        <strong className="dark semi_bold">$800</strong>
      </div>
      <RangeSlider
        onInput={handleRangeChange}
        defaultValue={defaultValue ?? [100, 700]}
        min={min ?? 20}
        max={max ?? 800}
      />
    </div>
  );
}
