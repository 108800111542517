import React, { memo, useEffect, useState } from "react";

import regroupIcon from "../../assets/images/icons/regroup-icon.svg";
import dollarSign from "../../assets/images/icons/dollar-sign.svg";
import videoFileIcon from "../../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../../assets/images/icons/live-icon.svg";
import avatarIcon from "../../assets/images/icons/avatar-icon.svg";
import { HiArrowRight } from "react-icons/hi";
import { TbThumbDown } from "react-icons/tb";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { IoCheckmarkOutline } from "react-icons/io5";
import { getId, returnArray } from "../../utils/utils";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ViewProfileDetailModal from "../ViewProfileDetailModal";
import { Link } from "react-router-dom";
import inviteIcon from "../../assets/images/icons/invite-icon.svg";
import InviteCreatorModal from "../modals/InviteCreatorModal";
import {
  findCreatorsAsyncThunk,
  findCreatorsCountAsyncThunk,
  toggleFavoriteCreatorAsyncThunk,
} from "../../redux/pagesSlices/creatorSlice";
import {
  getGMVPerVideo,
  newGetGMV,
  newGetGMVPerVideo,
} from "../../utils/getStats";

const FindCreatorCardComponent = memo(({ item = {}, params }) => {
  const reviewLoading = useSelector(
    (s) => s.application?.loadings?.reviewApplicationAsyncThunk
  );
  console.log("itrmdsdfd", item);
  const [showModel, setShowModel] = useState(false);
  const [showInviteModel, setShowInviteModel] = useState(false);
  const [pricingView, setPricingView] = useState("video");
  const d = useDispatch();
  const pricingViewHandler = (view) => {
    setPricingView(view);
  };
  const creator = item || {};
  const application = item?.application || {};

  const handleShowInviteModal = () => {
    setShowInviteModel(true);
  };
  const handleCloseInviteModal = () => {
    setShowInviteModel(false);
  };

  return (
    <div className="col-md-6 col-lg-4 mb-4">
      <div className="creator-card-main-wrapper">
        <div className="creator-title-info-wrapper">
          <div className="main-wrapper">
            <div className="img-wrapper">
              <Link>
                <img
                  src={
                    creator.avatar
                      ? parseImagePathToURL(creator.avatar)
                      : avatarIcon
                  }
                  alt="brandImg"
                />
              </Link>
            </div>
            <div className="content-wrapper">
              <strong className="medium dark">
                <a href="#">{creator?.name}</a>
              </strong>
              <span className="small">
                @{creator?.userName || creator?.tikTokUserName}
              </span>
            </div>
          </div>
        </div>
        <div className="short-dec">
          <p>{creator?.bio}</p>
        </div>
        <div className="short-info-wrapper">
          <div className="text">
            <div className="title">
              <img src={regroupIcon} alt="regroupIcon" />
              <p className="small">Estimated ROI</p>
            </div>
            <strong className="dark small semi_bold">3.4X</strong>
          </div>
          <div className="text">
            <div className="title">
              <img src={dollarSign} alt="dollarSign" />
              <p className="small">
                GMV <span>(Last 28 days)</span>
              </p>
            </div>
            <strong className="dark small semi_bold">
              {newGetGMV(
                creator?.json?.creator_profile?.med_gmv_revenue?.value?.value,
                creator?.json?.creator_profile?.med_gmv_revenue_range?.value
              )}
            </strong>
          </div>
          <div className="text">
            <div className="title">
              <img src={dollarSign} alt="dollarSign" />
              <p className="small">GMV Per Video</p>
            </div>
            <strong className="dark small semi_bold">
              {newGetGMVPerVideo(
                creator?.json?.creator_profile?.med_gmv_revenue?.value?.value,
                creator?.json?.creator_profile?.med_gmv_revenue_range?.value,
                creator?.json?.creator_profile?.video_publish_cnt_30d?.value
              )}
            </strong>
          </div>
        </div>
        <div className="pricing-main-wrapper">
          <ul className="pricing-tabs-wrapper">
            <li className={`${pricingView === "video" ? "active" : ""}`}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  pricingViewHandler("video");
                }}
              >
                <img src={videoFileIcon} alt="videoFileIcon" /> Videos
              </a>
            </li>
            <li className={`${pricingView === "live" ? "active" : ""}`}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  pricingViewHandler("live");
                }}
              >
                <img src={liveIcon} alt="liveIcon" /> Live
              </a>
            </li>
          </ul>
          {pricingView === "video" ? (
            <div className="pricing-content-wrapper">
              {returnArray(creator?.videoPrices).length > 0 ? (
                returnArray(creator?.videoPrices).map((price, index) => (
                  <div className="text" key={index}>
                    <strong className="small medium-bold dark">
                      {price?.count} Videos/Month
                    </strong>
                    <strong className="semi_bold dark">${price?.price}</strong>
                  </div>
                ))
              ) : (
                <div className="text">
                  <strong className="small medium-bold dark">
                    Not available for this creator
                  </strong>
                </div>
              )}
            </div>
          ) : (
            <div className="pricing-content-wrapper">
              {returnArray(creator?.livePrices).length > 0 ? (
                returnArray(creator?.livePrices).map((price, index) => (
                  <div className="text" key={index}>
                    <strong className="small medium-bold dark">
                      {price?.count} Live/Month
                    </strong>
                    <strong className="semi_bold dark">${price?.price}</strong>
                  </div>
                ))
              ) : (
                <div className="text">
                  <strong className="small medium-bold dark">
                    Not available for this creator
                  </strong>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="btn-wrapper">
          <Button
            href="#"
            onClick={handleShowInviteModal}
            className="btn-style yellow auto-width"
          >
            {reviewLoading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              <>
                <img src={inviteIcon} alt="inviteIcon" /> Invite
              </>
            )}
          </Button>
          <Button
            href="#"
            className="btn-style light-blue"
            onClick={(e) => {
              e.preventDefault();
              setShowModel(true);
            }}
          >
            {" "}
            See Full Profile <HiArrowRight />{" "}
          </Button>
        </div>
        <Button
          onClick={() => {
            d(
              toggleFavoriteCreatorAsyncThunk({
                id: getId(item),
                callBack: () => {
                  d(findCreatorsAsyncThunk(params));
                  d(findCreatorsCountAsyncThunk({}));
                },
              })
            );
          }}
          className="book-mark-icon creator-card-bookmark"
        >
          {item?.favorite ? (
            <FaHeart color={"#578BFC"} />
          ) : (
            <FaRegHeart color={"#000"} />
          )}
        </Button>
      </div>

      {showInviteModel && (
        <InviteCreatorModal
          creator={creator}
          isOpen={showInviteModel}
          handleClose={handleCloseInviteModal}
        />
      )}
    </div>
  );
});

export default FindCreatorCardComponent;
