import { ErrorMessage, Field } from "formik";
import React from "react";

export default function JobDescriptionJobPostStep() {
  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper">
        <h3 className="step-title">Job description</h3>
      </div>
      <form action="#" className="form">
        <div className="row">
          <div className="col-md-12"></div>
          <div className="field-wrapper">
            <div className="form-group">
              <label htmlFor="title">
                What specific challenges or problems does your brand solve?
              </label>
              <Field
                as="textarea"
                className="form-control"
                placeholder="Example: Our sustainable water bottles help eliminate single-use plastic waste while keeping drinks at the perfect temperature for 24 hours. We're tackling both environmental waste and the inconvenience of lukewarm beverages."
                name="jobAttributes.painPoints"
              />

              <ErrorMessage
                component="span"
                className="error-msg"
                name="jobAttributes.painPoints"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">
                  What benefits does your product offer to customer?
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  placeholder="Example: Our water bottles save customers money by replacing disposable bottles, while the premium insulation technology keeps drinks cold for 24 hours or hot for 12 hours. Each bottle comes with a lifetime warranty for peace of mind."
                  name="jobAttributes.benefits"
                />
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="jobAttributes.benefits"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">
                  Describe the type of creator(s) you'd like to work with:
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  placeholder="Example: We're looking for fitness and outdoor lifestyle creators who are passionate about sustainability and active living. Ideal creators regularly share workout routines, hiking adventures, or daily lifestyle content with an engaged audience."
                  name="jobAttributes.creatorType"
                />
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="jobAttributes.creatorType"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">
                  How should creators promote your product?
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  placeholder="Example: We'd love to see creators naturally integrate our bottles into their daily lifestyle content, showing how they use them during workouts, hikes, or their daily routine. Authentic demonstrations of the bottle's features and sustainability benefits are encouraged."
                  name="jobAttributes.contentStrategy"
                />
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="jobAttributes.contentStrategy"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
