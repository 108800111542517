import { ErrorMessage, Field } from "formik";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Select from "react-select";
import { toTitleCase } from "../utils/methods";
import { gendersList } from "../utils/constant";

const types = ["video", "live"];
const ageRanges = ["18-24", "24-35", "35-55", "55+"];
const categories = [
  "Electronics",
  "Beauty & Health",
  "Home & Living",
  "Toys & Games",
  "Automotive",
  "Books & Media",
  "Groceries & Food",
  "Pet Supplies",
  "Office Supplies",
  "Tools & Hardware",
  "Jewellery & Watches",
  "Sports & Outdoors",
  "Baby & Kids",
  "Arts & Collectibles",
  // "Beauty & Care",
  // "Business & Finance",
  // "Fashion & Style",
  // "Food & Drinks",
  // "Gaming & Tech",
  // "Health & Wellness",
  // "Home & Garden",
  // "Kids & Parenting",
  // "Lifestyle",
  // "Outdoors & Nature",
  // "Pets",
  // "Sport & Fitness",
  // "Travel",
];

export default function BasicInfoJobPostStep({
  values,
  setFieldValue,
  setFieldTouched,
}) {
  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper">
        <h3 className="step-title">Basic Information</h3>
      </div>
      <form action="#" className="form">
        <div className="row">
          <div className="col-md-12"></div>
          <div className="field-wrapper">
            <div className="form-group">
              <label htmlFor="title">Job title</label>
              <Field
                type="text"
                className="form-control"
                placeholder="e.g Looking for fitness product influencers."
                name="jobTitle"
              />
              <ErrorMessage
                component="span"
                className="error-msg"
                name="jobTitle"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">Seller Shop code</label>
                <Field
                  type="text"
                  className="form-control"
                  placeholder="TikTok shop code"
                  name="sellerShopCode"
                />
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="sellerShopCode"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="types">Type of Content</label>
                <Select
                  styles={{ backgroundColor: "orange" }}
                  id="types"
                  name="types"
                  isMulti
                  className="form-select shadow-none select-package"
                  options={types.map((type) => ({ label: type, value: type }))}
                  value={values.types.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setFieldValue("types", selectedValues);
                  }}
                  onBlur={() => {
                    setFieldValue("types", values.types); // Ensure Formik registers the field as touched
                    setFieldTouched("types", true); // Mark the field as touched for validation
                  }}
                  classNamePrefix="react-select"
                />
              </div>
              <ErrorMessage
                component="span"
                className="error-msg"
                name="types"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">Age range</label>
                <Select
                  styles={{ backgroundColor: "orange" }}
                  id="ageRange"
                  name="ageRange"
                  isMulti
                  className="form-select shadow-none select-package"
                  options={ageRanges.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  value={values.ageRange.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setFieldValue("ageRange", selectedValues);
                  }}
                  onBlur={() => {
                    setFieldValue("ageRange", values.ageRange); // Ensure Formik registers the field as touched
                    setFieldTouched("ageRange", true); // Mark the field as touched for validation
                  }}
                  classNamePrefix="react-select"
                />
              </div>
              <ErrorMessage
                component="span"
                className="error-msg"
                name="ageRange"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="gender">Gender</label>
                <Select
                  styles={{ backgroundColor: "orange" }}
                  id="gender"
                  name="gender"
                  isMulti
                  className="form-select shadow-none select-package"
                  options={gendersList?.map((type) => ({
                    label: toTitleCase(type),
                    value: type,
                  }))}
                  value={values.gender.map((type) => ({
                    label: toTitleCase(type),
                    value: type,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setFieldValue("gender", selectedValues);
                  }}
                  onBlur={() => {
                    setFieldValue("gender", values.gender); // Ensure Formik registers the field as touched
                    setFieldTouched("gender", true); // Mark the field as touched for validation
                  }}
                  classNamePrefix="react-select"
                />
                {/* <DropdownButton
                  id="dropdown-button-drop-down"
                  drop={"down"}
                  variant="secondary"
                  className="custom-dropdown"
                  title={values.gender ? values.gender : "Select"}
                >
                  <Dropdown.Item
                    onClick={() => setFieldValue("gender", "male")}
                    value="male"
                  >
                    Male
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setFieldValue("gender", "female")}
                    value="female"
                  >
                    Female
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setFieldValue("gender", "other")}
                    value="other"
                  >
                    Other
                  </Dropdown.Item>
                </DropdownButton> */}
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="gender"
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">Categories</label>
                <Select
                  styles={{ backgroundColor: "orange" }}
                  id="categories"
                  name="categories"
                  isMulti
                  className="form-select shadow-none select-package"
                  options={categories.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  value={values.categories.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions
                      ? selectedOptions.map((option) => option.value)
                      : [];
                    setFieldValue("categories", selectedValues);
                  }}
                  onBlur={() => {
                    setFieldValue("categories", values.categories); // Ensure Formik registers the field as touched
                    setFieldTouched("categories", true); // Mark the field as touched for validation
                  }}
                  classNamePrefix="react-select"
                />
              </div>
              <ErrorMessage
                component="span"
                className="error-msg"
                name="categories"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">Add product ID</label>
                <Field
                  type="text"
                  className="form-control"
                  placeholder="Enter product ID"
                  name="product_id"
                />
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="product_id"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">Product Link</label>
                <Field
                  type="text"
                  className="form-control"
                  placeholder="Enter product link"
                  name="product_link"
                />
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="product_link"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">Tik Tok Shop link</label>
                <Field
                  type="text"
                  className="form-control"
                  placeholder="TikTok Shop link"
                  name="tiktokShopUrl"
                />
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="tiktokShopUrl"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
