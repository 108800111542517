import React from "react";
import { Link } from "react-router-dom";

export default function LoginAsBrandBottomBar() {
  return (
    <div className="login-as-brand-bottom-bar">
      <p className="medium">
        Log in as a creator? Click{" "}
        <Link
          to={process.env.REACT_APP_CREATOR_BASE_URL + "sign-in"}
          className="pink bold underline"
        >
          here
        </Link>
      </p>
    </div>
  );
}
