import React, { useEffect, useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import SearchIcon from "../assets/images/icons/search-icon-wrapper.svg";
import filterIcon from "../assets/images/icons/filter-icon.svg";
import listIcon from "../assets/images/icons/list-icon.svg";
import gridIcon from "../assets/images/icons/grid-icon.svg";
import { useSelector } from "react-redux";

const filterLabels = {
  "createdAt:desc": "Newest First",
  "createdAt:asc": "Oldest First",
  "jobTitle:asc": "A-Z",
  "jobTitle:desc": "Z-A",
};

export default function TabsAndFilterBar({
  jobsViewHandler,
  jobsView,
  statusTabs,
  setStatusTabs,
  handleSearchChange = () => {},
  search = "",
  sortBy,
  setSortBy,
  exLargeContainer,
  largeContainer,
  status,
}) {
  const { jobsCount } = useSelector((s) => s.job);

  const tabs =
    status === "approved"
      ? [
          {
            key: "approved",
            label: "In Progress",
            count: jobsCount?.approved || 0,
          },
          { key: "pending", label: "Pending", count: jobsCount?.pending || 0 },
        ]
      : status === "closed"
      ? [
          { key: "flagged", label: "Flagged", count: jobsCount?.flagged || 0 },
          { key: "closed", label: "Closed", count: 0 },
        ]
      : [];

  const statusTabsHandler = (tab) => {
    setStatusTabs(tab);
  };

  useEffect(() => {
    if (status === "approved") {
      setStatusTabs("approved"); // Default tab for activeJobs
    } else if (status === "closed") {
      setStatusTabs("flagged"); // Default tab for inActiveJobs
    }
    else if (status === "draft"){
      setStatusTabs("draft");
    }
    else if (status === "completed"){
      setStatusTabs("completed");
    }
  }, [status, setStatusTabs]);

  return (
    <div className="tabs-and-filter-bar-main-wrapper">
      <div
        className={`container ${
          exLargeContainer ? "ex-large" : largeContainer ? "large" : ""
        }`}
      >
        <div className="row">
          <div className="col-md-7 align-self-center">
            {tabs.length > 0 && ( // Only render tabs if there are any
              <div className="nav-tabs-main-wrapper">
                <ul className="tabs-wrapper">
                  {tabs.map((tab) => (
                    <li
                      key={tab.key}
                      className={`${statusTabs === tab.key ? "active" : ""}`}
                    >
                      <a href="#" onClick={() => statusTabsHandler(tab.key)}>
                        {tab.label} ({tab.count})
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="col-md-5 align-self-center">
            <div className="right-menu-wrapper">
              <div className="search-bar-wrapper">
                <img
                  src={SearchIcon}
                  alt="SearchIcon"
                  className="search-icon"
                />
                <input
                  onChange={handleSearchChange}
                  value={search}
                  type="text"
                  className="form-control"
                  placeholder="Search Jobs"
                />
              </div>
              <div className="filter-and-view-wrapper">
                <div className="sort-by-wrapper">
                  <strong>Sort by: </strong>
                  <DropdownButton
                    onSelect={setSortBy}
                    id={`dropdown-button-drop-down`}
                    drop={"down"}
                    variant="secondary"
                    title={filterLabels[sortBy]}
                  >
                    <Dropdown.Item eventKey="createdAt:desc">
                      Newest First
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="createdAt:asc">
                      {" "}
                      Oldest First
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="jobTitle:asc">A-Z</Dropdown.Item>
                    <Dropdown.Item eventKey="jobTitle:desc">Z-A</Dropdown.Item>
                  </DropdownButton>
                  {/* <img src={filterIcon} alt="filterIcon" className="filter-icon" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
