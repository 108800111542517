import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { SlHandbag } from "react-icons/sl";
import { LuGlobe } from "react-icons/lu";
import { PiLinkSimpleBold } from "react-icons/pi";

import leftLongIcon from "../assets/images/icons/left-long-arrow.svg";
import mapIcon from "../assets/images/icons/map-icon.svg";
import jobImg from "../assets/images/icons/job-image.svg";
import proposalImg from "../assets/images/proposal-img.svg";
import videoMediaImg from "../assets/images/video-media-img.svg";
import videoIcon from "../assets/images/icons/video-file-wrapper.svg";
import instagramIcon from "../assets/images/icons/instagram-colored-icon.svg";
import facebookIcon from "../assets/images/icons/facebook-colored-icon.svg.svg";
import tiktokIcon from "../assets/images/icons/tiktok-colored-icon.svg.svg";
import xIcon from "../assets/images/icons/x-colored-icon.svg.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import LiveIcon from "../assets/images/icons/live-icon.svg";
import NoLinksComponent from "./NoLinksComponent";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationAsyncThunk } from "../redux/pagesSlices/applicationSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { getId, returnArray } from "../utils/utils";
import LoaderComponent from "./LoaderComponent";
import { MdVerifiedUser } from "react-icons/md";
import { dateFormat } from "../utils/format";
import moment from "moment";
import { getCreatorAsyncThunk } from "../redux/pagesSlices/creatorSlice";
import dollarSign from "../assets/images/icons/dollar-sign.svg";
import regroupIcon from "../assets/images/icons/regroup-icon.svg";
import { gendersList } from "../utils/constant";
import { toTitleCase } from "../utils/methods";

export default function JobDetailSectionForCreator({
  jobId,
  activeJobsTab,
  livePrice,
  videoPrice,
  creatorId,
  loading,
  creatorProgress,
}) {
  // console.log("Data with job id", jobId);

  const jobBenefits = jobId?.jobAttributes?.benefits;
  const jobPainPoins = jobId?.jobAttributes?.painPoints;
  const jobContentStrategy = jobId?.jobAttributes?.contentStrategy;

  const [viewMorePainPoints, setViewMorePainPoints] = useState(false);
  const [viewMoreGuidance, setViewMoreGuidance] = useState(false);
  const [viewMoreBenefits, setViewMoreBenefits] = useState(false);

  const videoRefs = useRef([]);
  const [playingIndex, setPlayingIndex] = useState(null); // Track which video is playing

  const jobImages = returnArray(jobId?.image);
  const jobVideoExamples = returnArray(jobId?.videoUrl);
  const livePriceArray = returnArray(livePrice);
  const videoPriceArray = returnArray(videoPrice);

  const videoUrls = jobId?.videoUrl
    ? jobId?.videoUrl.split(",").map((url) => url.trim())
    : [];
  // console.log("Creator id", creatorId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (creatorId) {
      if (typeof creatorId !== "string") {
        creatorId = getId(creatorId);
      }
      dispatch(getCreatorAsyncThunk(creatorId));
    }
  }, [creatorId]);

  const hiredCreator = useSelector((state) => state.creator?.creator);

  // console.log("hiredCreator", hiredCreator);

  // const videoPrices = returnArray(proposalDetails?.offeredVideoPrice);
  // const livePrices = returnArray(proposalDetails?.offeredLivePrice);
  // const brand = proposalDetails?.brandId;
  // const job = proposalDetails?.jobId ?? {};
  // console.log("Application Details", proposalDetails);
  // const brandWebsite = proposalDetails?.brandId?.website;

  // const videoArray = job?.videoUrl?.split(",").map((url) => url.trim());

  // const brandName = proposalDetails?.brandId?.name;
  const numberOfVideos = returnArray(hiredCreator?.videoPrice).reduce(
    (acc, item) => acc + item.count,
    0
  );
  const numberOfLives = returnArray(hiredCreator?.livePrice).reduce(
    (acc, item) => acc + item.count,
    0
  );
  const [showPlayButton, setShowPlayButton] = useState(
    Array(videoUrls.length).fill(true)
  );

  const handlePlay = (index) => {
    // Pause all videos except the current one
    videoUrls.forEach((_, idx) => {
      const videoElement = document.getElementById(`video-${idx}`);
      if (videoElement && idx !== index) {
        videoElement.pause();
      }
    });

    // Hide play button for the current video
    setShowPlayButton((prev) =>
      prev.map((_, idx) => (idx === index ? false : true))
    );
  };

  const handlePause = (index) => {
    // Show play button for the current video
    setShowPlayButton((prev) =>
      prev.map((_, idx) => (idx === index ? true : prev[idx]))
    );
  };

  const jobTypes = returnArray(jobId?.types);
  const jobCategories = returnArray(jobId?.categories);
  const creatorVideoPrices = returnArray(hiredCreator?.videoPrices);
  const creatorLivePrices = returnArray(hiredCreator?.livePrices);
  const creatoSampleVideos = returnArray(hiredCreator?.sampleVideos);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="project-detail-section">
      <div className="container ">
        {activeJobsTab === "about job" && (
          <div className="row">
            <div className="col-md-8">
              <div className="about-project-detal-wrapper">
                <div className="about-job-content-wrapper">
                  <h3>{jobId?.jobTitle} </h3>
                  <div className="project-deliverables-main-wrapper">
                    <strong className="dark medium mb-3 d-block">
                      Project deliverables
                    </strong>
                    <div className="main-wrapper">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="text-wrapper">
                            <span>Videos created</span>
                            <strong className="large d-block semi_bold">
                              {creatorProgress?.completedVideoCount}/
                              {creatorProgress?.totalVideoCount}
                            </strong>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="text-wrapper">
                            <span>Live created</span>
                            <strong className="large d-block semi_bold">
                              {creatorProgress?.completedLiveCount}/
                              {creatorProgress?.totalLiveCount}
                            </strong>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="text-wrapper">
                            <span>Completion </span>
                            <strong className="large d-block semi_bold">
                              {dateFormat(creatorProgress?.ongoingTaskEndTime)}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-wrapper">
                    <strong className="medium">Job description</strong>
                    <p>{jobId?.jobAttributes?.creatorType}</p>
                  </div>
                  <div className="text-wrapper">
                    <strong className="medium"> Pain points</strong>
                    <p>
                      {jobPainPoins?.length > 200
                        ? viewMorePainPoints === true
                          ? jobPainPoins
                          : jobPainPoins?.slice(0, 200) + "..."
                        : jobPainPoins}
                      {jobPainPoins}
                    </p>

                    {jobPainPoins?.length > 200 && !viewMorePainPoints && (
                      <button
                        onClick={() => setViewMorePainPoints(true)}
                        className="btn btn-link text-decoration-none blue"
                      >
                        View more <IoIosArrowDown />
                      </button>
                    )}
                  </div>
                  <div className="text-wrapper">
                    <strong className="medium">
                      Content Strategy Guidance
                    </strong>
                    <p>
                      {jobContentStrategy?.length > 200
                        ? viewMoreGuidance === true
                          ? jobContentStrategy
                          : jobContentStrategy?.slice(0, 200) + "..."
                        : jobContentStrategy}
                      {jobContentStrategy}
                    </p>

                    {jobContentStrategy?.length > 200 && !viewMoreGuidance && (
                      <button
                        onClick={() => setViewMoreGuidance(true)}
                        className="btn btn-link text-decoration-none blue"
                      >
                        View more <IoIosArrowDown />
                      </button>
                    )}
                  </div>
                  <div className="text-wrapper">
                    <strong className="medium">Job benefits</strong>
                    <p>
                      {jobBenefits?.length > 200
                        ? viewMoreBenefits === true
                          ? jobBenefits
                          : jobBenefits?.slice(0, 200) + "..."
                        : jobBenefits}
                      {jobBenefits}
                    </p>

                    {jobBenefits?.length > 200 && !viewMoreBenefits && (
                      <button
                        onClick={() => setViewMoreBenefits(true)}
                        className="btn btn-link text-decoration-none blue"
                      >
                        View more <IoIosArrowDown />
                      </button>
                    )}
                  </div>
                </div>
                <div className="media-and-resources-wrapper">
                  <div className="media-wrapper">
                    <strong className="bold dark">Photos</strong>
                    <ul className="images-media-wrapper">
                      {jobImages?.map((item, index) => (
                        <li key={index}>
                          <img
                            src={parseImagePathToURL(item)}
                            alt="proposalImg"
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="media-wrapper">
                    <strong className="bold dark">Videos</strong>
                    <ul className="videos-media-wrapper">
                      {videoUrls?.length > 0 &&
                        videoUrls?.map((url, index) => (
                          <li key={index} style={{ position: "relative" }}>
                            <video
                              id={`video-${index}`}
                              controls
                              width="600"
                              poster={videoMediaImg}
                              onPlay={() => handlePlay(index)}
                              onPause={() => handlePause(index)}
                            >
                              <source src={url} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                            {showPlayButton[index] && (
                              <div
                                className="icon-wrapper"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  zIndex: 10,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const videoElement = document.getElementById(
                                    `video-${index}`
                                  );
                                  if (videoElement) videoElement.play();
                                }}
                              >
                                <FaPlay />
                              </div>
                            )}
                          </li>

                          // <li key={index}>
                          //   <video
                          //     ref={(el) => (videoRefs.current[index] = el)} // Set ref for each video
                          //     controls
                          //     width="600"
                          //     poster={videoMediaImg}
                          //     onPlay={() => setPlayingIndex(index)} // Set index when video starts playing
                          //     onPause={() => setPlayingIndex(null)} // Reset index when video is paused
                          //   >
                          //     <source src={video} type="video/mp4" />
                          //     Your browser does not support the video tag.
                          //   </video>
                          //   {playingIndex !== index && (
                          //     <div
                          //       className="icon-wrapper"
                          //       onClick={() => handlePlay(index)}
                          //     >
                          //       <FaPlay />
                          //     </div>
                          //   )}
                          // </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="summary-main-wrapper">
                <strong className="large dark">Job details</strong>
                <div className="top-bar-wrapper">
                  <div className="text">
                    <strong>Job posted</strong>
                    <p className="dark_text semi_bold">
                      {dateFormat(jobId?.createdAt)}{" "}
                      <span className="small gray">
                        {" "}
                        ({moment(jobId?.createdAt).fromNow()})
                      </span>
                    </p>
                  </div>
                  <div className="text">
                    <strong>Content type</strong>
                    <ul className="proposal-type-list">
                      {jobTypes?.map((type, index) => (
                        <li
                          key={index}
                          className={`${
                            type == "video" ? "light-gray-bg" : "light-pink-bg"
                          }`}
                        >
                          <img
                            src={type === "video" ? videoIcon : LiveIcon}
                            alt="Icon"
                          />
                          {type}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="text">
                    <strong>Categories</strong>
                    <ul className="categories-list">
                      {jobCategories?.map((category, index) => (
                        <li key={index}>{category}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="text">
                    <strong>Preferred ages</strong>
                    <p className="light-bg">
                      {returnArray(jobId?.ageRange).join(", ")}
                    </p>
                  </div>
                  <div className="text">
                    <strong>Preferred gender</strong>
                    <ul className="categories-list">
                      {gendersList?.every((gender) =>
                        returnArray(jobId?.gender)?.includes(gender)
                      ) ? (
                        <li>All</li>
                      ) : (
                        <>
                          {returnArray(jobId?.gender).map((gender, index) => (
                            <li key={index}>{toTitleCase(gender)}</li>
                          ))}
                        </>
                      )}
                    </ul>
                  </div>
                  {/* <div className="text">
                    <strong>Location</strong>
                    <p className="dark_text medium semi_bold">
                      {jobId?.location}
                    </p>
                  </div> */}
                  <div className="text">
                    <strong>Language</strong>
                    <p className="dark_text medium semi_bold">English</p>
                  </div>
                </div>
                <strong className="large dark">Hired creator</strong>
                <div className="top-bar-wrapper">
                  <div className="main-creator-profile-info-wrapper">
                    <div className="img-wrapper">
                      {hiredCreator?.avatar ? (
                        <img
                          src={parseImagePathToURL(hiredCreator?.avatar)}
                          alt="avatarIcon"
                        />
                      ) : (
                        <img src={avatarIcon} alt="avatarIcon" />
                      )}
                    </div>
                    <div className="content-wrapper">
                      <strong className="name dark medium d-block">
                        {hiredCreator?.name}
                      </strong>
                      <spsan className="username">
                        @{hiredCreator?.userName}
                      </spsan>
                    </div>
                  </div>
                  <div className="creator-pricings-main-wrapper">
                    <div className="video-pricing">
                      <strong className="dark semi_bold d-block">
                        Video prices
                      </strong>
                      <ul className="price-listing">
                        {creatorVideoPrices?.map((videoPrice, index) => (
                          <li key={index}>
                            {videoPrice?.durationCount} Videos/
                            {videoPrice?.duration === "monthly" ? "m" : ""}{" "}
                            <span>${videoPrice?.price}</span>
                          </li>
                        ))}
                        {/* <li>
                          {videoPrice?.durationCount} Videos/
                          {videoPrice?.duration === "monthly" ? "m" : ""}{" "}
                          <span>${videoPrice?.price}</span>
                        </li> */}
                        {/* <li>
                          2 Videos/m <span>$200</span>
                        </li>
                        <li>
                          2 Videos/m <span>$200</span>
                        </li>
                        <li>
                          2 Videos/m <span>$200</span>
                        </li>
                        <li>
                          2 Videos/m <span>$200</span>
                        </li> */}
                      </ul>
                    </div>
                    <div className="live-pricing">
                      <strong className="dark semi_bold d-block">
                        Live prices
                      </strong>
                      <ul className="price-listing">
                        {creatorLivePrices?.map((livePrice, index) => (
                          <li key={index}>
                            {livePrice?.durationCount} Videos/
                            {livePrice?.duration === "weekly" ? "w" : ""}{" "}
                            <span>${livePrice?.price}</span>
                          </li>
                        ))}
                        <li>
                          {livePrice?.durationCount} Videos/
                          {livePrice?.duration === "weekly" ? "w" : ""}{" "}
                          <span>${livePrice?.price}</span>
                        </li>
                        {/* <li>
                          2 Videos/m <span>$200</span>
                        </li>
                        <li>
                          2 Videos/m <span>$200</span>
                        </li>
                        <li>
                          2 Videos/m <span>$200</span>
                        </li>
                        <li>
                          2 Videos/m <span>$200</span>
                        </li>
                        <li>
                          2 Videos/m <span>$200</span>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeJobsTab === "content created" && (
          <div className="row">
            <div className="col-md-8 creator-detail-content">
              <strong className="job-title-creator">{jobId?.jobTitle}</strong>
              <div className="about-project-detal-wrapper">
                <div className="image-container">
                  <strong className="dark semi_bold">Videos Created</strong>
                  <div className="row mt-3">
                    {creatoSampleVideos?.length > 0 ? (
                      creatoSampleVideos?.map((video, index) => (
                        <div className="col-md-4">
                          <div className="image-side-text-parent">
                            <ul className="videos-media-wrapper">
                              <li key={index}>
                                <video
                                  ref={(el) => (videoRefs.current[index] = el)} // Set ref for each video
                                  controls
                                  width="600"
                                  poster={videoMediaImg}
                                  onPlay={() => setPlayingIndex(index)} // Set index when video starts playing
                                  onPause={() => setPlayingIndex(null)} // Reset index when video is paused
                                >
                                  <source src={video} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                                {playingIndex !== index && (
                                  <div
                                    className="icon-wrapper"
                                    onClick={() => handlePlay(index)}
                                  >
                                    <FaPlay />
                                  </div>
                                )}
                              </li>
                            </ul>
                            <div className="text-icon-parent">
                              <div className="text-icon">
                                <div className="inner-text-icon">
                                  <img
                                    src={regroupIcon}
                                    alt="Regrouplight icon"
                                  />
                                  <span className="roi-text">ROI</span>
                                </div>
                                <span className="text-3x">3x</span>
                              </div>
                              <div className="text-icon">
                                <div className="inner-text-icon">
                                  <img src={dollarSign} alt="dollarSign" />
                                  <span className="roi-text">GMV</span>
                                </div>
                                <span className="text-3x">$89</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <strong>No Content Uploaded Yet!</strong>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="summary-main-wrapper">
                <strong className="large dark">Job details</strong>
                <div className="top-bar-wrapper">
                  <div className="text">
                    <strong>Job posted</strong>
                    <p className="dark_text semi_bold">
                      {dateFormat(jobId?.createdAt)}{" "}
                      <span className="small gray">
                        {" "}
                        ({moment(jobId?.createdAt).fromNow()})
                      </span>
                    </p>
                  </div>
                  <div className="text">
                    <strong>Content type</strong>
                    <ul className="proposal-type-list">
                      {jobTypes?.map((type, index) => (
                        <li
                          key={index}
                          className={`${
                            type == "video" ? "light-gray-bg" : "light-pink-bg"
                          }`}
                        >
                          <img
                            src={type === "video" ? videoIcon : LiveIcon}
                            alt="Icon"
                          />
                          {type}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="text">
                    <strong>Categories</strong>
                    <ul className="categories-list">
                      {jobCategories?.map((category, index) => (
                        <li key={index}>{category}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="text">
                    <strong>Preferred ages</strong>
                    <p className="light-bg">
                      {returnArray(jobId?.ageRange).join(", ")}
                    </p>
                  </div>
                  <div className="text">
                    <strong>Preferred gender</strong>
                    <ul className="categories-list">
                      {gendersList?.every((gender) =>
                        returnArray(jobId?.gender)?.includes(gender)
                      ) ? (
                        <li>All</li>
                      ) : (
                        <>
                          {returnArray(jobId?.gender).map((gender, index) => (
                            <li key={index}>{toTitleCase(gender)}</li>
                          ))}
                        </>
                      )}
                    </ul>
                  </div>
                  <div className="text">
                    <strong>Location</strong>
                    <p className="dark_text medium semi_bold">
                      {jobId?.location}
                    </p>
                  </div>
                  <div className="text">
                    <strong>Language</strong>
                    <p className="dark_text medium semi_bold">English</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
