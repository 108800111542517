import React, { useEffect, useLayoutEffect } from "react";
import { isImageURL, parseImagePathToURL } from "../../utils/globalFunctions";
import SentImageComponent from "../Chat/SentImageComponent";
import FileSentComponent from "../Chat/FileSentComponent";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDots } from "react-icons/bs";
export default function SentMessage({ message, handleDeleteMessage }) {
  const messageDate = new Date(message?.createdAt);
  const hours = messageDate.getHours();
  const minutes = messageDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
  function decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  useLayoutEffect(() => {
    // Function to handle click events
    const handleClick = (event) => {
      console.log("🚀 ~ handleClick ~ event:", event.target.dataset.action)
      if (event.target.dataset.action === "cancelOffer") {
        cancelOffer();
      }
      if (event.target.dataset.action === "deleteMessage") {
        handleDeleteMessage(message);
      }
    };

    // Add click event listener to the parent container
    const container = document.getElementById("dynamic-container");
    container?.addEventListener("click", handleClick);

    // Cleanup the event listener
    return () => {
      container?.removeEventListener("click", handleClick);
    };
  }, []);

  const cancelOffer = () => {
    console.log("Cancel offer button clicked!");
    // Add your cancel offer logic here
  };
  return (
    <div className="message-wrapper sent-msg">
      {message?.file && (
        <>
          <div className="uploaded-items-list">
            {isImageURL(message?.file) && (
              <SentImageComponent
                formateTime={formattedTime}
                imageSrc={parseImagePathToURL(message?.file)}
              />
            )}
            {message?.content !== "voice message" &&
              !isImageURL(message?.file) && (
                <FileSentComponent
                  file={message?.file}
                  content={message?.content}Contract settings

                  time={formattedTime}
                />
              )}
          </div>
        </>
      )}
      {(!message?.file || message?.content === "voice message") && (
        <>
          <div className="sent-voice-message">
            {message?.content === "voice message" ? (
              <audio controls>
                <sourceContract settings

                                src={parseImagePathToURL(message.file)}
                  type="audio/wav"
                />
                Your browser does not support the audio element.
              </audio>
            ) : (
                <div className='message'>
              <p
              id={'dynamic-container'}
                dangerouslySetInnerHTML={{ __html: decodeHTML(message?.contentBrand||message?.content) }}
              ></p>
                </div>
            )}
            {/* <span className="tile">{formattedTime}</span> */}
            <DropdownButton
              id={`dropdown-button-drop-down`}
              drop={"down"}
              variant="secondary"
              title={<BsThreeDots />}
              className="custom-dropdown three-dots-line action-dropdown"
            >
              {/* <Dropdown.Item eventKey="1">Report chat </Dropdown.Item> */}
              <Dropdown.Item eventKey="2" onClick={handleDeleteMessage}>
                Delete chat
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </>

      )}
    </div>
  );
}
