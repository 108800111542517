import React from "react";

import { IoMdAdd } from "react-icons/io";

import listIcon from "../assets/images/icons/list-icon.svg";
import gridIcon from "../assets/images/icons/grid-icon.svg";
import { Link } from "react-router-dom";

const filterLabels = {
  "createdAt:desc": "Newest First",
  "createdAt:asc": "Oldest First",
  "jobTitle:asc": "A-Z",
  "jobTitle:desc": "Z-A",
};

export default function MyJobsPageHeader({
  title = "My Jobs",
  showBtn,
  jobsViewHandler,
  jobsView,
  handleSearchChange = () => { },
  search = "",
  sortBy,
  setSortBy,
  exLargeContainer,
  largeContainer,
  status
}) {
  return (
    <div className="inner-page-header">
      <div
        className={`container ${exLargeContainer ? "ex-large" : largeContainer ? "large" : ""
          }`}
      >
        <div className="row">
          <div className="col-md-4 align-self-center">
            <div className="inner-page-title-wrapper">
              <h2>{title}</h2>
            </div>
          </div>
          {showBtn && (
            <div className="col-md-8 align-self-center">
              <div className="right-menu-wrapper">
                <div className="post-job-btn-wrapper">
                  <Link to="/job-post" className="btn-style large_font">
                    <IoMdAdd /> Post a Job
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
