import React, { useEffect, useState } from "react";
import pointericon from "../assets/images/icons/pointer-icon.svg";
import SampleProductImage from "./SampleProductImage";
import { parseImagePathToURL } from "../utils/globalFunctions";

export default function ReviewProductImagesJobPostStep({
  values,
  setFieldValue,
  setActiveStep,
}) {
  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    const processImages = async () => {
      // Process each image in values.image
      const urls = await Promise.all(
        values?.image?.map((file) => {
          // If the item is a string (old image URL), return it directly
          if (typeof file === "string") {
            return parseImagePathToURL(file);
          }

          // If the item is a File object, use FileReader to generate a preview URL
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(file);
          });
        }) || []
      );

      setImageURLs(urls);
    };

    processImages();
  }, [values.image]);

  const handleDelete = (index) => {
    const updatedImages = [...values.image];
    updatedImages.splice(index, 1);
    setFieldValue("image", updatedImages);

    const updatedImageURLs = [...imageURLs];
    updatedImageURLs.splice(index, 1);
    setImageURLs(updatedImageURLs);
  };

  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper has-icon mb-2">
        <h3 className="step-title">Product Images</h3>
        <button className="edit-icon-wrapper" onClick={() => setActiveStep(1)}>
          <img src={pointericon} alt="Edit" />
        </button>
      </div>
      <div className="sample-product-images-main">
        <div className="main-wrapper">
          <SampleProductImage
            handleDelete={handleDelete}
            sampleProductImage={imageURLs}
          />
        </div>
      </div>
    </div>
  );
}
