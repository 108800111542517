export const gendersList = ["male", "female", "other"];

export const membershipPlans = [
  {
    planName: "Basic",
    price: 97,
    frequency: "per month",
    recommended: false,
    features: [
      "Hire, mhanage & analyze up to 3 creators.",
      "10 invites per month",
    ],
  },
  {
    planName: "Pro",
    price: 197,
    frequency: "per month",
    recommended: true,
    features: [
      "Hire, manage & analyze up to 10 creators",
      "50 invites per month",
    ],
  },
  {
    planName: "Enterprise",
    price: 497,
    frequency: "per month",
    recommended: false,
    features: [
      "Hire, manage & analyze unlimited creators",
      "250 invites per month",
    ],
  },
];
