import React from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { createJobAsyncThunk } from "../redux/pagesSlices/jobSlice";

export default function JobPostStepperComponent({
  draftJobId,
  values,
  isActive,
  isCompleted,
}) {
  const { profile, user } = useSelector((s) => s.auth);
  const { loadings } = useSelector((s) => s.job);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSaveAndExit = () => {
    const formData = new FormData();

    // Append simple key-value pairs
    formData.append("jobTitle", values.jobTitle);
    formData.append("sellerShopCode", values.sellerShopCode);
    // formData.append("deadlineDate", values.deadlineDate);
    formData.append("isPrivate", values.isPrivate);
    formData.append("tiktokShopUrl", values.tiktokShopUrl);
    formData.append("product_id", values.product_id);
    formData.append("product_link", values.product_link);
    formData.append("videoUrl", values.videoUrl);
    formData.append("status", "draft");
    formData.append("brandName", profile?.name);
    if (draftJobId) {
      formData.append("draftJobId", draftJobId);
    }

    
    // Separate old image URLs and new image files
    const oldImageUrls = values.image.filter((img) => typeof img === "string");
    const newImageFiles = values.image.filter((img) => img instanceof File);

    // Append arrays
    newImageFiles.forEach((image) => formData.append("image", image));
    formData.append("oldImageUrls", JSON.stringify(oldImageUrls));
    values.types.forEach((type) => formData.append("types[]", type));
    values.gender.forEach((gender) => formData.append("gender[]", gender));
    values.ageRange.forEach((age) => formData.append("ageRange[]", age));
    values.categories.forEach((category) =>
      formData.append("categories[]", category)
    );

    for (const key in values.jobAttributes) {
      if (values.jobAttributes.hasOwnProperty(key)) {
        formData.append(`jobAttributes[${key}]`, values.jobAttributes[key]);
      }
    }

    const contactInfo = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    };

    for (const key in contactInfo) {
      if (contactInfo.hasOwnProperty(key)) {
        formData.append(`contactInfo[${key}]`, contactInfo[key]);
      }
    }

    // Dispatch action
    dispatch(
      createJobAsyncThunk({
        data: formData,
        callBack: () => {
          navigate(`/dashboard/my-jobs?status=draft`);
        },
      })
    );
  };

  return (
    <div className="job-post-stepper-component-wrapper">
      <div className="stepper-header">
        <div className="container large">
          <div className="row">
            <div className="col-md-3 align-self-center">
              <div className="back-btn-wrapper">
                <Link to="/dashboard">
                  <MdKeyboardArrowLeft /> Back to dashboard
                </Link>
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <div className="steps-main-wrapper">
                <div className="steps-wrapper">
                  <div
                    className={`step-wrapper ${isActive(0) ? "active" : ""} ${
                      isCompleted(0) ? "complete" : ""
                    }`}
                  >
                    <div className="num">
                      {isCompleted(0) ? <FaCheck /> : <span>1</span>}
                    </div>
                    <div className="text">
                      <span>Job info</span>
                    </div>
                  </div>
                  <div
                    className={`step-wrapper ${isActive(1) ? "active" : ""} ${
                      isCompleted(1) ? "complete" : ""
                    }`}
                  >
                    <div className="num">
                      {isCompleted(1) ? <FaCheck /> : <span>2</span>}
                    </div>
                    <div className="text">
                      <span>Details</span>
                    </div>
                  </div>
                  <div
                    className={`step-wrapper ${isActive(2) ? "active" : ""} ${
                      isCompleted(2) ? "complete" : ""
                    }`}
                  >
                    <div className="num">
                      {isCompleted(2) ? <FaCheck /> : <span>3</span>}
                    </div>
                    <div className="text">
                      <span>Media</span>
                    </div>
                  </div>
                  <div
                    className={`step-wrapper ${isActive(3) ? "active" : ""} ${
                      isCompleted(3) ? "complete" : ""
                    }`}
                  >
                    <div className="num">
                      {isCompleted(3) ? <FaCheck /> : <span>4</span>}
                    </div>
                    <div className="text">
                      <span>Settings</span>
                    </div>
                  </div>
                  <div
                    className={`step-wrapper ${isActive(4) ? "active" : ""} ${
                      isCompleted(4) ? "complete" : ""
                    }`}
                  >
                    <div className="num">
                      {isCompleted(4) ? <FaCheck /> : <span>5</span>}
                    </div>
                    <div className="text">
                      <span>Review</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 align-self-center">
              <div className="save-btn-swrapper">
                <button
                  disabled={loadings?.createJobAsyncThunk}
                  onClick={handleSaveAndExit}
                  type="button"
                  className="btn-style blue-border large_font"
                >
                  {loadings?.createJobAsyncThunk ? (
                    <Spinner size="sm" />
                  ) : (
                    "Save and exit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
