import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import 'react-circular-progressbar/dist/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.scss';

import ChoosePlatform from './pages/ChoosePlatform';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateAsyncThunk, userLogoutAsyncThunk } from './redux/pagesSlices/authSlice';
import Authenticated from './layouts/authenticated';
import UnAuthenticated from './layouts/unAuthenticated';
import LoaderComponent from "./components/LoaderComponent";
import ForgetPassword from "./pages/ForgetPassword";
import EmailSentTemplate from "./pages/EmailSentTemplate";
import EnterNewPassword from "./pages/EnterNewPassword";
import SuccessChangedPasswoedTemplate from "./pages/SuccessChangedPasswoedTemplate";
import { Button } from "react-bootstrap";
import DashboardHomeNoData from "./pages/DashboardHomeNoData";
import JobPostPage from "./pages/JobPostPage";
import MyJobsPage from "./pages/MyJobsPage";
import ProposalDetailPage from "./pages/ProposalDetailPage";
import MyCreator from "./pages/MyCreators";
import DashboaerdHome from "./pages/DashboardHome";
import JobDetailPage from "./pages/JobetailPage";
import CreatorProfilePage from "./pages/CreatorProfilePage";
import BrandContent from "./pages/BrandContent";
import ErrorMessagePage from "./pages/ErrorMessagePage";
import PortalSettings from "./pages/PortalSettings";
import TrackProjectPage from "./pages/TrackProjectPage";
import MessagesPage from "./pages/MessagesPage";
import AsadPage from "./pages/AsadPage.jsx";
import { getMyBrandsAsyncThunk } from "./redux/pagesSlices/brandSlice";
import FindCreators from "./pages/FindCreators.jsx";
import HiredApplicantsJobDetailPage from "./pages/HiredApplicantsJobDetailPage.jsx";
import MyBrand from "./pages/MyBrand";
import GoogleAuthCallback from "./pages/GoogleAuthCallback.jsx";
import ChangePasswodModal from "./components/modals/ChangePasswodModal.jsx";
import NotificationPage from "./pages/NotificationPage.jsx";

function App() {
  const d = useDispatch();
  const auth = useSelector(s => s.auth);
  const loading = auth?.loadings?.authenticateAsyncThunk;
  // const user = auth?.user;


  useEffect(() => {
    d(authenticateAsyncThunk());
    // d(getMyBrandsAsyncThunk());
  }, [d]);

  if (loading) return <LoaderComponent />;
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<UnAuthenticated Component={ChoosePlatform} />} />
          <Route path="/sign-up" element={<UnAuthenticated Component={SignUp} />} />
          <Route path="/sign-in" element={<UnAuthenticated Component={SignIn} />} />
          <Route path="/callback" element={<UnAuthenticated Component={GoogleAuthCallback} />} />
          <Route path="/forget-password" element={<UnAuthenticated Component={ForgetPassword} />} />
          <Route path="/email-sent" element={<UnAuthenticated Component={EmailSentTemplate} />} />
          <Route path="/new-password" element={<UnAuthenticated Component={EnterNewPassword} />} />
          <Route path="/password-changed" element={<UnAuthenticated Component={SuccessChangedPasswoedTemplate} />} />
          <Route path="/restricted" element={<Authenticated Component={Restricted} />} />
          <Route path="/dashboard" element={<Authenticated Component={Dashboard} />} >
            {/* <Route  element={<Authenticated Component={DashboardHomeNoData} />} /> */}
            <Route path="my-jobs" element={<Authenticated Component={MyJobsPage} />} />
            <Route path="creators" element={<MyCreator />} />
            <Route path="creators/:id" element={<CreatorProfilePage />} />
            <Route index path="" element={<DashboaerdHome />} />
            <Route path="job-detail/:id" element={<JobDetailPage />} />
            <Route path="messages" element={<MessagesPage />} />
            <Route path="settings" element={<PortalSettings />} />
            <Route path="my-profile" element={<BrandContent />} />
            <Route path="asad" element={<AsadPage />} />
            <Route path="hired-applicant-detail" element={<HiredApplicantsJobDetailPage />} />
            <Route path="find-creators" element={<FindCreators />} />
            <Route path="my-brand" element={<MyBrand />} />
            <Route path="track-project/:applicationId" element={<TrackProjectPage />} />
          </Route>
          <Route path="/no-dashboard" element={<UnAuthenticated Component={DashboardHomeNoData} />} />
          <Route path="/job-post" element={<Authenticated Component={JobPostPage} />} />
          <Route path="/proposal-detail" element={<UnAuthenticated Component={ProposalDetailPage} />} />
          <Route path="/brand-profile" element={<BrandContent />} />
          <Route path="/errorMessage" element={<ErrorMessagePage />} />
          <Route path="/notification" element={<NotificationPage />} />
     
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

const Restricted = () => {
  const auth = useSelector(s => s.auth);
  const user = auth?.user;
  const d = useDispatch();
  if (user.role === 'creator') {
    return <Navigate to={'/dashboard'} />;
  }
  const handleLogout = () => {
    d(userLogoutAsyncThunk());
  }
  return <div style={{ display: 'flex', flexDirection: "column", alignItems: "center", gap: "10px" }}>
    <h1 style={{ color: 'black' }}>Restricted</h1>
    <p>You are Creator and You unable to access this portal</p>
    <a href={process.env.REACT_APP_CREATOR_BASE_URL} className="btn btn-warning">Go to Creator Portal</a>
    <Button variant="danger" onClick={handleLogout}>Logout</Button>
  </div>;
}
