import React from "react";

import noJobImgNew from '../assets/images/no-job-img-new.svg'
import { Link } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import noJobsImage from "../assets/images/empty-img.png"

export default function NoDataComponent ({title,description}) {
    return(
        <div className="no-jobs-main-wrapper">
        <div className="main-wrapper">
            <div className="img-wrapper">
                <img src={noJobsImage} alt="noJobImgNew" className="no-jobs-img" />
            </div>
            <div className="content-wrapper">   
                <h4>{title}</h4>
                <p className="medium medium-bold">{description}</p>
                {/* <div className="btn-wrapper">
                    <Link to="/job-post" className="btn-style " style={{maxWidth : 'auto'}}><IoMdAdd/> Post a Job</Link>
                </div> */}
            </div>
        </div>
    </div>
    )
}