import React, { useState } from "react";
import pointerIcon from "../assets/images/icons/pointer-icon.svg";
import trashIcon from "../assets/images/icons/trash-icon.svg";
import brandAvatar from "../assets/images/brand-img-1.svg";
import { HiArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBrandAsyncThunk,
  getMyBrandsAsyncThunk,
} from "../redux/pagesSlices/brandSlice";
import { getId } from "../utils/utils";
import { dateFormat } from "../utils/format";
import { setBrand } from "../redux/pagesSlices/authSlice";

export default function MyBrandCardComponent({ item }) {
  const { brand = {} } = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  const handleDeleteBrand = (id) => {
    dispatch(
      deleteBrandAsyncThunk({
        id,
        callBack: () => {
          dispatch(getMyBrandsAsyncThunk({ pagination: true }));
        },
      })
    );
    // console.log("id", id);
  };

  const handleBrandSwitch = (selectedBrand) => {
    if (selectedBrand?.steps < 5) {
      const previousBrands = brand;
      localStorage.setItem("previous_space", JSON.stringify(previousBrands));
    }
    dispatch(setBrand(selectedBrand));
  };
  const [visible, setVisible] = useState(false);


  return (
    <div className="brand-card-wrapper"
     onMouseEnter={() => setVisible(true)} 
      onMouseLeave={() => setVisible(false)}
      >
      <div className="top-bar">
        <div className="job-status">
          {getId(brand) === getId(item) && (
            <span className="status">Active</span>
          )}
        </div>
        {visible && (
          <ul className="action-list">
            <li>
              <Link to={`/dashboard/my-profile?id=${item?._id}`}>
                <img src={pointerIcon} alt="pointerIcon" />
              </Link>
            </li>
            <li onClick={() => handleDeleteBrand(item?._id)}>
              <a href="#">
                <img src={trashIcon} alt="trashIcon" />
              </a>
            </li>
          </ul>
        )}

      </div>
      <div className="brand-img-wrapper">
        <a href="#">
          {" "}
          <img
            src={item?.avatar ? parseImagePathToURL(item?.avatar) : brandAvatar}
            alt="brandAvatar"
          />
        </a>
      </div>
      <div className="content-wrapper">
        <strong className={"large dark"}>
          <a href="#">{item?.name}</a>
        </strong>
        <p className={"medium-bold "}> {item?.address}</p>
        <p className={"medium-bold "}> {dateFormat(item?.createdAt)}</p>
      </div>
      <div className="edit-profile-btn-wrapper">
        {getId(brand) === getId(item) ? (
          <Link
            to={`/dashboard/my-profile?id=${item?._id}`}
            className="btn-style full "
          >
            Edit Profile <HiArrowRight />
          </Link>
        ) : (
          <button
            className="btn-style full "
            onClick={() => handleBrandSwitch(item)}
          >
            Activate brand
          </button>
        )}
      </div>
    </div>
  );
}
