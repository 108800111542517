import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import userLightIcon from "../assets/images/icons/user-light-icon.svg";
import starlight from "../assets/images/icons/Star_light.svg";
import creditcardlight from "../assets/images/icons/Credit-card-light.svg";
import notification from "../assets/images/icons/notification-icon.svg";
import Chieldchecklight from "../assets/images/icons/Chield_check_light.svg";
import logoutlighticon from "../assets/images/icons/logout-light-icon.svg";
import deleteicon from "../assets/images/icons/deleteicon.svg";
import DeleteAccountModal from "../components/modals/DeleteAccountModal";
import AddPaymentModal from "../components/modals/AddPaymentModal";
import AccountTab from "../components/profileSettings/AccountTab";
import NotificationTab from "../components/profileSettings/NotificationTab";
import SecurityTab from "../components/profileSettings/SecurityTab";
import PaymentTab from "../components/profileSettings/PaymentTab";
import SubscriptionTab from "../components/profileSettings/SubscriptionTab";

const tabs = [
  { id: "accounts", icon: userLightIcon, name: "Accounts" },
  { id: "subscription", icon: starlight, name: "Subscription" },
  { id: "pay-out-method", icon: creditcardlight, name: "Payment methods" },
  { id: "notifications", icon: notification, name: "Notification Settings" },
  { id: "security", icon: Chieldchecklight, name: "Passwords & Security" },
];

const bottomTabs = [
  { id: "logout", icon: logoutlighticon, name: "Log Out" },
  { id: "delete", icon: deleteicon, name: "Delete Account" },
];

const PortalSettings = () => {
  const [activeTab, setActiveTab] = useState("accounts");
  const [deleteAccount, setDeleteAccount] = useState(false);

  const handleOpenDeleteAccount = () => {
    setDeleteAccount(true);
  };
  const handleCloseDeleteAccount = () => {
    setDeleteAccount(false);
  };

  return (
    <div className="protalsettings-main-container">
      <div className="container">
        <span className="title">Settings</span>
        <div className="row">
          <div className="col-md-3">
            <div className="settings-menu">
              <ul className="top-ul">
                {tabs.map((tab) => (
                  <li
                    key={tab.id}
                    className={`menu-item ${
                      activeTab === tab.id ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(tab.id)}
                  >
                    <img src={tab.icon} alt={tab.name} />
                    <span>{tab.name}</span>
                  </li>
                ))}
              </ul>
              <div className="custom-bar"></div>
              <ul className="bottom-ul">
                {bottomTabs.map((tab) => (
                  <li
                    key={tab.id}
                    className={`menu-item ${
                      activeTab === tab.id ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(tab.id)}
                  >
                    <img src={tab.icon} alt={tab.name} />
                    <span>{tab.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-9">
            <div className="form-section-parent">
              {activeTab === "accounts" && <AccountTab />}
              {activeTab === "subscription" && <SubscriptionTab />}
              {activeTab === "pay-out-method" && <PaymentTab />}
              {activeTab === "notifications" && <NotificationTab />}
              {activeTab === "security" && <SecurityTab />}
              {activeTab === "logout" && (
                <div>
                  <h3>Log Out</h3>
                  <p>You can log out of your account here.</p>
                </div>
              )}
              {activeTab === "delete" && (
                <div>
                  <h3>Delete Account</h3>
                  <p>
                    Proceed with caution if you wish to delete your account.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteAccountModal
        deleteAccount={deleteAccount}
        handleCloseDeleteAccount={handleCloseDeleteAccount}
      />
    </div>
  );
};

export default PortalSettings;

{
  /* <div className="form-section-parent">
              <div className="header">
                <span>Account</span>
              </div>
              <div className="header-bottom-bar"></div>
              <div className="form-section">
          <form action="#" className="form">
          <div className="row first-section-input">
  <div className="col-md-4">
    <div className="form-group">
      <label htmlFor="name">First Name</label>
      <input type="text" className="form-control" placeholder="John" />
    </div>
  </div>
  <div className="col-md-4">
    <div className="form-group">
      <label htmlFor="name">Last Address</label>
      <input type="text" className="form-control" placeholder="Doe" />
    </div>
  </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <label htmlFor="name">Email</label>
      <input type="email" className="form-control" placeholder="email@example.com" />
    </div>
    </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <h3>Brand information </h3>
      <span >This information will appear on your future bills and invoices</span>
    </div>
    </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <label htmlFor="name">Email</label>
      <input type="email" className="form-control" placeholder="email@example.com" />
    </div>
    </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <label htmlFor="name">legal business name</label>
      <input type="email" className="form-control" placeholder="Burger King" />
    </div>
    </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <label htmlFor="name">Business phone number</label>
      <input type="tel" className="form-control" placeholder="+1 000 000 000 00" />
    </div>
    </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <label htmlFor="name">Business email address</label>
      <input type="email" className="form-control" placeholder="email@example.com" />
    </div>
    </div>
</div>
<div className="row first-section-input">
  <div className="col-md-4">
    <div className="form-group">
      <label htmlFor="name">Tax type (VAT, EIN)</label>
      <Dropdown className="portalsettings-dropdown">
      <Dropdown.Toggle >
        <span>Select</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </div>
  </div>
  <div className="col-md-4">
    <div className="form-group">
      <label htmlFor="name">ID number</label>
      <input type="text" className="form-control" placeholder="John" />
    </div>
  </div>
  </div>

                </form>
          </div>
          <div className="save-button-parent">
                <button className="save-button">Save</button>
              </div>
          </div>  */
}
