import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import brandContent from "../assets/images/brandImage.png";
import LocationIcon from "../assets/images/icons/LocationIcon.svg";
import PhoneIcon from "../assets/images/icons/PhoneIcon.svg";
import globeIcon from "../assets/images/icons/globeIcon.svg";
import tiktokIcon from "../assets/images/icons/tiktokIcon.svg";
import instaIcon from "../assets/images/icons/instaIcon.svg";
import facebookIcon from "../assets/images/icons/facebookIcon.svg";
import youtubeIcon from "../assets/images/icons/youtubeIcon.svg";
import Editfill from "../assets/images/icons/Editfill.svg";
import EditProfileInfoModal from "../components/modals/EditProfileInfoModal";
import { useDispatch, useSelector } from "react-redux";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { getId, returnArray } from "../utils/utils";
import { Link, useSearchParams } from "react-router-dom";
import {
  getBrandAsyncThunk,
  updateBrandAsyncThunk,
  updateBrandImageAsyncThunk,
  updateBrandProfileAsyncThunk,
  updateBrandProfileByIdAsyncThunk,
} from "../redux/pagesSlices/brandSlice";

const BrandContent = () => {
  const [editProfileModal, setEditProfileModal] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  console.log("id", id);

  useEffect(() => {
    if (id) {
      dispatch(getBrandAsyncThunk(id));
    }
  }, [dispatch, id]);
  const brandFromId = useSelector((state) => state.brand.brand);

  console.log("brandFromId", brandFromId);

  const editProfileModalOpenHandler = () => setEditProfileModal(true);
  const editProfileModalCloseHandler = () => setEditProfileModal(false);

  const brand = useSelector((s) => s.auth.profile);
  const brandSocials = returnArray(brand?.socials);
  console.log("Brand socials", brandSocials);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const handleIconClick = () => {
    // Trigger the file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      console.log("Selected file:", selectedFile); // Debugging log
      setFile(selectedFile); // Update state
      handleUpdateCover(selectedFile); // Immediately handle update
    } else {
      console.log("No file selected"); // Debugging log
    }
  };

  const handleUpdateCover = async (file) => {
    console.log("Received file:", file); // Debugging log

    const formData = new FormData();
    formData.append("image", file);

    console.log("Form data:", formData); // Debugging log

    // Uncomment to dispatch an API call
    await dispatch(
      updateBrandImageAsyncThunk({
        id: getId(brand),
        data: formData,
      })
    );
  };

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  console.log("Image", image);

  const handleImageChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file); // Save the file (you can upload it later)
        setImagePreview(reader.result); // Set the preview image
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("avatar", file);
      let myid = id ? getId(brandFromId) : getId(brand);
      console.log("id at function", id);
      if (id) {
        dispatch(updateBrandProfileByIdAsyncThunk({ id: id, data: formData }));
      } else dispatch(updateBrandProfileAsyncThunk({ data: formData }));
      // dispatch(updateBrandProfileByIdAsyncThunk({ id: id, data: formData }))
      // dispatch(
      //   myid
      //     ? updateBrandProfileByIdAsyncThunk({
      //         id: myid,
      //         data: formData,
      //       })
      //     : updateBrandProfileAsyncThunk({
      //         data: formData,
      //       })
      // );
    }
  };

  const triggerFileInput = () => {
    document.getElementById("image-upload-input").click(); // Trigger the file input click
  };
  // console.log("Brand", brand);

  return (
    <div className="parent-container">
      <div className="container">
        <div className="single-field main-page-wrapper">
          <div className="row">
            <div className="col-md-7 align-self-center">
              <div className="main-image-conatiner">
                <div onClick={triggerFileInput} style={{ cursor: "pointer" }}>
                  {imagePreview ? (
                    <img src={imagePreview} alt="Brand Image" />
                  ) : (
                    <img
                      src={
                        brandFromId
                          ? brandFromId.avatar
                            ? parseImagePathToURL(brandFromId.avatar)
                            : brandContent
                          : brand?.avatar
                          ? parseImagePathToURL(brand.avatar)
                          : brandContent
                      }
                      alt="Brand Image"
                    />
                  )}
                </div>
                <input
                  type="file"
                  id="image-upload-input"
                  style={{ display: "none" }} // Hide the file input
                  accept="image/*" // Only allow image files
                  onChange={handleImageChange}
                />
              </div>
              <div className="text-container">
                <div className="title-text">
                  <span>{id ? brandFromId?.name : brand?.name}</span>
                </div>
                <div className="info-text">
                  <div className="comp1">
                    <img
                      src={LocationIcon}
                      alt="Location"
                      className="compIcon"
                    />
                    <span>{id ? brandFromId?.address : brand?.address}</span>
                  </div>
                  <div className="comp2">
                    <img src={PhoneIcon} alt="Phone" className="compIcon" />
                    <span>
                      <a href="tel : +1 00 000 0000">
                        {id ? brandFromId?.phone : brand?.phone}
                      </a>
                    </span>
                  </div>
                  <div className="comp3">
                    <img src={globeIcon} alt="globe" className="compIcon" />
                    <span>
                      <a href={id ? brandFromId?.website : brand?.website}>
                        {id ? brandFromId?.website : brand?.website}
                      </a>
                    </span>
                  </div>
                </div>
                {/* <button onClick={handleIconClick}>📷 Upload Image</button>{" "}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }} // Hide the file input
                  onChange={handleFileChange}
                /> */}
                <ul className="social-icons">
                  {brandSocials?.map((social, index) => {
                    // Choose the appropriate icon based on the type
                    let socialIcon;

                    switch (social.type) {
                      case "Facebook":
                        socialIcon = facebookIcon;
                        break;
                      case "Instagram":
                        socialIcon = instaIcon;
                        break;
                      case "TikTok":
                        socialIcon = tiktokIcon;
                        break;
                      case "YouTube":
                        socialIcon = youtubeIcon;
                        break;
                      default:
                        socialIcon = null; // Skip if no valid type
                    }

                    // Render only if the icon and URL are valid
                    return (
                      socialIcon &&
                      social.url && (
                        <li key={index}>
                          <Link
                            to={social.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={socialIcon} alt={`${social.type} Icon`} />
                          </Link>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-5 align-self-center">
              <div className="edit-profile-button-parent text-end justify-content-end">
                <button onClick={editProfileModalOpenHandler}>
                  <img src={Editfill} alt="Editfill" />
                  <span>Edit profile</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="single-field">
          <div className="row">
            <div className="col-md-12">
              <div className="about-context">
                <span>About</span>
                <p>{id ? brandFromId?.description : brand?.description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="single-field">
          <div className="row">
            <div className="col-md-8">
              <div className="categories-section">
                <span>Categories</span>
                <div className="category-btns">
                  <ul>
                    {id
                      ? brandFromId?.categories?.map((category, index) => (
                          <li className="li-categories" key={index}>
                            {category}
                          </li>
                        ))
                      : brand?.categories?.map((category, index) => (
                          <li className="li-categories" key={index}>
                            {category}
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditProfileInfoModal
        editProfileModalCloseHandler={editProfileModalCloseHandler}
        editProfileModal={editProfileModal}
        brand={brand}
        id={id}
        brandFromId={brandFromId}
      />
    </div>
  );
};

export default BrandContent;
