import { RxArrowTopRight } from "react-icons/rx";
import { Link } from "react-router-dom";
import jobImg1 from "../../assets/images/job-img-1.svg";
import brandImg from "../../assets/images/brand-img-1.svg";
import DashboardMyMessages from "../../components/DashboardMyMessages";
import { FaCalendarAlt } from "react-icons/fa";
import { CircularProgressbar } from "react-circular-progressbar";
import videofile from "../../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../../assets/images/icons/live-icon.svg";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { getId, returnArray } from "../../utils/utils";

export default function OnGoingJobsComponent({ ongoingjob }) {
  return (
    <div className="ongoing-job-status">
      <div className="job-info">
        <div className="img-wrapper">
          <Link to={`/dashboard/job-detail/${getId(ongoingjob)}`} href="#">
            {ongoingjob?.image ? (
              <img
                src={parseImagePathToURL(ongoingjob?.image[0]) || jobImg1}
                alt="jobImg1"
                className="job-img"
              />
            ) : (
              <img src={jobImg1} alt="jobImg1" className="job-img" />
            )}
          </Link>
        </div>
        <div className="text-wrapper">
          <strong className="dark ">
            <Link to={`/dashboard/job-detail/${getId(ongoingjob)}`}>
              {ongoingjob?.jobTitle}
            </Link>
          </strong>
        </div>
      </div>
      <div className="texts">
        <p className="medium-bold">
          <span className="blue">3.5X </span> ROI
        </p>
      </div>
      <div className="texts">
        <p className="medium-bold">
          <span className="blue">$1,200 </span> GMV Gen.
        </p>
      </div>
      <div className="all-uers-images-main">
        <ul className="users-imgs-list">
          {/* {job?.image?.map((img, index) => (
            <li key={index}>
              <a href="#">
                <img
                  src={parseImagePathToURL(img)} // Use the parseImagePathToURL function
                  alt={`Job Image ${index + 1}`}
                />
              </a>
            </li>
          ))} */}
          {returnArray(ongoingjob?.applicants).map((applicant, index) => (
            <li key={index}>
              {applicant?.avatar ? (
                <a href="#">
                  <img
                    src={parseImagePathToURL(applicant?.avatar)}
                    alt="avatar"
                  />
                </a>
              ) : (
                // <img src={jobImg1} alt="avatar" />
                <a href="#">
                  <img
                    src={jobImg1} // Use the parseImagePathToURL function
                    alt={`Job Image `}
                  />
                </a>
              )}
            </li>
          ))}
        </ul>
        <strong className=" semi_bold">
          <a href="#">+{returnArray(ongoingjob?.applicants).length} Creators</a>
        </strong>
      </div>
      <div className="states-main-wrapper">
        <div className="stats">
          <div className="text blue">
            <CircularProgressbar value={20} strokeWidth={16} />
            <span className="white"> {ongoingjob?.video}</span>
          </div>
          <div className="bottom">
            <img src={videofile} alt="videofile" />
            <span className="ex_small">Videos Created</span>
          </div>
        </div>
        <div className="stats">
          <div className="text pink">
            <CircularProgressbar value={20} strokeWidth={16} />
            <span className="white"> {ongoingjob?.live}</span>
          </div>
          <div className="bottom">
            <img src={liveIcon} alt="liveIcon" />
            <span className="ex_small">Livestreams</span>
          </div>
        </div>
        {/* <div className="stats">
          <div className="text gray">
            <span className="">7</span>
          </div>
          <div className="bottom">
            <FaCalendarAlt />
            <span className="ex_small">Days to deadline</span>
          </div>
        </div> */}
      </div>
    </div>
  );
}
