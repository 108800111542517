import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function SampleProductImage({
  sampleProductImage,
  handleDelete,
}) {
  return (
    <>
      {sampleProductImage?.map((productImage, index) => (
        <div className="sample-product-image-wrapper" key={index}>
          <img src={productImage} alt={`productImage ${index}`} />
          <button className="delete-icon" type="button" onClick={() => handleDelete(index)}>
            <RiDeleteBin6Line />
          </button>
        </div>
      ))}
    </>
  );
}
