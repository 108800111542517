import React, { useEffect, useState } from "react";

import jobImg from "../assets/images/job-img-1.svg";
import mapIcon from "../assets/images/icons/map-icon.svg";
import clockFillIcon from "../assets/images/icons/clock-fill-icon.svg";
import calendar from "../assets/images/icons/calendar-simple-icon.svg";
import dollarSign from "../assets/images/icons/dollar-sign.svg";
import videoFileLight from "../assets/images/icons/video-file-light-icon.svg";
import moneyIcon from "../assets/images/icons/money-icon.svg";
import videoFileIcon from "../assets/images/icons/video-file-wrapper.svg";
import regroupIcon from "../assets/images/icons/regroup-icon.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import statusIcon from "../assets/images/icons/status-icon.svg";
import chatIcon from "../assets/images/icons/chat-icon.svg";
import envelopIcon from "../assets/images/icons/envelop-fill-icon.svg";
import { Link, useParams, useSearchParams } from "react-router-dom";
import TrackJobsStepsContent from "../components/TrackJobsStepsContent";
import LinkTrackingUrlModal from "../components/LinkTrackingUrlModal";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationAsyncThunk } from "../redux/pagesSlices/applicationSlice";
import { getJobTaskWithApplicationIdAsyncThunk } from "../redux/pagesSlices/jobTaskSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import moment from "moment";
import { dateFormat } from "../utils/format";
import { getId } from "../utils/utils";
import LoaderComponent from "../components/LoaderComponent";
import NoDataComponent from "../components/NoDataComponent";

export default function TrackProjectPage() {
  const d = useDispatch();
  const { applicationId } = useParams();

  const { jobTask, loadings } = useSelector((s) => s.jobTask);
  useEffect(() => {
    if (applicationId) d(getJobTaskWithApplicationIdAsyncThunk(applicationId));
  }, [d, applicationId]);

  const jobDetails = jobTask?.jobId;
  const brandDetails = jobTask?.brandId;
  console.log("🚀 ~ jobDetails:", jobTask);
  console.log("🚀 ~ jobTask?.videoPrice:", jobTask?.videoPrice);
  const [currentStep, setCurrentStep] = useState(0);

  //   const handleNext = () => {
  //     if (currentStep < 6) {
  //       setCurrentStep(currentStep + 1);
  //     }
  //   };

  //   const handlePrevious = () => {
  //     if (currentStep > 0) {
  //       setCurrentStep(currentStep - 1);
  //     }
  //   };

  const isActive = (step) => step === currentStep;
  const isCompleted = (step) => step < currentStep;
  const proposalDetails = {};
  const steps = [
    { id: 1, name: "Make Payment" },
    { id: 2, name: "Content creation" },
    { id: 3, name: "Review & Renew" },
  ];

  const getStepClass = (index) => {
    const status = jobTask?.status;
    console.log("jobTask?.videoPrice", jobTask?.videoPrice);
    console.log("jobTask?.jobProgressInfo", jobTask?.jobProgressInfo);

    const isContentCreated =
      (jobTask?.videoPrice?.count &&
        jobTask?.livePrice?.count &&
        jobTask?.jobProgressInfo?.video_count >= jobTask?.videoPrice?.count &&
        jobTask?.jobProgressInfo?.live_count >= jobTask?.livePrice.count) ||
      (jobTask?.videoPrice?.count &&
        !jobTask?.livePrice?.count &&
        jobTask?.jobProgressInfo?.video_count >= jobTask?.videoPrice?.count) ||
      (jobTask?.livePrice?.count &&
        !jobTask?.videoPrice?.count &&
        jobTask?.jobProgressInfo?.live_count >= jobTask?.livePrice?.count);

    if (!jobTask?.paymentInfo?.isPaid) {
      if (index < 1) return "step-name-wrapper active"; // Step 3
      return "step-name-wrapper"; // Steps 4–6
    }

    if (!isContentCreated) {
      if (index < 1) return "step-name-wrapper complete"; // Steps 1–4
      if (index === 1) return "step-name-wrapper active"; // Step 5
      return "step-name-wrapper"; // Step 6
    }

    if (index === 2) return "step-name-wrapper active";

    return "step-name-wrapper";
  };
  if (loadings?.getJobTaskWithApplicationIdAsyncThunk)
    return <LoaderComponent />;
  if (!jobTask)
    return (
      <NoDataComponent
        title="No Job Found"
        description="No job tracking details found with this id or application id"
        primaryActionText="Go to Dashboard"
        primaryActionLink="/dashboard"
        secondaryActionText="Go to My jobs"
        secondaryActionLink="/dashboard/my-jobs"
      />
    );
  return (
    <div className="inner-page-wrapper active m-0">
      <div className="track-project-page">
        <div className="track-project-page-inner">
          <div className="job-top-title-wrapper">
            <div className="container large">
              <div className="row">
                <div className="col-md-7">
                  <div className="title-wrapper">
                    <h2 className="white">{jobDetails?.jobTitle}</h2>
                    <div className="main-wrapper">
                      <div className="img-wrapper">
                        <img
                          src={
                            jobDetails?.image[0]
                              ? parseImagePathToURL(jobDetails?.image[0])
                              : jobImg
                          }
                          alt="jobImg"
                        />
                      </div>
                      <div className="content-wrapper">
                        <div className="text">
                          <strong className="name medium white">
                            <Link to="#" className="white">
                              {jobDetails?.brandName}
                            </Link>
                          </strong>
                          <div className="location">
                            <img
                              src={mapIcon}
                              alt="mapIcon"
                              className="white-icon"
                            />
                            <p className="medium medium-bold white">
                              {jobDetails?.location || "United States"}
                            </p>
                          </div>
                        </div>
                        <div className="text">
                          <p className="medium medium-bold m-0 white">
                            <Link to="#">{jobDetails?.tiktokShopUrl}</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="top-bar-cards-wrapper">
                    <div className="card-wrapper">
                      <h3 className="white">
                        {moment(jobTask?.endTime).diff(moment(), "days")}
                      </h3>
                      <div className="bottom">
                        <img
                          src={clockFillIcon}
                          alt="clockFillIcon"
                          className="white-icon"
                        />
                        <span className="ex-small white">Days to deadline</span>
                      </div>
                    </div>
                    <div className="card-wrapper">
                      <h3 className="white">
                        {jobTask?.jobProgressInfo?.video_count || 0}/
                        {jobTask?.videoPrice?.count}
                      </h3>
                      <div className="bottom">
                        <img
                          src={videoFileIcon}
                          alt="videoFileIcon"
                          className="white-icon"
                        />
                        <span className="ex-small white">Videos</span>
                      </div>
                    </div>
                    <div className="card-wrapper">
                      <h3 className="white">
                        {jobTask?.jobProgressInfo?.live_count || 0}/
                        {jobTask?.livePrice?.count}
                      </h3>
                      <div className="bottom">
                        <img
                          src={liveIcon}
                          alt="liveIcon"
                          className="white-icon"
                        />
                        <span className="ex-small white">Live</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="track-project-steps-main-wrapper">
                {steps.map((step, index) => (
                  <div key={step.id} className={getStepClass(index)}>
                    <h3 className="num">{step.id}.</h3>
                    <strong className="white">{step.name}</strong>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="track-project-short-info-wrapper">
            <div className="container large">
              <div className="inner-section-wrapper">
                <div className="text-wrapper">
                  <div className="title">
                    <img src={statusIcon} alt="statusIcon" />
                    <span className="medium-bold">Status</span>
                  </div>
                  <p>{jobTask?.status}</p>
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={regroupIcon} alt="regroupIcon" />
                    <span className="medium-bold">ROI</span>
                  </div>
                  <p>3x</p>
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={dollarSign} alt="statusIcon" />
                    <span className="medium-bold">GMV</span>
                  </div>
                  <p>$89</p>
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={dollarSign} alt="statusIcon" />
                    <span className="medium-bold">GMV/Video</span>
                  </div>
                  <p>$87</p>
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={videoFileLight} alt="videoFileLight" />
                    <span className="medium-bold">Content Type</span>
                  </div>
                  <p>{jobDetails?.types.join(",")}</p>
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={moneyIcon} alt="moneyIcon" />
                    <span className="medium-bold">Amount</span>
                  </div>
                  {jobTask?.paymentInfo?.amount ? (
                    <p>`${Number(jobTask?.paymentInfo?.amount).toFixed(1)}`</p>
                  ) : (
                    "_"
                  )}
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={calendar} alt="calendar" />
                    <span className="medium-bold">Start Date</span>
                  </div>
                  <p>{dateFormat(jobTask?.createdAt)}</p>
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={calendar} alt="calendar" />
                    <span className="medium-bold">Completion Date</span>
                  </div>
                  <p>
                    {jobTask?.completedAt
                      ? dateFormat(jobTask?.completedAt)
                      : "N/A"}
                  </p>
                </div>
                <div className="text-wrapper">
                  <div className="title column-direction">
                    <Link
                      to={`/dashboard/messages?id=${getId(
                        brandDetails
                      )}&jobId=${getId(jobDetails)}`}
                    >
                      <div className="img-wrapper">
                        <img
                          src={envelopIcon}
                          alt="envelopIcon"
                          className="white-icon"
                        />
                      </div>
                      <span className="medium-bold">Message brand</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="track-project-main-content-section">
            <div className="container large">
              <div className="row">
                <div className="col-md-8">
                  <div className={"main-steps-content-wrapper"}>
                    <div className="main-title-wrapper">
                      <h3>Track Your Project Progress</h3>
                      <p className="medium medium-bold">
                        Keep an eye on every stage of your project with
                        real-time updates. Easily monitor the creator's <br />{" "}
                        progress, review deliverables, and manage renewals or
                        adjustments as needed.
                      </p>
                    </div>

                    <TrackJobsStepsContent proposalDetails={jobTask} />
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>

          {/* <button onClick={handlePrevious} disabled={currentStep === 0}>
                        Previous
                    </button>
                    <button onClick={handleNext} disabled={currentStep === 6}>
                        Next
                    </button> */}

          <div className="contact-us-in-trouble-wrapper">
            <div className="container large">
              <div className="inner-wrapper">
                <div className="row">
                  <div className="col-md-8 align-self-center">
                    <div className="content-wrapper">
                      <div className="img-wrapper">
                        <img src={chatIcon} alt="chatIcon" />
                      </div>
                      <div className="text-wrapper">
                        <strong className="medium">Having trouble?</strong>
                        <p className="medium medium-bold">
                          Feel free to contact us and we will always help you
                          through the process
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 align-self-center">
                    <div className="btn-wrapper">
                      <a href="#" className="btn-style white-bg">
                        Contact us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
