import React, { useEffect, useState } from "react";
import TickedCircle from "../../assets/images/icons/TickedCircle.svg";
import Downloadlight from "../../assets/images/icons/Download_light.svg";
import Downloadgreylight from "../../assets/images/icons/Download_grey-light.svg";
import { useDispatch, useSelector } from "react-redux";
import { membershipPlans } from "../../utils/constant";
import moment from "moment";
import { getBrandBillingHistoryAsyncThunk } from "../../redux/pagesSlices/paymentSlice";
import { getId, returnArray } from "../../utils/utils";
import LoaderComponent from "../PaginateLoaderComponent";
import NoDataComponent from "../NoDataComponent";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";

export default function SubscriptionTab() {
  const { brand } = useSelector((s) => s.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);
  const [loading, setLoading] = useState(false);
  // console.log("brand_details", brand);
  const filteredPlan = membershipPlans?.filter(
    (plan) => plan.planName === brand?.subscription?.plan
  )[0];
  const dispatch = useDispatch();
  const billingHistory = useSelector((state) => state.payment.billing);
  const [lastInvoiceId, setLastInvoiceId] = useState(null);
  useEffect(() => {
    const fetchBillingHistory = async () => {
      const params = {
        limit: pageSize,
        page: currentPage,
        lastInvoiceId: lastInvoiceId,
      };
      setLoading(true);
      try {
        await dispatch(
          getBrandBillingHistoryAsyncThunk({
            params,
            callBack: () => {
              // Optional callback if needed
            },
          })
        );
      } catch (error) {
        console.error("Error fetching billing history:", error);
      } finally {
        setLoading(false); // Ensure this runs after the async operation
      }
    };

    fetchBillingHistory();
  }, [dispatch, getId(brand), lastInvoiceId, currentPage]);

  // console.log("billingHistory", billingHistory);
  // console.log("expiry", brand?.subscription?.expiresAt);

  const billingData = returnArray(billingHistory?.invoices);

  const handleNextPage = () => {
    if (billingHistory?.hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
      // Update lastInvoiceId for the next page
      setLastInvoiceId(billingData[billingData.length - 1]?.id);
    }
  };

  // Handle Previous Page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      // For going to the previous page, the lastInvoiceId will be handled on the backend
      setLastInvoiceId(null); // Reset for the previous page
    }
  };
  const totalRecords = billingHistory?.totalRecords;
  function formatString(input) {
    return input
      ?.replace(/_/g, " ") // Replace underscores with spaces
      ?.replace(/\b\w/g, (char) => char?.toUpperCase()); // Capitalize the first letter of each word
  }

  return (
    <div className="subscription-tab-main-wrapper">
      <div className="portal-settings-tab-header">
        <strong className="dark large">Subscription</strong>
      </div>
      <div className="portal-header-bottom-bar"></div>
      <div className="subscription-bottom-content">
        <div className="title">
          <span>Current Plan</span>
        </div>
        <div className="bordered-box">
          <div className="bordered-box-sec-1">
            <h6>{brand?.subscription?.plan}</h6>
            <span>${filteredPlan?.price} </span>
            <sub>{filteredPlan?.frequency}</sub>
            <div className="ordered-text">
              <h6>Includes:</h6>
              {filteredPlan?.features?.map((feature, index) => (
                <div className="ordered-text-comp" key={index}>
                  <img src={TickedCircle} alt="TickedCircle" />
                  <span>{feature}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="bordered-box-sec-2">
            <p className="text-section">
              <span className="text1">Subscription end date</span>
              <span className="text2">
                {brand?.subscription?.expiresAt
                  ? moment(brand.subscription.expiresAt.slice(0, 10)).format(
                      "MMM DD, YYYY"
                    )
                  : "N/A"}
              </span>
            </p>
          </div>
        </div>
        <div className="subscription-tab-table">
          <div className="table-headings">
            <span>Billing history</span>
            <div className="sec2-text">
              <button className="trasparent-btn">
                <span>Download all</span>
                <img src={Downloadgreylight} alt="download" />
              </button>
            </div>
          </div>
          {loading ? (
            <LoaderComponent />
          ) : (
            <div>
              {billingData?.length === 0 ? (
                <NoDataComponent title="No Billing History Found" />
              ) : (
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Description</th>
                      <th>Method</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billingData.map((item, index) => (
                      <tr key={index}>
                        <td>{moment(item?.created).format("MMMM DD, YYYY")}</td>
                        <td>${item?.amount_due}</td>
                        <td>{item?.description ? formatString(item?.description) : "No Description"}</td>
                        <td className="status">{item?.method || "N/A"}</td>
                        <td>
                          <button className="trasparent-btn">
                            <img
                              src={Downloadlight}
                              alt="icon"
                              className="icon"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}

          <div className="subscription-pagination">
            <div className="inner-wrapper">
              <div className="counting">
                Showing {(currentPage - 1) * pageSize + 1} to{" "}
                {Math.min(currentPage * pageSize, totalRecords)} of{" "}
                {totalRecords} entries
              </div>

              <div className="controls">
                <div className="single-control">
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <LuChevronLeft />
                  </button>
                </div>
                <div className="single-control">
                  <button
                    onClick={handleNextPage}
                    disabled={!billingHistory?.hasMore}
                  >
                    <LuChevronRight />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
