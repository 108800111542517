import React from "react";

import noJobImgNew from "../assets/images/ongoing-job-status.svg";
import { Link } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";

export default function NoJobNewComponent({ title,description, showBtn }) {
  return (
    <div className="no-jobs-main-wrapper">
      <div className="main-wrapper">
        <div className="img-wrapper">
          <img src={noJobImgNew} alt="noJobImgNew" className="no-jobs-img" />
        </div>
        <div className="content-wrapper">
            <h4>{title}</h4>
          <p className="medium medium-bold">{description}</p>
          <div className="btn-wrapper">
            {showBtn && (
              <Link
                to="/job-post"
                className="btn-style "
                style={{ maxWidth: "auto" }}
              >
                <IoMdAdd /> Post a Job
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
